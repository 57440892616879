import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderBottom: `1px solid #000000`,
  },
  text: {
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme_fpl.palette.common.white,
  },
}));

export default useStyles;
