import { useDispatch, useSelector } from 'react-redux';
import Login from 'components/Login/Login';
import { useEffect, useState } from 'react';
import { SIGN_IN, forgotPassword, signInUser } from 'core/actions/AuthActions';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import { getLoadingStatusSelector } from 'core/selectors/LoadingSelectors';
import { AppRoutes } from 'config/AppRoutes';
import { globalNavigate } from 'utils/global-history';
import { getSigninFormSelector } from 'core/selectors/AuthSelectors';


export default function LoginContainer() {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;
  const isLoading = useSelector(getLoadingStatusSelector(SIGN_IN.POST.REQUEST));
  const signinForm = useSelector(getSigninFormSelector);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const onSignIn = (email, password) => {
    dispatch(signInUser.request(email, password));
  }

  const navigateToSignup = () => {
    globalNavigate(AppRoutes.signup.link);
  }

  const onForgotPassword = (email) => {
    dispatch(forgotPassword.request(email))
  }

  return (
    <ResponsiveContainer>
      <Login
        isMobile={width < breakpoint}
        navigateToSignup={navigateToSignup}
        onSignIn={onSignIn}
        onForgotPassword={onForgotPassword}
        isLoading={isLoading}
        error={false}
        redirectFromConfirmSignup={signinForm.redirectFromConfirmSignup}
      />
    </ResponsiveContainer>
  )
}