import { UserLeague } from "core/models/Models";

export function getUserByCognitoId(cognitoId) {
  return `{
    user(userId: "${cognitoId}") {
      cognitoId: userId
      teamId
      teamName
      playerName
      walletAddress
      walletBalance
      usersLeagues {
        leagueId
        finalRank
        prize
        competitionType
      }
      usersPrivateLeagues {
        leagueId
        finalRank
        prize
        competitionType
      }
    }
  }`;
}


export function getWalletBalance(address) {
  return `{
    walletBalance(address: "${address}") {
      address
      wallet
    }
  }`;
}


export function getNextGameWeekQuery() {
  return `{
    nextGameWeek  {
      id
      name
      deadlineTime
    }
  }`;
}


export function getPublicLeaguesQuery() {
  return `{
    publicLeagues {
      publicLeagues {
        leagueId
        leagueName
        entryPrice
        fund
        playersNumber
        winnersNumber
        startGameWeek
        endGameWeek
        prizeDistribution
        maxPlayers
        createdBy
      }
      publicH2H {
        leagueId
        leagueName
        entryPrice
        fund
        playersNumber
        startGameWeek
        endGameWeek
        createdBy
        maxPlayers
      }
    }
  }`
}



export function getUsersLeaguesQuery(cognitoId: string) {
  return `{
    usersLeagues(userId: "${cognitoId}") {
      usersCurrentLeagues {
        leagueId
        leagueName
        entryPrice
        fund
        playersNumber
        winnersNumber
        startGameWeek
        endGameWeek
        prizeDistribution
        maxPlayers
        createdBy
        leagueType
      }
      usersCurrentH2H {
        leagueId
        leagueName
        entryPrice
        fund
        playersNumber
        startGameWeek
        endGameWeek
        createdBy
        maxPlayers
        leagueType
      }
      usersFinishedLeagues {
        leagueId
        leagueName
        entryPrice
        fund
        startGameWeek
        endGameWeek
        prize
        rank
        leagueType
      }
      usersFinishedH2H {
        leagueId
        leagueName
        entryPrice
        fund
        startGameWeek
        endGameWeek
        prize
        rank
        leagueType
      }
    }
  }`
}


export function getH2HDetailsMutation(leagueId, userId) {
  return `{
    h2hDetails(
      userId: "${userId}"
      leagueId: "${leagueId}"
    )
  }`;
}



export function getGameWeeksQuery() {
  return `
    query {
      getGameWeeks {
        currentGameWeek {
          id
          name
          deadlineTime
        }
        nextGameWeek {
          id
          name
          deadlineTime
        }
        fistEligibleGameWeek {
          id
          name
          deadlineTime
        }
      }
    }
  `;
}


