import React from 'react';
import { useStyles } from './CryptoHelp.styles';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const CryptoHelp: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid>
      <h2>Generating a deposit address</h2>
      <p className={classes.paragraph}>
        When you created your account, a unique deposit address was automatically created and is displayed on this page.
        This address is specific to your account and will be used as the destination for your coin deposit.
        Copy the provided deposit address.
      </p>

      <h3>Step 1: Initiating the Deposit</h3>

      <p className={classes.paragraph}>
        Open your personal cryptocurrency wallet where you hold your USDT. Navigate to the "Send" or "Withdraw" section of your wallet.
        Paste the deposit address copied from the Crypto Fantasy page into the recipient field. Double-check the address to avoid errors.
        Make sure that you choose Matic network Enter the amount of USDT you wish to deposit and review the transaction details.
        Confirm the transaction, following any additional security measures required by your personal wallet (e.g., two-factor authentication).
      </p>

      <h3>Step 2: Wait for Confirmations</h3>

      <p className={classes.paragraph}>
        After submitting the transaction, the USDT network will need to validate and confirm it.
        The number of confirmations required may vary but usually takes around 6 confirmations.
        You can track the progress of your deposit by checking the transaction ID on the USDT Matic blockchain explorer.
      </p>

      <h3>Step 3: Completing the Deposit</h3>

      <p className={classes.paragraph}>
        Once the required number of confirmations is reached, your USDT deposit will be credited to your Crypto Fantasy account.
        Click on the refresh balance icon to see the updated balance.
        The deposited USDT will be available for use in participating in fantasy  games.
      </p>

      <h3>Important Tips and Considerations</h3>

      <p className={classes.paragraph}>
        Always double-check the deposit address to ensure it is the correct one provided by the Crypto Fantasy.
        Make sure you have sufficient USDT funds available in your personal wallet before initiating the deposit.
        Pay attention to any network fees associated with the USDT transaction to ensure the correct amount is sent.
        If you encounter any issues during the deposit process, contact the support team of the Crypto Fantasy  for assistance.
      </p>

      <Link to="/help" className={classes.helpLink}>Check out a more detailed to crypto if you are new.</Link>
    </Grid>
  );
};

export default CryptoHelp;
