import { Grid } from '@mui/material';
import images from 'assets/images';
import { useStyles } from './FPLCarousel.styles'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};


export default function FPLCarousel() {
  const classes = useStyles();
  return (
    <Carousel interval={10000} showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true}>
      <div>
        <img src={images.carousel1.src} />
      </div>
      <div>
        <img src={images.carousel2.src} />
      </div>
      <div>
        <img src={images.carousel3.src} />
      </div>
      <div>
        <img src={images.carousel4.src} />
      </div>
    </Carousel>
);
}
