// import authReducer from './AuthReducer';
import { combineReducers } from 'redux';
import authReducer from './AuthReducer';
import loadingReducer from './LoadingReducer';
import errorReducer from './ErrorReducer';
import userReducer from './UserReducer';
import emailReducer from './EmailReducer';
import newsReducer from './NewsReducer';
import leagueReducer from './LeagueReducer';
import commonsReducer from './CommonsReducer';


const rootReducer = combineReducers({
  // router: connectRouter(history),
  auth: authReducer,
  user: userReducer,
  email: emailReducer,
  commons: commonsReducer,
  news: newsReducer,
  league: leagueReducer,
  loading: loadingReducer,
  error: errorReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
