import { createTheme, Theme } from '@mui/material/styles';
import "@mui/styles";

declare module '@mui/styles' {
  type DefaultTheme = Theme;
}

const backgroundPrimary = '#2A2A2A';
const fplGreen = '#25eba8'
const fplPurple = '#9020ff'
const fplWhite = '#ffffff'
const fplInactive = '#C4C4C4';
const fplBlack = '#2A2A2A'


const fplRed = '#DA100B';
const fplBlue = '#0034FF';
const fplBlueDark = '#0029CB';
const fplGrayDark = '#828282';
const fplGray = '#484848';
const fplGrayInactive = '#C4C4C4';
const fplGrayLight = '#F6F6F6';
const fplGrayLight1 = '#9E9E9E';
const fplGrayLighter = '#E0E0E0';

/**
 * Fonts
 */
const fplPrimaryFont = 'Barlow Condensed';
const fplSecondaryFont = 'Barlow Condensed';

const theme_fpl = createTheme({
  spacing: 10,
  palette: {
    text: {
      primary: '#ffffff',
      secondary: fplGreen,
      disabled: fplInactive
    },
    common: {
      black: fplBlack,
      white: fplWhite
    },
    primary: {
      main: fplPurple,
    },
    secondary: {
      main: fplGreen,
    },
    error: {
      main: fplRed
    },
    success: {
      main: fplGreen
    },
    info: {
      main: fplBlue,
      dark: fplBlueDark
    },
    divider: fplGrayLighter,
    grey: {
      200: fplGrayLighter,
      400: fplGrayLight,
      500: fplGrayLight1,
      600: fplGray,
      800: fplGrayDark,
      A400: fplGrayInactive
    }
  },
  typography: {
    fontFamily: fplPrimaryFont,
    h1: {
      fontFamily: `${fplPrimaryFont}`,
      fontSize: '2.357rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '2.7rem',
      letterSpacing: '0em'
    },
    h2: {
      fontFamily: `${fplPrimaryFont}`,
      fontSize: '2.333rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '2.833rem',
      letterSpacing: '0em'
    },
    h3: {
      fontFamily: `${fplPrimaryFont}`,
      fontSize: '2rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '2.416rem',
      letterSpacing: '0em'
    },
    subtitle1: {
      fontFamily: `${fplPrimaryFont}`,
      fontSize: '1.3rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '3.083rem',
      letterSpacing: '0em'
    },
    subtitle2: {
      fontFamily: `${fplSecondaryFont}`,
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '1.188rem',
      letterSpacing: '0em'
    },
    button: {
      fontFamily: `${fplPrimaryFont}`,
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '1.833rem',
      letterSpacing: '0em'
    },
    body1: {
      fontFamily: `${fplSecondaryFont}`,
      fontSize: '1.333rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '1.833rem',
      letterSpacing: '0em'
    },
    body2: {
      fontFamily: `${fplSecondaryFont}`,
      fontSize: '1.166rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '1.583rem',
      letterSpacing: '0em'
    }
  },
  mixins: {
    toolbar: {
      minHeight: 70,
      maxHeight: 70
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: { root: { color: `${fplGreen}` } }
    }
  }
});

export default theme_fpl;
