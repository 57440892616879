import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  verificationTextContainer: {
    //
  },
  inputsContainer: {
    marginTop: '2.5em',
  },
  textInputContainer: {
    marginTop: '1em',
  },
  button: {
    marginTop: '4em',
    marginBottom: '3em',
    width: '100%',
    '& > button': {
      width: '100%',
      padding: 15
    }
  },
  input: {
    '& > div > div': {
      backgroundColor: theme_fpl.palette.grey[600],
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme_fpl.palette.primary.main,
    }
  },
   divider: {
      // Theme Color, or use css color in quote
      background: theme_fpl.palette.primary.main,
  },
  createAccountWrapper: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    width: '100%',
    paddingBottom: '2em',
    display: 'flex',
    borderBottomColor: theme_fpl.palette.primary.main,
    marginTop: '2em',
  },
  snackbar: {
    width: '100%',
    '& > * + *': {
      marginTop: 20
    }
  },
  textContainer: {

  },
  createAccountText: {
    fontSize: '16px',
    fontWeight: 500,
    color: theme_fpl.palette.common.white,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  resendCodeTextContainer: {
    textAlign: 'center',
    paddingBottom: '2em',
  },
  resendCodeText: {
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme_fpl.palette.secondary.main,
    cursor: 'pointer',
  },

  progress: {
    width: '100%',
    textAlign: 'center'
  },
  errorMessage: {
    width: '100%',
    color: theme_fpl.palette.primary.main,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '16px'
  },
  inputIcon: {
    color: theme_fpl.palette.common.white,
  },
  inputSubtext: {
    color: theme_fpl.palette.grey[500],
    fontFamily: theme_fpl.palette.text.primary,
    fontSize: '12px',
    marginTop: '1.5em',
    marginBottom: '1.5em',
    paddingLeft: '1em',
    // textAlign: 'center',
  }
}));
