import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  walletAddressContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    // alignItems: 'center',
  },
  walletText: {
    marginLeft: 10,
    fontSize: '14px',
    fontWeight: 500,
    color: theme_fpl.palette.common.white,
    width: '100%',
    maxWidth: '100%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
    marginRight: '20px',
  },
  walletSidebar: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  sidebarContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    marginTop: '5px',
    alignSelf: 'flex-start',
  },
  questionIcon: {
    // marginBottom: 2,
    fontSize: 20,
    padding: 5,
    color:  theme_fpl.palette.common.black,
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: theme_fpl.palette.secondary.main,
  },
  helpText: {
    marginRight: 5,
  },
  walletAmountContainer: {
    marginTop: 10,
    marginBottom: 5,
    borderBottom: `1px solid ${theme_fpl.palette.primary.main}`
  },
  walletAmount: {
    fontSize: '24px',
    fontWeight: 700,
    marginLeft: 30,
    color: theme_fpl.palette.secondary.main,
  },
  minimumWarning: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme_fpl.palette.common.white,
  },
  tapAndPlay: {
    marginTop: 40,
    marginBottom: 40,
    fontSize: '20px',
    textAlign: 'center',
  },
  enterTeam: {
    fontSize: '20px',
    textAlign: 'center',
    color: theme_fpl.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
}));
