import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import { getNextGameWeekSelector, getPublicH2HSelector, getPublicLeaguesSelector } from 'core/selectors/LeagueSelectors';
import { getUserSelector } from 'core/selectors/UserSelectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './Tournaments.styles';
import { CompetitionType, ILeague, LeagueType } from 'core/models/Models';
import clsx from 'clsx';
import FPLTable from 'components/Table/FPLTable';
import { getCognitoUserSelector } from 'core/selectors/AuthSelectors';
import { USER } from 'core/actions/UserActions';
import { getLoadingStatusSelector } from 'core/selectors/LoadingSelectors';
import { getPublicLeagues, joinLeague } from 'core/actions/LeagueActions';
import LeaguesFilter from './LeaguesFilter';



interface IProps {
  isMobile: boolean;
}


const Tournaments = ({isMobile}: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const nextGameWeek = useSelector(getNextGameWeekSelector);
  const user = useSelector(getUserSelector);
  const cognitoUser = useSelector(getCognitoUserSelector);
  const [selectedType, setSelectedType] = useState(CompetitionType.LEAGUE);
  const isLoading = useSelector(getLoadingStatusSelector(USER.GET.REQUEST));
  const publicLeagues = useSelector(getPublicLeaguesSelector);
  const publicH2H = useSelector(getPublicH2HSelector);

  const [filteredLeagues, setFilteredLeagues] = useState(publicLeagues);
  const [filteredH2H, setFilteredH2H] = useState(publicH2H);

  const disabledLeagues = publicLeagues
    ?.filter((league) => league?.maxPlayers == league.playersNumber || user?.usersLeagues?.some((userLeague) => userLeague.leagueId === league.leagueId))
    .map((league) => league.leagueId);

  const disabledH2H = publicH2H
    ?.filter((league) => league?.maxPlayers == league.playersNumber || user?.usersLeagues?.some((userLeague) => userLeague.leagueId === league.leagueId))
    .map((league) => league.leagueId);


  useEffect(() => {
    if (!publicLeagues || publicLeagues.length == 0) {
      dispatch(getPublicLeagues.request());
    }
  }, []);


  useEffect(() => {
    const h2hIds = filteredH2H.map((h2h) => h2h.leagueId);
    const updatedFilteredH2H = publicH2H.filter((h2h) => h2hIds.includes(h2h.leagueId));
    setFilteredH2H(updatedFilteredH2H);
  }, [publicH2H]);


  const onJoinLeague = (leagueId) => {
    dispatch(joinLeague.request({
      leagueType: LeagueType.PUBLIC,
      competitionType: CompetitionType.LEAGUE,
      leagueId: leagueId,
    }))
  }

  const onJoinH2H = (leagueId) => {
    dispatch(joinLeague.request({
      leagueType: LeagueType.PUBLIC,
      competitionType: CompetitionType.H2H,
      leagueId: leagueId
    }));
  }


  const filterLeagues = (leagues, value) => {
    return leagues.filter((league: ILeague) =>
      league.entryPrice == value)
  }


  const handleFilter = (value) => {
    if (value == 0) {
      setFilteredLeagues(publicLeagues);
      setFilteredH2H(publicH2H);
    } else {
      setFilteredLeagues(filterLeagues(publicLeagues, value));
      setFilteredH2H(filterLeagues(publicH2H, value));
    }
  }

  const onLeagueClicked = () => {
    setSelectedType(CompetitionType.LEAGUE);
  }

  const onH2HClicked = () => {
    setSelectedType(CompetitionType.H2H);
  }


  const leagueOrH2HFilter = () =>
    <div className={classes.container}>
      <div
        onClick={onLeagueClicked}
        className={clsx(classes.chip, classes.leftChip, selectedType === CompetitionType.LEAGUE ? classes.selectedChip : classes.noteSlectedChip)}
      >
        League
      </div>
      <div
        onClick={onH2HClicked}
        className={clsx(classes.chip, classes.rightChip, selectedType === CompetitionType.H2H ? classes.selectedChip : classes.noteSlectedChip)}
        >
        H2H
      </div>
    </div>


  return (
    <>
      <BoxedComponent paddingLeft='1.5em' paddingRight='1.5em'>
        {leagueOrH2HFilter()}
        <LeaguesFilter onFilter={handleFilter}/>
      </BoxedComponent>

      <div className={classes.tablesContainer}>
      {selectedType == CompetitionType.LEAGUE ? (
        <div className={classes.tableContainer}>
          <FPLTable isMobile={isMobile} title={'Public Leagues'} leagues={filteredLeagues} disabledLeagueIds={disabledLeagues} user={user} cognitoUser={cognitoUser} onJoinLeague={onJoinLeague} competitionType={CompetitionType.LEAGUE}/>
        </div>
        ) : (
        <div className={classes.tableContainer}>
          <FPLTable isMobile={isMobile} title={'Public H2H'} leagues={filteredH2H} disabledLeagueIds={disabledH2H} user={user} cognitoUser={cognitoUser} onJoinLeague={onJoinH2H} competitionType={CompetitionType.H2H}/>
        </div>
      )}
      </div>
    </>
  );
};

export default Tournaments;
