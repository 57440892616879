import React from 'react';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from 'config/AppRoutes';

interface Props {
  children: any;
  isAuthorized: boolean;
  userExists?: boolean;
}

const ProtectedRoute = ({ children, isAuthorized, userExists }: Props) => {
  if (!isAuthorized) {
    return <Navigate to={AppRoutes.login.link} replace />;
  }
    // if (!userExists) {
    //   return <Navigate to={AppRoutes.teamID.link} />;
    // }
  return children;
};

export default ProtectedRoute;
