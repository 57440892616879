import { makeStyles } from "@mui/styles";
import theme_fpl from "utils/Theme";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    backgroundColor: '#FFFFE0', // Light pink
    color: theme_fpl.palette.common.black,
    padding: '10px',
    borderRadius: '5px',
  },
  exclamationIcon: {
    color: '#FFFF00', // Dark red
    marginRight: '10px',
    flexShrink: 0, // Prevent the icon from shrinking when the text wraps into multiple lines
  },
  text: {
    flex: 1,
    overflowWrap: 'break-word', // This prevents the icon from shrinking when the text wraps into multiple lines.
  },
  closeButton: {
    marginTop: -10,
    marginLeft: 'auto', // Push the close icon to the rightmost side of the container
  },
  }));

  export default useStyles;
