import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';


const useStyles = makeStyles((theme) => ({
  inputIcon: {
    color: theme_fpl.palette.secondary.main,
  },
  dropDownContainer: {
    marginBottom: 10,
  },
  percentagesContainer: {
    marginTop: '2em',
  },
  label: {
    fontSize: '1em',
    fontWeight: 600,
    color: theme_fpl.palette.grey[500],
    marginBottom: 10,
  },
  percentagesItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  percentagesItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  percentageLabel: {
    fontSize: '1.5em',
    fontWeight: 600,
    color: theme_fpl.palette.grey[500],
    marginRight: 10,
  },
  percentageSymbolLabel: {
    fontSize: '1.5em',
    fontWeight: 600,
    color: theme_fpl.palette.common.white,
    marginLeft: 10,
  },
  dialogContainer: {
    backgroundColor: theme_fpl.palette.grey[600],
  },
  dialogTitle: {
    backgroundColor: theme_fpl.palette.grey[600],
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  percentageIcon: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: theme_fpl.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  icon: {
    fontSize: 12,
    color: theme_fpl.palette.common.black,
  },
}));

export default useStyles;
