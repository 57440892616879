import { AppState } from 'core/reducers/RootReducer';
import { createSelector } from 'reselect';

export const getLoadingStatus = (state: AppState, action) => state?.loading?.[action];

export const getLoadingStatuses = (state: AppState, actions: Array<string>) => {
  let isLoading = false;

  for (const action of actions) {
    if (state?.loading?.[action]) {
      isLoading = true;
      break;
    }
  }

  return isLoading;
};

export const getLoadingStatusSelector = (actionType: string) =>
  createSelector(
    (state: AppState) => getLoadingStatus(state, actionType),
    (loading) => loading
  );

  export const getLoadingStatusesSelector = (actions: Array<string>) =>
  createSelector(
    (state: AppState) => getLoadingStatuses(state, actions),
    (loading) => loading
  );
