import { AppState } from '../reducers/RootReducer';
import { createSelector } from 'reselect';
import { ICognitoUser } from 'core/models/Models';

const getCognitoUser = (state: AppState): ICognitoUser => state.auth.cognitoUser;
const getIsAuthenticated = (state: AppState): boolean => state.auth.isAuthenticated;
const getAccessToken = (state: AppState): any => state.auth.cognitoUser.signInUserSession?.accessToken?.jwtToken;
const getIdToken = (state: AppState): any => state.auth.cognitoUser.signInUserSession?.idToken?.jwtToken;
const getRefreshToken = (state: AppState): any => state.auth.cognitoUser.signInUserSession?.refreshToken?.token;
const getSessionToken = (state: AppState) => state.auth.cognitoUser.Session;
const getUserIdentityId = (state: AppState) => state.auth.cognitoUser.attributes?.sub;
const getUserPoolId = (state: AppState) => state.auth.cognitoUser.pool?.userPoolId;
const getSignupForm = (state: AppState) => state.auth.signupForm;
const getSigninForm = (state: AppState) => state.auth.signinForm;
const getCognitoAttibutesVerification = (state: AppState) => state.auth.cognitoAttributesVerification;

export const getCognitoUserSelector = createSelector(getCognitoUser, (cognitoUser) => cognitoUser);
export const getCognitoAttibutesVerificationSelector = createSelector(
  getCognitoAttibutesVerification,
  (attributes) => attributes
);
export const getIsAuthenticatedSelector = createSelector(getIsAuthenticated, (isAuthenticated) => isAuthenticated);
export const getAccessTokenSelector = createSelector(getAccessToken, (accessToken) => accessToken);
export const getIdTokenSelector = createSelector(getIdToken, (idToken) => idToken);
export const getRefreshTokenSelector = createSelector(getRefreshToken, (refreshToken) => refreshToken);
export const getSessionTokenSelector = createSelector(getSessionToken, (sessionToken) => sessionToken);
export const getUserIdentityIdSelector = createSelector(getUserIdentityId, (userIdentityId) => userIdentityId);
export const getUserPoolIdSelector = createSelector(getUserPoolId, (userPoolId) => userPoolId);
export const getSignupFormSelector = createSelector(getSignupForm, (signupForm) => signupForm);
export const getSigninFormSelector = createSelector(getSigninForm, (signinForm) => signinForm);
