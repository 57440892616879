import { makeStyles } from '@material-ui/core/styles';
import theme_fpl from 'utils/Theme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2em',
    paddingBottom: '2em'
  },
  image: {
    marginRight: theme.spacing(2), // Adjust the spacing between the image and text
  },
  heading: {
    textAlign: 'center',
    verticalAlign: 'middle',
    textTransform: 'uppercase',
    fontSize: theme_fpl.typography.subtitle1.fontSize,
    fontWeight: 600,
  },
}));

export default useStyles;