import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';


export const useStyles = makeStyles(() => ({
  menuItem: {
    // paddingLeft: '30px',
    margin: '2px auto',
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      minWidth: 'auto'
    },
    '&:focus': {
      backgroundColor: theme_fpl.palette.secondary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme_fpl.palette.common.white
      },
      '& > .MuiListItemIcon-root': {
        backgroundColor: 'white',
        color: theme_fpl.palette.secondary.main
      }
    },
    '&:hover': {
      backgroundColor: theme_fpl.palette.secondary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme_fpl.palette.common.white
      },
      '& > .MuiListItemIcon-root': {
        backgroundColor: 'white',
        color: theme_fpl.palette.secondary.main
      }
    }
  },
  menuItemDisabled: {
    pointerEvents: 'none',
    minWidth: 'auto',
    paddingLeft: '30px',
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      minWidth: 'auto'
    }
  },
  itemTextMobile: {
    color: theme_fpl.palette.common.white,
    '& > span': {
      fontSize: '12px',
      fontWeight: '500',
      textAlign: 'center',
    },
    textTransform: 'uppercase'
  },
  itemText: {
    color: theme_fpl.palette.common.white,
    '& > span': {
      fontSize: '14px',
      fontWeight: '600',
      textAlign: 'center',
    },
    textTransform: 'uppercase'
  }
}));
