import React, { useState } from 'react';
import { useStyles } from './JoinPrivate.styles';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme_fpl from 'utils/Theme';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import { joinLeague } from 'core/actions/LeagueActions';
import { CompetitionType, LeagueType } from 'core/models/Models';
import { useDispatch } from 'react-redux';
import JoinPrivateLeagueDialog from 'components/Join/JoinPrivateLeagueDialog';

interface Props {
  // onSignIn: (email: string, password: string) => void;
  // onForgotPassword: (email: string) => void;
  isMobile: boolean;
}

const JoinPrivate: React.FC<Props> = ( {isMobile }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isJoinLeagueOpen, setIsJoinLeagueOpen] = useState(false);
  const [isJoinH2HOpen, setIsJoinH2HOpen] = useState(false);


  const toggleJoinLeague = () => {
    setIsJoinLeagueOpen(!isJoinLeagueOpen);
  }

  const toggleJoinH2H = () => {
    setIsJoinH2HOpen(!isJoinH2HOpen);
  }


  const handleJoinPrivateLeague = (code) => {
    dispatch(joinLeague.request({
      leagueType: LeagueType.PRIVATE,
      competitionType: CompetitionType.LEAGUE,
      entryCode: code
    }))
  }

  const handleJoinPrivateH2H = (code) => {
    dispatch(joinLeague.request({
      leagueType: LeagueType.PRIVATE,
      competitionType: CompetitionType.H2H,
      entryCode: code
    }))
  }



// TODO smaller root padding when mobile
  const titleClass = {
    fontSize: isMobile ? theme_fpl.typography.subtitle1.fontSize : theme_fpl.typography.subtitle1.fontSize,
  };

  return (
    <>
      <BoxedComponent>
        <Grid className={classes.innerContainer}>
          <Grid className={classes.headerText} style={titleClass}>
            Join private
          </Grid>
          <Grid className={classes.createJoinContainer}>
            <Button className={classes.button} onClick={toggleJoinLeague}>
              League
            </Button>
            <Button className={classes.button} onClick={toggleJoinH2H}>
              H2H
            </Button>
          </Grid>
        </Grid>
      </BoxedComponent>
      <JoinPrivateLeagueDialog onSubmit={handleJoinPrivateLeague} onClose={toggleJoinLeague} isOpen={isJoinLeagueOpen} competitionType={'league'}/>
      <JoinPrivateLeagueDialog onSubmit={handleJoinPrivateH2H} onClose={toggleJoinH2H} isOpen={isJoinH2HOpen} competitionType={'H2H'}/>
    </>
  );
};

export default JoinPrivate;
