import { useStyles } from './styles/CreateContainer.styles';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import ImageComponent from '../components/ImageComponent/ImageComponent';
import images from 'assets/images';
import BoxedComponent from '../components/Shared/Container/BoxedComponent';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import CreateLeague from 'components/Create/CreateLeague';
import CreateMobileHeader from 'components/Create/CreateMobileHeader';
import LeagueOrH2HHeader from 'components/Create/LeagueOrH2HHeader';


export default function CreatLeagueContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();


  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;

  const isMobile = width < breakpoint;
  const isDesktop = width >= breakpoint;


  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);



  return (
    <ResponsiveContainer showCarousel={false} splitOn={2} isMobile={isMobile}>
      {isDesktop ? (
      <BoxedComponent height='100%'>
        <Grid className={classes.topImageContainer}>
          <ImageComponent heading={'Leagues'}/>
        </Grid>
      </BoxedComponent>
      ) : (
      <CreateMobileHeader heading={"Create league"}>
        <LeagueOrH2HHeader selected={'league'}/>
      </CreateMobileHeader>
      )}
      {isDesktop && (
        <Grid className={classes.imageContainer}>
          <img src={images.desktopInnerImage.src} alt={images.desktopInnerImage.alt}/>
        </Grid>
      )}
      {isDesktop && (
        <BoxedComponent>
          <LeagueOrH2HHeader selected={'league'}/>
        </BoxedComponent>
      )}
      <CreateLeague/>
  </ResponsiveContainer>
  )
}