import React, { useState } from 'react';
import { IconButton, Input, InputAdornment } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';

const valuesArray = [2, 3, 5, 10, 20, 30, 50, 100, 200, 300, 400, 500]; // Array of values to choose from

interface IProps {
  value: any;
  setValue: (value) => void;
}

const ValueInput = ({value, setValue}: IProps) => {
  const theme = useTheme();

  const handleIncrement = () => {
    const currentIndex = valuesArray.indexOf(value);
    if (currentIndex < valuesArray.length - 1) {
      setValue(valuesArray[currentIndex + 1]);
    }
  };

  const handleDecrement = () => {
    const currentIndex = valuesArray.indexOf(value);
    if (currentIndex > 0) {
      setValue(valuesArray[currentIndex - 1]);
    }
  };

  return (
    <Input
      value={value}
      disabled
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={handleIncrement} style={{ color: theme.palette.secondary.main }}>
            <FontAwesomeIcon icon={faArrowUp} />
          </IconButton>
          <IconButton onClick={handleDecrement} style={{ color: theme.palette.secondary.main }}>
            <FontAwesomeIcon icon={faArrowDown} />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default ValueInput;
