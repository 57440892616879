import { iNotification, Store } from 'react-notifications-component';

const notification = {
  message: 'FPL',
  type: 'info',
  title: 'info',
  insert: 'bottom',
  container: 'bottom-left',
  animationIn: ['animate__animated animate__fadeIn'],
  animationOut: ['animate__animated animate__fadeOut'],
  dismiss: {
    duration: 3000,
    onScreen: true,
    pauseOnHover: true
  }
};

export const setSuccessAlert = (message: string) =>
  Store.addNotification({
    ...notification,
    title: 'Success',
    message,
    type: 'success'
  } as iNotification);

export const setInfoAlert = (message: string) =>
  Store.addNotification({
    ...notification,
    title: 'Info',
    message
  } as iNotification);

export const setErrorAlert = (message: string) =>
  Store.addNotification({
    ...notification,
    title: 'Error',
    message,
    type: 'danger'
  } as iNotification);

export const setWarningAlert = (message: string) =>
  Store.addNotification({
    ...notification,
    title: 'Warning',
    message,
    type: 'warning'
  } as iNotification);

export const setToastNotification = (contentElement: any) => {
  Store.addNotification({
    ...notification,
    content: contentElement
  } as iNotification);
};
