import { useStyles } from './styles/TournamentsContainer.styles';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import images from 'assets/images';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import CreateMobileHeader from 'components/Create/CreateMobileHeader';
import Account from 'components/Tournamets/Account';
import { getUserSelector } from 'core/selectors/UserSelectors';
import JoinPrivate from 'components/Home/JoinPrivate';
import { getPublicLeagues, joinLeague } from 'core/actions/LeagueActions';
import { USER } from 'core/actions/UserActions';
import { getPublicLeaguesSelector, getPublicH2HSelector } from 'core/selectors/LeagueSelectors';
import { getLoadingStatusSelector } from 'core/selectors/LoadingSelectors';
import Tournaments from 'components/Tournamets/Tournaments';


export default function TournamentsContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  const isLoading = useSelector(getLoadingStatusSelector(USER.GET.REQUEST));
  const publicLeagues = useSelector(getPublicLeaguesSelector);



  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;

  const isMobile = width < breakpoint;
  const isDesktop = width >= breakpoint;


  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);


  useEffect(() => {
    if (!publicLeagues || publicLeagues.length == 0) {
      dispatch(getPublicLeagues.request());
    }
  }, []);



  return (
    <ResponsiveContainer splitOn={2} isMobile={isMobile} showCarousel={false}>
      <Account isMobile={isMobile} user={user}/>
      {isDesktop && (
        <Grid className={classes.imageContainer}>
          <img src={images.desktopInnerImage.src} alt={images.desktopInnerImage.alt}/>
        </Grid>
      )}
      <Tournaments isMobile={isMobile}/>

      {/* <div className={classes.leagueContainer}>
        <JoinPrivate isMobile={isMobile}/>
      </div> */}
  </ResponsiveContainer>
  )
}
