import { all, put, takeLatest } from 'redux-saga/effects';
import { API, Auth } from 'aws-amplify';
import * as Alert from 'utils/Alerts';
import { createUserMutation, refreshBalanceMutation, withdrawMutation } from 'api/graphql/mutations';
import { REFRESH_BALANCE, USER, WITHDRAW, createUser, getUser, refreshWalletBalance, withdraw } from 'core/actions/UserActions';
import { getUserByCognitoId } from 'api/graphql/queries';
import { globalNavigate } from 'utils/global-history';
import { AppRoutes } from 'config/AppRoutes';

export function* createUsersSaga({ payload: { teamId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const cognitoId = user?.attributes?.sub;
    const email = user?.attributes?.email;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: createUserMutation(cognitoId, email, teamId)
      }
    };

    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);
    const errors = response?.errors;
    if (errors) {
        throw Error(errors[0])
    }
    const newUser = response?.data?.createUser;

    yield put(createUser.success(newUser));
    globalNavigate(AppRoutes.home.link);
  } catch (e: any) {
    console.log(e);
    yield put(createUser.failure(e));
    yield Alert.setErrorAlert('An error occurred while creating a new user');
  }
}


export function* refreshWalletBalanceSaga() {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const cognitoId = user?.attributes?.sub;
    const email = user?.attributes?.email;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: refreshBalanceMutation(cognitoId)
      }
    };

    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);
    const errors = response?.errors;
    if (errors) {
        throw Error(errors[0])
    }
    const balance = response?.data?.refreshBalance?.balance;
    yield Alert.setSuccessAlert('Balance refresh done');
    yield put(refreshWalletBalance.success(balance));
  } catch (e: any) {
    console.log(e);
    yield put(refreshWalletBalance.failure(e));
    yield Alert.setErrorAlert('An error occurred while getting balance');
  }
}


export function* withdrawSaga({ payload: { withdrawAmount, withdrawAddress } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const cognitoId = user?.attributes?.sub;

    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: withdrawMutation(cognitoId, withdrawAmount, withdrawAddress)
      }
    };

    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);
    const errors = response?.errors;
    if (errors) {
        throw Error(errors[0])
    }
    const walletBalance = response?.data?.withdraw?.walletBalance;
    yield Alert.setSuccessAlert('Withdraw request successfully sent');
    yield put(withdraw.success(walletBalance));
  } catch (e: any) {
    console.log(e);
    yield put(refreshWalletBalance.failure(e));
    yield Alert.setErrorAlert('An error occurred while sending withdraw request');
  }
}


export function* getUserSaga() {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: getUserByCognitoId(cognitoId)
      }
    };

    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);
    const errors = response?.data?.userById?.errors;
    if (errors) {
      throw Error(errors[0])
    }
    const newUser = response?.data?.user;
    if (newUser == null) {
      throw Error('User does not exist')
    }

    yield put(getUser.success(newUser));
  } catch (e: any) {
    console.log(e);
    yield put(getUser.failure(e));
    globalNavigate(AppRoutes.teamID.link);
    // yield Alert.setErrorAlert('Could not fetch user');
  }
}

function* userSaga() {
    yield all([
      takeLatest(USER.POST.REQUEST, createUsersSaga),
      takeLatest(USER.GET.REQUEST, getUserSaga),
      takeLatest(REFRESH_BALANCE.POST.REQUEST, refreshWalletBalanceSaga),
      takeLatest(WITHDRAW.POST.REQUEST, withdrawSaga),
    ])
}

export default userSaga;
