import Input from 'components/Shared/Input/Input';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useStyles } from './ForgotPassword.styles';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Grid, Icon } from '@mui/material';
import { FaAt } from 'react-icons/fa';

interface Props {
  open: boolean;
  closeRequestDialog: () => void;
  onSubmitRequestDialog: (email: string) => void;
}

const schema = yup.object({
  email: yup.string().email('Enter a valid email address').required('Enter a valid email address')
});

export default function ForgotPassword({ open, closeRequestDialog, onSubmitRequestDialog }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(true);

  const handleClose = (e) => {
    closeRequestDialog();
  };

  const handleInputChange = ({ target: { value } }) => {
    setEmail(value);
    hasError(value);
  }

  const handlePressEnter = (event) => {
    if (!isError && event.keyCode === 13) {
      event.preventDefault();
      onSubmitRequestDialog(email);
    }
  };

  const hasError = (value) => {
    schema.isValid({ email: value }).then((valid) => {
      setIsError(!valid);
    });
  }

  return (
    <Dialog
      classes={{ paper: classes.requestDialogContainer }}
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'>
      <React.Fragment>
        <div className={classes.titleWrapper}>
          <div className={classes.requestDialogTitle}>{"Password reset"}</div>
          <div className={classes.requestDialogSubtitle}>{"A verification code will be sent to your email address"}</div>
        </div>
        <Input
          label='Email'
          name='email'
          startIcon={<Icon fontSize={'small'} className={classes.inputIcon}><FaAt/></Icon>}
          onChange={handleInputChange}
          helperText='Invalid email'
          value={email}
          isRequired
        />
        <Grid className={classes.button}>
          <Button
            color='secondary'
            onClick={() => onSubmitRequestDialog(email)}
            variant='contained'
            style={{borderRadius: 20, height: 45}}
            onKeyUp={handlePressEnter}
            disabled={isError}>
            CONFIRM
          </Button>
        </Grid>
      </React.Fragment>
    </Dialog>
  );
}
