import React from 'react';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';

const LoaderPopup = ({ isOpen }) => {
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <DialogContent>
        <CircularProgress color="primary" size={40} style={{outline: 'none'}} />
      </DialogContent>
    </Dialog>
  );
};

export default LoaderPopup;
