import React, { useEffect, useState } from 'react';
import { useStyles } from './styles/TeamID.styles';
import { Divider, Grid, Icon, IconButton, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import images from 'assets/images';

interface Props {
  isMobile: boolean;
}


const ImagePopup = ({ imgSrc, onClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.imagePopupOverlay} onClick={onClose}>
      <img src={imgSrc}/>
    </div>
  );
};


const TeamID: React.FC<Props> = ({isMobile}: Props) => {
  const { t } = useTranslation();

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // TODO is user/team id exists, redirect

  const handleImageClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <Grid>
      <h2>What is FPL Team ID?</h2>
      <p>
      Every Fantasy Premier League account has a unique identifier called <b>FPL Team ID</b> and it is assigned based
      on when you signup for the game once the 2023/24 FPL season goes live.
      The earlier you signup for FPL when the game goes live the lower your team ID will be for the season
      </p>
      <h2>How to find your FPL Team ID?</h2>
      The below steps will show you how to find your FPL Team ID:
      <ul>
        <li>Login to your FPL account and navigate to the points page/gameweek history page</li>
        <li>The URL will have a unique number between /entry/ and /event/ which is your FPL team ID</li>
        <li>In the below example, the team ID is 123152</li>
        <li>You need to do this via a browser on a mobile/tablet/laptop/desktop.</li>
      </ul>
      <img src={isMobile ? images.teamID2.src : images.teamID.src} onClick={handleImageClick}/>
      {isPopupOpen && (
        <ImagePopup imgSrc={isMobile ? images.teamID2.src : images.teamID2.src} onClose={handleClosePopup} />
      )}
    </Grid>
  );
};

export default TeamID;
