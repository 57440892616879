import { produce } from 'immer';
import { CompetitionType, IActivity, IGameWeek, ILeague, IUserLeague, LeagueType } from 'core/models/Models';
import { GAMEWEEK, H2H_DETAILS, JOIN_LEAGUE, LEAGUE, LEAVE_LEAGUE, PUBLIC_LEAGUES, USERS_LEAGUES, USERS_PRIVATE_LEAGUES } from 'core/actions/LeagueActions';

export interface ILeagueState {
  publicLeagues: ILeague[];
  publicH2H: ILeague[];
  nextGameWeek: IGameWeek;
  usersCurrentLeagues: IUserLeague[];
  usersCurrentH2H: IUserLeague[];
  usersFinishedLeagues: IUserLeague[];
  usersFinishedH2H: IUserLeague[];
}

const emptyGameWeek = {
  id: 0,
  name: '',
  deadlineTime: ''
}


const initialState: ILeagueState = {
  publicLeagues: [] as ILeague[],
  publicH2H: [] as ILeague[],
  nextGameWeek: emptyGameWeek,
  usersCurrentLeagues: [] as IUserLeague[],
  usersCurrentH2H: [] as IUserLeague[],
  usersFinishedLeagues: [] as IUserLeague[],
  usersFinishedH2H: [] as IUserLeague[],
}



const leagueReducer = (state = initialState, action: IActivity): ILeagueState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case LEAGUE.POST.SUCCESS:
        if (payload.leagueType == LeagueType.PUBLIC) {
          if (payload.competitionType == CompetitionType.LEAGUE) {
            draft.publicLeagues.push(payload);
          } else {
            draft.publicH2H.push(payload);
          }
        }
        if (payload.competitionType == CompetitionType.LEAGUE) {
          draft.usersCurrentLeagues.push(payload);
        } else {
          draft.usersCurrentH2H.push(payload);
        }
        break;
      case GAMEWEEK.GET.SUCCESS:
        draft.nextGameWeek = payload;
        break;
      case GAMEWEEK.GET.FAILURE:
        draft.nextGameWeek = emptyGameWeek;
        break;
      case PUBLIC_LEAGUES.GET.SUCCESS:
        const newPublicLeagues = payload?.publicLeagues?.map((league) => {
          return {
            ...league,
            competitionType: CompetitionType.LEAGUE,
            leagueType: LeagueType.PUBLIC
          }})
        draft.publicLeagues = newPublicLeagues;
        const newPublicH2H = payload?.publicH2H?.map((league) => {
          return {
            ...league,
            competitionType: CompetitionType.H2H,
            leagueType: LeagueType.PUBLIC
          }})
        draft.publicH2H = newPublicH2H;
        break;
      case JOIN_LEAGUE.POST.SUCCESS:
        if (payload.competitionType == CompetitionType.LEAGUE) {
          draft.usersCurrentLeagues.push(payload)
        } else {
          draft.usersCurrentH2H.push(payload)
        }
        break;
      case LEAVE_LEAGUE.POST.SUCCESS:
        if (payload.competitionType == CompetitionType.LEAGUE) {
          draft.usersCurrentLeagues = draft.usersCurrentLeagues.filter((league) =>
            league.leagueId != payload.leagueId)
        } else {
          draft.usersCurrentH2H = draft.usersCurrentH2H.filter((league) =>
            league.leagueId != payload.leagueId)
        }
        // }
        break;
      case USERS_LEAGUES.GET.SUCCESS: {
        draft.usersCurrentLeagues = payload.usersCurrentLeagues;
        draft.usersCurrentH2H = payload.usersCurrentH2H;
        draft.usersFinishedLeagues = payload.usersFinishedLeagues;
        draft.usersFinishedLeagues = payload.usersFinishedLeagues;
        break;
      }
      case H2H_DETAILS.GET.SUCCESS: {
        if (payload.leagueType == LeagueType.PUBLIC) {
          const h2h = draft.publicH2H.find((league) => league.leagueId == payload.leagueId);
          if (h2h) {
            const updatedH2H = {
              ...h2h,
              opponentName: payload.opponentName
            }
            draft.publicH2H = draft.publicH2H.map((league) => league.leagueId != payload.leagueId ? league : updatedH2H);
          }
        }
        const currentH2h = draft.usersCurrentH2H.find((league) => league.leagueId == payload.leagueId);
        if (currentH2h) {
          const updatedH2H = {
            ...currentH2h,
            opponentName: payload.opponentName
          }
          draft.usersCurrentH2H = draft.usersCurrentH2H.map((league) => league.leagueId != payload.leagueId ? league : updatedH2H);
        }
        const finishedH2H = draft.usersFinishedH2H.find((league) => league.leagueId == payload.leagueId);
        if (finishedH2H) {
          const updatedH2H = {
            ...finishedH2H,
            opponentName: payload.opponentName
          }
          draft.usersFinishedH2H = draft.usersFinishedH2H.map((league) => league.leagueId != payload.leagueId ? league : updatedH2H);
        }
        break;
      }

      default:
        return draft;
    }
  });

export default leagueReducer;
