import makeStyles from '@mui/styles/makeStyles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'baseline',
    // padding: '0px 20px',
    flexWrap: 'wrap'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  link: {
    display: 'flex',
    textDecoration: 'none',
    color: theme_fpl.palette.common.white,
    fontSize: '16px',
    textTransform: 'uppercase',
    padding: '5px',
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    fontWeight: 700,
    height: 48,
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: theme_fpl.palette.secondary.main,
      color: theme_fpl.palette.common.black,
    },
    alignItems: 'center',
  },
  linkMobile: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: theme_fpl.palette.common.white,
    fontSize: '12px',
    textTransform: 'uppercase',
    paddingRight: '6px',
    paddingLeft: '6px',
    paddingTop: '10px',
    paddingBottom: '10px',
    fontWeight: 500,
    // height: 42,
    alignSelf: 'center',
    '& span': {
      color: theme_fpl.palette.grey[800],
    },
    '& div': {
      backgroundColor: theme_fpl.palette.grey[600],
      boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.5)',
    },
    '& svg': {
      fill: theme_fpl.palette.common.white
    },
    alignItems: 'center',
  },
  linkWithBorder: {
    borderRightWidth: '1px',
    borderRightColor: theme_fpl.palette.secondary.main,
    borderRightStyle: 'solid',
  },
  activeLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme_fpl.palette.common.black,
    fontSize: '16px',
    textTransform: 'uppercase',
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    fontWeight: 700,
    backgroundColor: theme_fpl.palette.secondary.main,
    height: 48,
  },
  activeLinkMobile: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textDecoration: 'none',
    // color: theme_fpl.palette.common.black,
    fontSize: '12px',
    textTransform: 'uppercase',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
    alignSelf: 'center',
    '& span': {
      // Styles for the span inside the parent container
      // Adjust these styles as needed
      fontWeight: 600,
      color: theme_fpl.palette.common.white,
    },
    '& div': {
      backgroundColor: theme_fpl.palette.secondary.main,
      color: theme_fpl.palette.common.black
    },
    '& svg': {
      fill: theme_fpl.palette.common.black
    },
  },
  activeLinkWithBorder: {
    borderRightWidth: '2px',
    borderRightColor: theme_fpl.palette.primary.main,
    borderRightStyle: 'solid',
  },
  moreLink: {
    marginLeft: 100,
    paddingLeft: '10px',
    display: 'flex',
    textDecoration: 'none',
    color: theme_fpl.palette.common.white,
    fontSize: '16px',
    textTransform: 'uppercase',
    fontWeight: 700,
    height: 48,
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: theme_fpl.palette.secondary.main,
      color: theme_fpl.palette.common.black,
    },
    alignItems: 'center',
  },
  activeMoreLink: {
    marginLeft: 100,
    display: 'flex',
    textDecoration: 'none',
    color: theme_fpl.palette.common.white,
    fontSize: '16px',
    textTransform: 'uppercase',
    fontWeight: 700,
    height: 48,
    alignSelf: 'center',
    backgroundColor: theme_fpl.palette.secondary.main,
    alignItems: 'center',
  },
  moreLinkMobile: {
    fontSize: 30,
    alignSelf: 'center',
    color: theme_fpl.palette.secondary.main,
    alignItems: 'center',
    marginBottom: 5,
  },
  activeMoreLinkMobile: {
    height: 48,
    paddingTop: 5,
    alignSelf: 'center',
    backgroundColor: theme_fpl.palette.secondary.main,
    alignItems: 'center',
  },
  moreContainer: {
    borderTop: `1px solid ${theme_fpl.palette.primary.main}`,
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    padding: '1em',
  },
  navIconContainer: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: '#e9e9e9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navIcon: {
    fontSize: 16,
  },
  navText: {
    marginTop: 10,
    color: theme_fpl.palette.grey[800],
  },
  moreText: {
    marginTop: 15,
    color: theme_fpl.palette.grey[800],
  },
  moreTextActive: {
    marginTop: 15,
    color: `${theme_fpl.palette.common.white} !important`,
  },
  navTextActive: {
    marginTop: 10,
    fontWeight: 600,
  },
  moreOption: {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: theme_fpl.palette.common.white,
  },
}));
