import { useDispatch, useSelector } from 'react-redux';
import Login from 'components/Login/Login';
import { useEffect, useState } from 'react';
import { FORGOT_PASSWORD_CONFIRMATION, SIGN_IN, forgotPassword, forgotPasswordConfirmation, signInUser } from 'core/actions/AuthActions';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import { getLoadingStatusSelector } from 'core/selectors/LoadingSelectors';
import ForgotPassword from 'components/FotgotPassword/ForgotPassword';
import ForgottenPassword from 'components/Login/ForgottenPassword';


export default function ForgottenPasswordContainer() {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;
  const isLoading = useSelector(getLoadingStatusSelector(FORGOT_PASSWORD_CONFIRMATION.POST.REQUEST));

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);


  const onConfirmForgotPassword = (verificationCode, email, password) => {
    dispatch(forgotPasswordConfirmation.request(verificationCode, email, password));
  }


  return (
    <ResponsiveContainer>
      <ForgottenPassword
        onConfirmForgotPassword={onConfirmForgotPassword}
        isMobile={width < breakpoint}
        isLoading={isLoading}
        error={false} />
    </ResponsiveContainer>
  )
}
