import React, { useEffect, useState } from 'react';
import { useStyles } from './Support.styles';
import { Button, Grid, Input, TextField } from '@mui/material';
import { FaCheck, FaCopy, FaQuestion, FaWallet } from 'react-icons/fa';
import * as yup from 'yup';
import { passwordRegex } from 'utils/Helpers';
import { useTranslation } from 'react-i18next';
import theme_fpl from 'utils/Theme';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import { LoadingButton } from '@mui/lab';

interface Props {
  isMobile: boolean;
  isLoading: boolean;
  isIssueSent: boolean;
  issue: string;
  setIssue: (text: string) => void;
  onCreateNewIssue: () => void;
  onSendIssue: (issue: string) => void;
}


const Support: React.FC<Props> = ({ isMobile, isLoading, isIssueSent, issue, setIssue, onCreateNewIssue, onSendIssue }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleIssueChange = (event) => {
    setIssue(event.target.value);
  };

  const handeSendIssue = () => {
    onSendIssue(issue);
  }

  return (
    <BoxedComponent paddingLeft='1.5em' paddingRight='1.5em'>
      {isIssueSent ? (
        <>
          <p>
            Thank you for reaching out! Your issue has been successfully sent to our support team.
            We will get back to you as soon as possible.
          </p>
          <Button
            onClick={onCreateNewIssue}
            color='secondary'
            className={classes.newIssueButton}
            >
            New issue
          </Button>
        </>
      ) : (
        <>
          <p>
            Please report any problems or suggestions you have by providing a detailed description of the issue,
            including steps to reproduce it and any error messages received.
            Thank you for taking the time to report the issue.
          </p>
          <TextField
            id="issueTextArea"
            multiline
            rows={10}
            value={issue}
            variant="outlined"
            onChange={handleIssueChange}
            InputProps={{ className: classes.issueField }}
          />
          <LoadingButton
            color='secondary'
            onClick={handeSendIssue}
            loading={isLoading}
            variant='contained'
            style={{borderRadius: 20, height: 45}}
            disabled={issue.trim().length==0}>
            Send
          </LoadingButton>
        </>
      )}
   </BoxedComponent>
  )
}

export default Support;
