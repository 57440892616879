import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './ImageComponent.styles';

import images from 'assets/images';


interface IProps {
  heading: string;
}

const ImageComponent = ({heading}: IProps) => {
  const classes = useStyles();

  return (
    <div  className={classes.container}>
      <div >
        <img src={images.componentLogo.src} alt={images.componentLogo.alt}  style={{ height: '150px', width: '150px' }} className={classes.image} />
      </div>
      <div >
        <div className={classes.heading}>
          {heading}
        </div>
      </div>
    </div>
  );
};

export default ImageComponent;
