/* eslint @typescript-eslint/no-var-requires: "off" */
import {
  CONFIRM_SIGN_UP,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CONFIRMATION,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  changeCognitoPassword,
  confirmSignup,
  forgotPassword,
  forgotPasswordConfirmation,
  signInUser,
  signOutUser,
  signUpUser,
  updateSignupData,
  RESEND_CONFIRMATION_CODE,
  resendConfirmationCode,
  getCurrentAuthUser,
  CURRENT_AUTH_USER,
  updateCognitoUser,
  COGNITO_USER,
  verifyUserEmail,
  COGNITO_USER_EMAIL_VERIFICATION,
  CHANGE_COGNITO_PASSWORD,
  DEACTIVATE_USERS_PROFILE,
  deactivateUsersProfile,
  deleteUserProfile,
  DELETE_USER_PROFILE,
  UPDATE_SIGNUP_DATA,
  verifyEmailChange,
  COGNITO_USER_EMAIL_CHANGE_VERIFICATION
} from 'core/actions/AuthActions';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { AppRoutes } from 'config/AppRoutes';
import { API, Auth } from 'aws-amplify';
import { navigateToExternalPage } from 'utils/Helpers';
import i18n from 'utils/i18n';
import * as Alert from 'utils/Alerts';
import { ISignupForm, ISignupState } from 'core/models/Models';
import { globalNavigate } from 'utils/global-history';
import { updateEmailMutation } from 'api/graphql/mutations';
import { resetUserState } from 'core/actions/UserActions';


export function* getCurrentAuthUserSaga() {
  try {
    const data = yield call([Auth, Auth.currentAuthenticatedUser]);
    yield put(getCurrentAuthUser.success(data));
  } catch (e) {
    yield put(getCurrentAuthUser.failure(e));
  }
}

// TODO also update email in database
export function* updateCognitoUserSaga({ payload }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    yield Auth.updateUserAttributes(user, {
      ...payload
    });
    yield put(updateCognitoUser.success({ attributes: payload }));
    if (payload?.email) {
      yield Alert.setInfoAlert("A verification email was sent to your email address");
    } else {
      yield Alert.setSuccessAlert("Information successfully updated");
    }
  } catch (e: any) {
    yield put(updateCognitoUser.failure({ error: e }));
    if (e.code === 'NotAuthorizedException') {
      const data = yield Auth.signOut();
      yield put(signOutUser.success(data));
      globalNavigate(AppRoutes.login.link);
    }
    else {
      yield Alert.setErrorAlert("Upadte failed");
    }
  }
}

export function* verifyCognitoUserAttributSaga({payload: {email, code}}) {
  try {
    yield Auth.verifyCurrentUserAttributeSubmit('email', code);
    yield put(verifyUserEmail.success(email));
    yield Alert.setSuccessAlert('Email successfully confirmed');
  } catch (e: any) {
    yield Alert.setErrorAlert('Invalid verification code');
    yield put(verifyUserEmail.failure(e));
  }
}

export function* signInUserSaga({ payload: { email, password } }) {
  const username = email.split('@').join('_');
  try {
    const user = yield Auth.signIn({username: email, password});
    yield put(signInUser.success(user));
  } catch (e: any) {
    const code = e.code;
    if (code == 'UserNotConfirmedException') {
      yield put(signInUser.failure({
        error: e,
        signupForm: {
          email: email, password: password, state: ISignupState.ConfirmEmail, username: username
        }}));
      yield Alert.setErrorAlert('Please verify your account before logging in');
    }
    else if (code == 'NotAuthorizedException') {
      yield put(signInUser.failure(e));
      yield Alert.setErrorAlert('Invalid email and/or password');
    }
    else {
      yield put(signInUser.failure(e));
      yield Alert.setErrorAlert('Sign in not successful');
    }
  }
}



export function* signOutUserSaga() {
  try {
    const data = yield Auth.signOut();
    // TODO remove user from state
    yield put(signOutUser.success(data));
    yield put(resetUserState());
    globalNavigate(AppRoutes.login.link);
  } catch (e: any) {
    yield put(signOutUser.failure(e));
    yield Alert.setErrorAlert('Sign out not successful');
  }
}


export function* signUpUserSaga({ payload }) {
  const username = payload.email.split('@').join('_');
  try {
    const data = {
      username,
      password: payload.password,
      attributes: {
        email: payload.email,
        name: payload.teamname,
      },
      autoSignIn: {
        enabled: true
      }
    };

    const { user, userConfirmed, userSub } = yield Auth.signUp(data);
    yield put(signUpUser.success(user));
    yield put(updateSignupData({ ...payload, username: user.username, userCognitoId: userSub }));
    globalNavigate(AppRoutes.confirmSignup.link);
  } catch (e: any) {
    if (e.code == 'UsernameExistsException') {
      //TODO resend code if not confirmed
      yield Alert.setErrorAlert('User already exists');
    }
    yield put(signUpUser.failure(e));
    console.log(e);
    // yield Aler t.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
  }
}


export function* confirmSignupSaga({ payload: {signupForm}}) {
  try {
    const username = signupForm?.username || signupForm.email.split('@').join('_');
    yield Auth.confirmSignUp(username, signupForm.code);
    let redirectToLogin = true;
    if (signupForm?.password) {
      yield put(signInUser.request(signupForm?.email, signupForm?.password));
      redirectToLogin = false;
    }
    // yield delay(3000);
    // set autosignin to true
    yield put(confirmSignup.success({}));
    yield Alert.setSuccessAlert('Email verfication successful.');
    if (redirectToLogin) {
      globalNavigate(AppRoutes.login.link);
    } else {
      globalNavigate(AppRoutes.home.link);
    }
  } catch (e: any) {
      yield put(confirmSignup.failure(e));
      console.log(e);
      yield Alert.setErrorAlert('Could not verify email');
  }
}

export function* resendConfirmationCodeSaga({ payload: { email } }) {
  try {
    const username = email.split('@').join('_');
    const data = yield Auth.resendSignUp(username);
    yield put(resendConfirmationCode.success(data));
    yield Alert.setInfoAlert('A verification code was sent to your email address');
  } catch (e: any) {
    yield put(resendConfirmationCode.failure(e));
    yield Alert.setErrorAlert('Could not send email verification code');
  }
}

export function* changePasswordSaga({ payload: { oldPassword, newPassword } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const data = yield Auth.changePassword(user, oldPassword, newPassword);
    yield put(changeCognitoPassword.success(data));
    yield Alert.setSuccessAlert("Password successfully updated");
  } catch (e: any) {
    yield put(changeCognitoPassword.failure(e));
    yield Alert.setErrorAlert("Could not update password. Check if old password is correct");
  }
}

export function* forgotPasswordSaga({ payload: { email } }) {
  try {
    yield Auth.forgotPassword(email);
    yield put(forgotPassword.success('forgotPasswordSubmit'));
    yield Alert.setInfoAlert('A verification code was sent to your email address');
    globalNavigate(AppRoutes.forgottenPassword.link);
    yield put(forgotPassword.success({}));
  } catch (e: any) {
    yield put(forgotPassword.failure(e));
    yield Alert.setErrorAlert('A verification could not be sent');
  }
}

export function* forgotPasswordConfirmationSaga({ payload: { verificationCode, email, password } }) {
  try {
    const username = email.split('@').join('_');
    const data = yield Auth.forgotPasswordSubmit(username, verificationCode, password);
    yield Alert.setSuccessAlert('Password update successfully');
    yield put(forgotPasswordConfirmation.success(data));
    globalNavigate(AppRoutes.home.link);
  } catch (e: any) {
    yield put(forgotPasswordConfirmation.failure(e));
    yield Alert.setErrorAlert('An error occurred. Could not update password');
  }
}

export function* deactivateUserSaga({ payload: { discourseId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    // TODO send request to mark user as deactivated
    // const requestInfo = {
    //   headers: {
    //     Authorization: token
    //   },
    //   queryStringParameters: {
    //     query: deactivateUser(user?.attributes?.sub)
    //   }
    // };
    // const response = yield API.post('UserAPI', '/graphql?query', requestInfo);
    // globalNavigate('/nalog/deaktiviran');
    yield call(signOutUserSaga);
    // yield put(deactivateUsersProfile.success(response));
  } catch (e) {
    yield put(deactivateUsersProfile.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deactivateAccount'));
  }
}

export function* deleteUserSaga({ payload: { cognitoId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    //TODO
    // user.deleteUser((error, data) => {
    //   if (error) {
    //     throw new Error(error);
    //   }
    // });
    // const token = user.signInUserSession.idToken.jwtToken;
    // const requestInfo = {
    //   headers: {
    //     Authorization: token
    //   },
    //   queryStringParameters: {
    //     query: deleteUser(user?.attributes?.sub)
    //   }
    // };
    // const response = yield API.post('UserAPI', '/graphql?query', requestInfo);

    // globalNavigate('/nalog/obrisan');
    yield call(signOutUserSaga);
    yield put(deleteUserProfile.success());
  } catch (e: any) {
    yield put(deleteUserProfile.failure(e));
    if (i18n.t(`aws.errors.${e?.code}`)) {
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.deleteAccount'));
    }
  }
}


export function* verifyEmailChangeSaga({ payload: { email, code } }) {
  try {
    yield Auth.verifyCurrentUserAttributeSubmit('email', code);
    const user = yield Auth.currentAuthenticatedUser();

    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: updateEmailMutation(user?.attributes?.sub, email)
      }
    };
    const res = yield API.post('UserAPI', '/graphql?query', requestInfo);
    if (res?.errors) {
      throw new Error('Could not update email.');
    }
    yield put(verifyEmailChange.success({ email }));
    yield Alert.setSuccessAlert('Email successfully updated');
  } catch (e: any) {
    yield put(verifyEmailChange.failure({ email, error: e }));
    yield Alert.setErrorAlert('Could not verify email');
  }
}


function* authSaga() {
  yield all([
    takeLatest(CURRENT_AUTH_USER.GET.REQUEST, getCurrentAuthUserSaga),
    takeLatest(SIGN_IN.POST.REQUEST, signInUserSaga),
    takeLatest(SIGN_OUT.POST.REQUEST, signOutUserSaga),
    takeLatest(SIGN_UP.POST.REQUEST, signUpUserSaga),
    takeLatest(CONFIRM_SIGN_UP.POST.REQUEST, confirmSignupSaga),
    takeLatest(CHANGE_COGNITO_PASSWORD.POST.REQUEST, changePasswordSaga),
    takeLatest(FORGOT_PASSWORD.POST.REQUEST, forgotPasswordSaga),
    takeLatest(FORGOT_PASSWORD_CONFIRMATION.POST.REQUEST, forgotPasswordConfirmationSaga),
    takeLatest(RESEND_CONFIRMATION_CODE.POST.REQUEST, resendConfirmationCodeSaga),
    takeLatest(COGNITO_USER.POST.REQUEST, updateCognitoUserSaga),
    takeLatest(COGNITO_USER_EMAIL_VERIFICATION.POST.REQUEST, verifyCognitoUserAttributSaga),
    takeLatest(DEACTIVATE_USERS_PROFILE.POST.REQUEST, deactivateUserSaga),
    takeLatest(DELETE_USER_PROFILE.DELETE.REQUEST, deleteUserSaga),
    takeLatest(COGNITO_USER_EMAIL_CHANGE_VERIFICATION.POST.REQUEST, verifyEmailChangeSaga),
  ]);
}

export default authSaga;
