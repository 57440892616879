import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useStyles } from './styles/SignUp.styles';
import Input from 'components/Shared/Input/Input';
import LoadingButton from '@mui/lab/LoadingButton';
import { Divider, Grid, Icon, IconButton } from '@mui/material';
import { FaAt, FaChevronRight, FaEye, FaEyeSlash, FaLock, FaUser } from 'react-icons/fa';
import * as yup from 'yup';
import { isEmailValid, passwordRegex } from 'utils/Helpers';
import { useTranslation } from 'react-i18next';
import ForgotPassword from 'components/FotgotPassword/ForgotPassword';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';

interface Props {
  onSignUp: (email: string, password: string) => void;
  onForgotPassword: (email: string) => void;
  navigateToSignin: () => void;
  isLoading: boolean;
  isMobile: boolean;
  error: boolean;
}

const loginSchema = yup.object({
  email: yup.string().email('Enter a valid email address').required('Enter a valid email address'),
  password: yup.string().matches(passwordRegex).required('Enter a valid password')
});


const passwordSchema = yup.object({
  password: yup.string().matches(passwordRegex).required('Enter a valid password')
});


const SignUp: React.FC<Props> = ({ onSignUp, onForgotPassword, navigateToSignin, isMobile, isLoading, error }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [signUpData, setSignUpData] = useState({
    email: '',
    password: '',
    repeatPassword: '',
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [repeatPasswordVisibility, setRepeatPasswordVisibility] = useState(false);
  const [errorInput, setErrorInput] = useState(false);

  const [errors, setErrors] = useState({
    password: true,
    repeatPassword: false,
    email: true,
  });


  const hasError = () =>
    errors?.email || errors.password || errors.repeatPassword || !signUpData.repeatPassword.length


  const validatePassword = (passwordValue, repeatPasswordValue) => {

    passwordSchema.isValid({ password: passwordValue }).then((valid) => {
      const repeatPasswordValid = repeatPasswordValue == '' || passwordValue === repeatPasswordValue;
      const passwordValid = valid;
      setErrors({ ...errors, password: !passwordValid, repeatPassword: !repeatPasswordValid });
    });
  }

  const handleInputChange = ({ target: { name, value } }) => {
    setSignUpData({ ...signUpData, [name]: value });

    if (name == 'password' || name == 'repeatPassword') {
      const passwordValue = name === 'password' ? value : signUpData.password;
      const repeatPasswordValue = name === 'repeatPassword' ? value : signUpData.repeatPassword;
      validatePassword(passwordValue, repeatPasswordValue);
    }
    else if (name == 'email') {
      setErrors({...errors, email: !isEmailValid(value)});
    }
    else {
      const newErrors = {...errors};
      newErrors[name] = value.trim() == "";
      setErrors(newErrors);
    }
  };

  const handleSignUp = async () => {
    const result = await loginSchema.validate(signUpData, { abortEarly: false }).catch((err) => err);
    const data = JSON.parse(JSON.stringify(result));
    if (data?.errors) {
      setErrorInput(true);
    } else {
      setErrorInput(false);
      onSignUp(data?.email, data?.password);
    }
  };


  const handlePressEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSignUp();
    }
  };

  const renderEyeIcon = (pass: string) => (
    <IconButton className={classes.inputIcon} onClick={() => setPasswordVisibility(!passwordVisibility)} size='medium'>
      {passwordVisibility ? <FaEye /> : <FaEyeSlash />}
    </IconButton>
  );

  const renderRepeatEyeIcon = (pass: string) => (
    <IconButton className={classes.inputIcon} onClick={() => setRepeatPasswordVisibility(!repeatPasswordVisibility)} size='medium'>
      {repeatPasswordVisibility ? <FaEye /> : <FaEyeSlash />}
    </IconButton>
  );

  return (
    <BoxedComponent>
      {errorInput && <div className={classes.errorMessage}>Invalid username and/or password</div>}
      <Grid className={classes.createAccountWrapper}>
        <Grid className={classes.createAccountText}>
          Create a new account
        </Grid>
      </Grid>
      <Grid className={classes.inputsContainer}>
        <Grid className={classes.textInputContainer}>
          <Input
            className={classes.input}
            name='email'
            value={signUpData.email}
            onChange={handleInputChange}
            hasError={false}
            disabled={isLoading}
            helperText='Invalid email'
            isRequired={true}
            onKeyUp={handlePressEnter}
            placeholder='Email*'
          />
          <Grid className={classes.inputSubtext}>
            We will send you a confirmation email to this address
          </Grid>
        </Grid>
        <Grid className={classes.textInputContainer}>
          <Input
            className={classes.input}
            endIcon={renderEyeIcon('password')}
            name='password'
            value={signUpData.password}
            onChange={handleInputChange}
            hasError={false}
            disabled={isLoading}
            helperText='Invalid password'
            isRequired={true}
            type={passwordVisibility ? 'text' : 'password'}
            onKeyUp={handlePressEnter}
            placeholder='Password'
          />
          <Grid className={classes.inputSubtext}>
            At least 8 characters, one uppercase letter, one number, and one special character (apart from @)
          </Grid>
        </Grid>
        <Grid className={classes.textInputContainer}>
          <Input
            className={classes.input}
            endIcon={renderRepeatEyeIcon('repeatPassword')}
            name='repeatPassword'
            value={signUpData.repeatPassword}
            onChange={handleInputChange}
            hasError={false}
            disabled={isLoading}
            helperText='Passwords do not match'
            isRequired={true}
            type={repeatPasswordVisibility ? 'text' : 'password'}
            onKeyUp={handlePressEnter}
            placeholder='Repeat password'
          />
          {errors.repeatPassword &&
          <Grid className={classes.inputSubtext}>
            Passwords do not match
          </Grid>
          }
        </Grid>
        <Grid className={classes.button}>
          <LoadingButton
            onClick={handleSignUp}
            loading={isLoading}
            variant='contained'
            className={classes.submitButton}
            disabled={hasError()}>
            SIGN UP
          </LoadingButton>
        </Grid>

        <Grid className={classes.signInContainer}>
          <Grid style={{fontSize: isMobile ? '12px' : '' }}>
            Already registered?
          </Grid>
          <Grid className={classes.signInLink}>
            <Grid className={classes.signInText} style={{fontSize: isMobile ? '14px' : '' }}>
              Log in
            </Grid>
              <FaChevronRight className={classes.signInIcon} onClick={navigateToSignin}/>
          </Grid>
        </Grid>
      </Grid>
    </BoxedComponent>
  );
};

export default SignUp;
