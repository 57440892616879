import { produce } from 'immer';
import { IActivity, IUser, LeagueType, UserLeague } from 'core/models/Models';
import { ADD_USER_LEAGUE, REFRESH_BALANCE, REMOVE_USER_LEAGUE, RESET_USER_STATE, USER, WITHDRAW } from 'core/actions/UserActions';

export interface IUsersState {
  user: IUser;
  userFetchFailed: boolean;
}

const initialState: IUsersState = {
    user: {} as IUser,
    userFetchFailed: false
  };


const userReducer = (state = initialState, action: IActivity): IUsersState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case USER.GET.SUCCESS:
      case USER.POST.SUCCESS:
        draft.user = payload || {};
        draft.userFetchFailed = false;
        break;
      case USER.GET.FAILURE:
        draft.userFetchFailed = true;
        break;
      case WITHDRAW.POST.SUCCESS:
        draft.user = {...draft.user, walletBalance: payload }
        break;
      case REFRESH_BALANCE.POST.SUCCESS:
        draft.user = {...draft.user, walletBalance: payload }
        break;
      case RESET_USER_STATE:
        draft.user = {};
        draft.userFetchFailed = false;
        break;
      case ADD_USER_LEAGUE:
        if (payload.leagueType == LeagueType.PUBLIC) {
          let newUserLeagues: Array<UserLeague> = [];
          if (draft.user.usersLeagues) {
            newUserLeagues = [...draft.user.usersLeagues]
          }
          newUserLeagues.push({
            leagueId: payload.leagueId,
            finalRank: 0,
            prize: 0,
            competitionType: payload.competitionType,
          })
          draft.user = {
            ...state.user,
            walletBalance: (state.user?.walletBalance || 0) - payload.entryPrice,
            usersLeagues: newUserLeagues
          }
        }
        else {
          let newUsersPrivateLeagues: Array<UserLeague> = [];
          if (draft.user.usersPrivateLeagues) {
            newUsersPrivateLeagues = [...draft.user.usersPrivateLeagues]
          }
          newUsersPrivateLeagues.push({
            leagueId: payload.leagueId,
            finalRank: 0,
            prize: 0,
            competitionType: payload.competitionType,
          })
          draft.user = {
            ...state.user,
            walletBalance: (state.user?.walletBalance || 0) - payload.entryPrice,
            usersPrivateLeagues: newUsersPrivateLeagues
          }
        }
        break;
      case REMOVE_USER_LEAGUE:
        // TODO find league and get wallet balance
        if (payload.leagueType == LeagueType.PUBLIC) {
          const userLeagues = draft.user?.usersLeagues?.filter((userLeague) => userLeague.leagueId != payload.leagueId)
          draft.user = {
            ...state.user,
            walletBalance: (state.user.walletBalance || 0) + payload.entryPrice,
            usersLeagues: userLeagues
          }
        } else {
          const privateLeagues = draft.user?.usersPrivateLeagues?.filter((userLeague) => userLeague.leagueId != payload.leagueId)
          draft.user = {
            ...state.user,
            walletBalance: (state.user.walletBalance || 0) + payload.entryPrice,
            usersPrivateLeagues: privateLeagues
          }
        }
        break;
      default:
        return draft;
    }
  });

export default userReducer;
