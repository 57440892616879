import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // width: '100%',
    backgroundColor: theme_fpl.palette.common.black,
    // minHeight: '100vh',
    marginTop: '10px',
    marginRight: '30px',
    marginLeft: '30px',
    color: theme_fpl.palette.common.white,
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    height: 500,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tablesContainer: {
    display: 'flex',
    marginTop: '20px',
    flexDirection: 'column',
    gap: '20px'
  },
  tableContainer: {
    marginTop: 30,
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },

  topImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  imageContainer: {
    marginTop: '4em',
  }
}))
