import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTableRow-head": {//title columns
      background: "linear-gradient(90deg, hsla(160, 100%, 61%, 1) 0%, hsla(270, 100%, 56%, 1) 100%) !important",
    },
    "& .MuiTableCell-head": {//table cells,all
      background: "none"
    },
    "& .MuiTablePagination-toolbar span": {
      color: "white"
    },
    "& .MuiTablePagination-toolbar": {
      marginBottom: 0
    },
    "& .MuiTablePagination-displayedRows": {
      display: 'none',
    },
    "& .MuiTableCell-footer": {
      border: 'none',
    },
    "& .MuiTypography-root": {
      // color: theme_fpl.palette.common.white,
      fontSize: 14
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "10px 4px 10px 4px" // <-- arbitrary value
    },
    overflowX: 'auto'
  },
  containerMobile: {
    flex: 1,
    width: '300px',
    overflow: 'auto', //
  },
  containerDesktop: {

  },
  toolbarWrapper: {
    '& .MuiToolbar-root': {
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      minHeight: 0,
    },
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  tableHeader: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  prizeColumn: {
    backgroundColor: theme_fpl.palette.primary.main,
    color: theme_fpl.palette.common.white,
    fontWeight: 600,
  },
  headerInnerContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    maxHeight: 90,
    background: "linear-gradient(90deg, hsla(160, 100%, 61%, 1) 0%, hsla(270, 100%, 56%, 1) 100%) !important",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  titleText: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10,
    fontWeight: 700,
    fontSize: 24,
    color: theme_fpl.palette.primary.main,
    alignSelf: 'center',

  },
  imageContainer: {
    marginLeft: 'auto',
    marginRight: 17,
    height: '100%',
  },
  tableCell: {

  },
}))

