import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme_fpl.palette.grey[600],
    paddingTop: theme_fpl.spacing(1.5),
    paddingBottom: theme_fpl.spacing(1.5),
    paddingRight: theme_fpl.spacing(3),
    paddingLeft: theme_fpl.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopRightRadius: '0px',
    borderTopLeftRadius: theme_fpl.spacing(2),
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: theme_fpl.spacing(2),
    marginTop: theme_fpl.spacing(4),
    boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.5)',
  },
  numberContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 0',
    borderBottom: `1px solid ${theme_fpl.palette.common.black}`,
    '&:first-child': {
      borderTop: `1px solid ${theme_fpl.palette.common.black}`,
    },
  },
  number: {
    marginLeft: theme_fpl.spacing(2),
    fontSize: '16px',
    fontWeight: 600,
  },
  button: {
    width: theme_fpl.spacing(2),
    height: theme_fpl.spacing(2),
    borderRadius: '50%',
    backgroundColor: theme_fpl.palette.secondary.main,
    color: theme_fpl.palette.common.black,
    display: 'flex',
    marginLeft: theme_fpl.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttonLabel: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  panel: {
    backgroundColor: theme_fpl.palette.grey[600],
    padding: theme_fpl.spacing(2),
    paddingTop: theme_fpl.spacing(3),
    borderRadius: theme_fpl.spacing(1),
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    marginTop: -15,
  },
  showPanel: {
    display: 'block',
  },
}));

export default useStyles;
