import { CompetitionType, ILeague, LeagueType, UserLeague } from 'core/models/Models';
import { DELETE, GET, POST, PUT } from 'utils/Constants';
import { action, createRequestTypes } from 'utils/ReduxHelpers';



export interface JoinLeagueInput {
  leagueType: LeagueType,
  competitionType: CompetitionType,
  leagueId?: string,
  entryCode?: string
}



export const LEAGUE = createRequestTypes('LEAGUE', [POST, PUT, DELETE]);

export const H2H = createRequestTypes('H2H', [POST, PUT, DELETE]);

export const PUBLIC_LEAGUES = createRequestTypes('PUBLIC_LEAGUES', [GET]);

export const GAMEWEEK = createRequestTypes('GAMEWEEK', [GET]);

export const JOIN_LEAGUE = createRequestTypes('JOIN_LEAGUE', [POST]);

export const JOIN_PRIVATE_LEAGUE = createRequestTypes('JOIN_PRIVATE_LEAGUE', [POST]);

export const LEAVE_LEAGUE = createRequestTypes('LEAVE_LEAGUE', [POST]);

export const USERS_PRIVATE_LEAGUES = createRequestTypes('USERS_PRIVATE_LEAGUES', [GET]);

export const H2H_DETAILS = createRequestTypes('H2H_DETAILS', [GET]);

export const USERS_LEAGUES = createRequestTypes('USERS_LEAGUES', [GET]);


export const createLeague = {
  request: (league: ILeague) => action(LEAGUE.POST.REQUEST, { league }),
  success: (payload: any) => action(LEAGUE.POST.SUCCESS, payload),
  failure: (error: any) => action(LEAGUE.POST.FAILURE, error)
};


export const getNextGameWeek = {
  request: () => action(GAMEWEEK.GET.REQUEST),
  success: (payload: any) => action(GAMEWEEK.GET.SUCCESS, payload),
  failure: (error: any) => action(GAMEWEEK.GET.FAILURE, error)
};


export const getPublicLeagues = {
  request: () => action(PUBLIC_LEAGUES.GET.REQUEST),
  success: (payload: any) => action(PUBLIC_LEAGUES.GET.SUCCESS, payload),
  failure: (error: any) => action(PUBLIC_LEAGUES.GET.FAILURE, error)
};


export const getUsersLeagues = {
  request: () => action(USERS_LEAGUES.GET.REQUEST),
  success: (payload: any) => action(USERS_LEAGUES.GET.SUCCESS, payload),
  failure: (error: any) => action(USERS_LEAGUES.GET.FAILURE, error)
};


export const joinLeague = {
  request: (input: JoinLeagueInput) => action(JOIN_LEAGUE.POST.REQUEST, { input }),
  success: (payload: any) => action(JOIN_LEAGUE.POST.SUCCESS, payload),
  failure: (error: any) => action(JOIN_LEAGUE.POST.FAILURE, error)
};


export const leaveLeague = {
  request: (leagueId, leagueType, competitionType) => action(LEAVE_LEAGUE.POST.REQUEST, { leagueId, leagueType, competitionType }),
  success: (payload: any) => action(LEAVE_LEAGUE.POST.SUCCESS, payload),
  failure: (error: any) => action(LEAVE_LEAGUE.POST.FAILURE, error)
};


export const getH2HDetails = {
  request: (leagueId, leagueType) => action(H2H_DETAILS.GET.REQUEST, { leagueId, leagueType }),
  success: (payload: any) => action(H2H_DETAILS.GET.SUCCESS, payload),
  failure: (error: any) => action(H2H_DETAILS.GET.FAILURE, error)
};

