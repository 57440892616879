import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  innerContainer: {
    marginTop: 20,
    marginBottom: 40,
  },
  text: {
    marginLeft: 40,
    fontSize: '22px',
    fontWeight: 700,
    color: theme_fpl.palette.common.white,
    textTransform: 'uppercase',
  },
  inputContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  input: {

  },
  labelIconContainer: {
    alignContent: 'center',
    marginTop: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {
    alignContent: 'center',
    marginTop: 30,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    height: 45,
    backgroundColor: theme_fpl.palette.primary.main,
    paddingLeft: 50,
    paddingRight: 50,
    color: theme_fpl.palette.common.white,
    fontFamily: theme_fpl.typography.fontFamily,
  }
}));
