import { IUser } from 'core/models/Models';
import { DELETE, GET, POST, PUT } from 'utils/Constants';
import { action, createRequestTypes } from 'utils/ReduxHelpers';


export const USER = createRequestTypes('USER', [GET, POST, PUT, DELETE]);
export const REFRESH_BALANCE = createRequestTypes('REFRESH_BALANCE', [POST]);
export const WITHDRAW = createRequestTypes('WITHDRAW', [POST]);
export const RESET_USER_STATE = 'RESET_USER_STATE';
export const ADD_USER_LEAGUE = 'ADD_USER_LEAGUE';
export const REMOVE_USER_LEAGUE = 'REMOVE_USER_LEAGUE';


export const resetUserState = () => action(RESET_USER_STATE);
export const addUserLeague = (payload) => action(ADD_USER_LEAGUE, payload);
export const removeUserLeague = (payload) => action(REMOVE_USER_LEAGUE, payload);

export const getUser = {
  request: () => action(USER.GET.REQUEST, {}),
  success: (payload: IUser) => action(USER.GET.SUCCESS, payload),
  failure: (error: any) => action(USER.GET.FAILURE, error)
};

export const createUser = {
  request: (teamId: string) => action(USER.POST.REQUEST, { teamId }),
  success: (payload: any) => action(USER.POST.SUCCESS, payload),
  failure: (error: any) => action(USER.POST.FAILURE, error)
};

export const updateUser = {
  request: (teamID?: string, email?: string) => action(USER.PUT.REQUEST, {teamID, email}),
  success: (payload: any) => action(USER.PUT.SUCCESS, payload),
  failure: (error: any) => action(USER.PUT.FAILURE, error)
};

export const withdraw = {
  request: (withdrawAmount: string, withdrawAddress) => action(WITHDRAW.POST.REQUEST, {withdrawAmount, withdrawAddress}),
  success: (payload: any) => action(WITHDRAW.POST.SUCCESS, payload),
  failure: (error: any) => action(WITHDRAW.POST.FAILURE, error)
};


export const deleteUser = {
  request: (cognitoId: string) => action(USER.DELETE.REQUEST, {cognitoId}),
  success: (payload: any) => action(USER.DELETE.SUCCESS, payload),
  failure: (error: any) => action(USER.DELETE.FAILURE, error)
};

export const refreshWalletBalance = {
  request: () => action(REFRESH_BALANCE.POST.REQUEST),
  success: (payload: any) => action(REFRESH_BALANCE.POST.SUCCESS, payload),
  failure: (error: any) => action(REFRESH_BALANCE.POST.FAILURE, error)
};
