import React, { ReactNode } from 'react';
import { Grid, Button } from '@mui/material';
import useStyles from './BoxedComponent.styles';


interface IProps {
  children: ReactNode;
  paddingRight?: string;
  paddingLeft?: string;
  paddingTop?: string;
  paddingBottom?: string;
  height?: string;
}

const BoxedComponent = ({children, paddingRight, paddingLeft, paddingTop, paddingBottom, height} :IProps) => {
  const classes = useStyles();

  // TODO adjustable padding
  return (
    <Grid className={classes.root} style={{
      paddingRight: paddingRight || '3em',
      paddingLeft: paddingLeft || '3em',
      paddingTop: paddingTop || '2em',
      paddingBottom: paddingBottom || '2em',
      height: height || '',
    }}>
      {children}
    </Grid>
  );
};

export default BoxedComponent;
