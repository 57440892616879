import { ILeague } from "core/models/Models"

export const createUserMutation = (cognitoId, email, teamId) =>
  `mutation {
    createUser(
      userId: "${cognitoId}"
      email: "${email}"
      teamId: "${teamId}"
    ) {
      cognitoId: userId
      teamId
      teamName
      playerName
      walletAddress
      walletBalance
    }
  }`

  export const updateEmailMutation = (cognitoId, email) =>
  `mutation {
    updateUser(
      userId: "${cognitoId}"
      email: "${email}"
    ) {
      isRequestSuccessful
    }
  }`

  export const refreshBalanceMutation = (cognitoId) =>
  `mutation {
    refreshBalance(
      userId: "${cognitoId}"
    ) {
      address
      balance
    }
  }`

  export const withdrawMutation = (cognitoId, withdrawAmount, withdrawAddress) =>
  `mutation {
    withdraw(
      userId: "${cognitoId}"
      withdrawAmount: ${withdrawAmount}
      withdrawAddress: "${withdrawAddress}"
    ) {
      walletBalance
    }
  }`


export const createLeagueMutation = (league: ILeague) =>
  `mutation {
    createLeague(input: {
      leagueName: "${league.leagueName}"
      entryPrice: ${league.entryPrice}
      winnersNumber: ${league.winnersNumber}
      startGameWeek: ${league.startGameWeek}
      endGameWeek: ${league.endGameWeek}
      prizeDistribution: ${JSON.stringify(league.prizeDistribution)}
      leagueType: "${league.leagueType}"
      createdBy: "${league.createdBy}"
      competitionType: "${league.competitionType}"
      maxPlayers: ${league.maxPlayers}
    }){
      league {
        leagueId
        leagueName
        entryPrice
        fund
        playersNumber
        maxPlayers
        winnersNumber
        prizeDistribution
        createdBy
        startGameWeek
        endGameWeek
      }
      isRequestSuccessful
      error
    }
  }
`;



export const joinLeagueMutation = (leagueId, leagueType, competitionType, cognitoId, entryCode) => {
  const entryCodeStr = entryCode ? `entryCode: "${entryCode}"` : '';
  const leagueIdString = leagueId ? `leagueId: "${leagueId}"` : '';

  return (
    `mutation  {
      joinLeague(
        ${leagueIdString}
        leagueType:"${leagueType}"
        competitionType: "${competitionType}"
        userId: "${cognitoId}"
        ${entryCodeStr}
      ) {
        league {
          leagueId
          leagueName
          entryPrice
          fund
          playersNumber
          maxPlayers
          winnersNumber
          prizeDistribution
          createdBy
          startGameWeek
          endGameWeek
        }
        isRequestSuccessful
        error
      }
    }
  `
  )
}


export const leaveLeagueMutation = (leagueId, leagueType, competitionType, cognitoId) =>
  `mutation  {
    leaveLeague(
      leagueId: "${leagueId}"
      leagueType:"${leagueType}"
      competitionType: "${competitionType}"
      userId: "${cognitoId}"
    ) {
      league {
        leagueId
        leagueName
        entryPrice
        fund
        playersNumber
        maxPlayers
        winnersNumber
        prizeDistribution
        createdBy
        startGameWeek
        endGameWeek
      }
      isRequestSuccessful
      error
    }
  }
  `

