/**
 *@fileoverview Provides Amplify configuration for Cognito Authorization and Zdruzene API Gateways, CloudFront API - dev
 *
 */
import { Amplify, Auth } from 'aws-amplify';

export default function Authorizer() {
  return Amplify.configure({
    // OPTIONAL - if your API requires authentication
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
    },
    API: {
      endpoints: [
        {
          name: process.env.REACT_APP_USER_API_NAME,
          endpoint: process.env.REACT_APP_USER_API_ENDPOINT,
          custom_header: async () => {
            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
          }
        },
        {
          name: process.env.REACT_APP_LEAGUE_API_NAME,
          endpoint: process.env.REACT_APP_LEAGUE_API_ENDPOINT,
          custom_header: async () => {
            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
          }
        },
        {
          name: process.env.REACT_APP_EMAIL_API_NAME,
          endpoint: process.env.REACT_APP_EMAIL_API_ENDPOINT,
          custom_header: async () => {
            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
          }
        },
        {
          name: process.env.REACT_APP_COMMONS_API_NAME,
          endpoint: process.env.REACT_APP_COMMONS_API_ENDPOINT,
          custom_header: async () => {
            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
          }
        }
      ]
    },
    Storage: {
      AWSS3: {
        bucket: process.env.REACT_APP_BUCKET,
        region: process.env.REACT_APP_REGION,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
      }
    }
  });
}
