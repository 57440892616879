import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  containerMobile: {
    flex: 1,
    width: '300px',
    overflow: 'auto', //
  },
  containerDesktop: {

  },
}))
