import { useStyles } from './TopComponentMobile.styles'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Nav from 'components/Nav/Nav';
import Grid from '@mui/material/Grid';
import { ReactNode } from 'react';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import { getUserSelector } from 'core/selectors/UserSelectors';
import { useSelector } from 'react-redux';


type IProps = {
  children: ReactNode;
};

export default function TopComponentMobile({children}: IProps) {
  const classes = useStyles();
  const user = useSelector(getUserSelector);


  return (
    <div className={classes.root}>
      <Grid className={classes.navigationContainer}>
        {user?.teamId && (
          <Grid className={classes.mobileNavRoot}>
            <Grid item className={classes.navigationBar}>
              <Nav isMobile={true}/>
            </Grid>
          </Grid>
      )}
      </Grid>
      {children &&
        <BoxedComponent paddingRight='0em' paddingLeft='0em' paddingTop='1.5em' paddingBottom='1.5em'>
          {children}
        </BoxedComponent>
      }
    </div>
);
}
