import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, TextField } from '@mui/material';
import useStyles from './styles/PrizesDropdown.styles';
import { FaChevronCircleUp, FaChevronCircleDown, FaPercentage } from 'react-icons/fa';
import Input from 'components/Shared/Input/Input';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import CounterComponent from './CounterComponent';
import theme_fpl from 'utils/Theme';


interface IProps {
  numberOfWinners: number;
  setNumberOfWinners: (number) => void;
  percentages: number[],
  setPercentages: (index: number, value: string) => void;
}

const PrizesModal = ({numberOfWinners, setNumberOfWinners, percentages, setPercentages}: IProps) => {
  const [open, setOpen] = useState(false);



  const classes = useStyles();

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleDropdownClose = () => {
    setOpen(false);
  };

  const handleNumberOfWinnersChange = (value) => {
    setNumberOfWinners(value);
  };

  const dialogContent = () =>
    // <BoxedComponent paddingRight={'0.5em'} paddingLeft={'0.5em'}>
      <DialogContent className={classes.dialogContainer}>
        <CounterComponent currentNumber={numberOfWinners} setCurrentNumber={setNumberOfWinners}/>
        <Grid>
          <Grid className={classes.percentagesContainer}>
            <Grid className={classes.labelContainer}>
            <Grid className={classes.percentageIcon}>
              <Icon className={classes.icon}>
                <FaPercentage />
              </Icon>
            </Grid>
              <Grid className={classes.label}>
                reward percentages
              </Grid>
            </Grid>
            <Grid className={classes.percentagesItemsContainer}>
              {Array.from({ length: numberOfWinners }, (_, index) => (
                <Grid className={classes.percentagesItem} key={index}>
                  <Grid className={classes.percentageLabel}>
                    {index + 1}.
                  </Grid>
                  <Input
                    key={index}
                    type="number"
                    backgroundColor={theme_fpl.palette.common.black}
                    value={percentages[index]}
                    onChange={(e) => setPercentages(index, e.target.value)}
                  />
                  <Grid className={classes.percentageSymbolLabel}>
                    %
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    // </BoxedComponent>

  const dropdownInput = (
    <Input
      name='email'
      readOnly={true}
      value={numberOfWinners}
      endIcon={<Icon fontSize={'small'} className={classes.inputIcon}>
        {open ? <FaChevronCircleUp/> : <FaChevronCircleDown/>}
      </Icon>}
      hasError={false}
      isRequired={true}
      onClick={handleToggle}
    />
  );

  return (
    <Grid className={classes.dropDownContainer}>
      {dropdownInput}
      <Dialog open={open} onClose={handleDropdownClose}>
        <DialogTitle className={classes.dialogTitle}>Number of winners</DialogTitle>
        {dialogContent()}
        {/* <DialogActions>
          <Button onClick={handleDropdownClose} color="primary">
            Confirm
          </Button>
        </DialogActions> */}
      </Dialog>
    </Grid>
  );
};

export default PrizesModal;
