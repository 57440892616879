import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import { FaExclamationCircle, FaTimes, FaPlus } from "react-icons/fa";
import useStyles from "./MessageContainer.style";


interface IProps {
  message: string
}

const MessageContainer   = ({message}: IProps) => {
    const classes = useStyles();
    const [isVisible, setIsVisible] = useState(true);
  
    const toggleVisibility = () => {
      setIsVisible((prevVisible) => !prevVisible);
    };
  
    return (
      <>
      {isVisible ? (
        <Box className={classes.container}>
          <Box className={classes.exclamationIcon}>
            <IconButton className={classes.closeButton} onClick={toggleVisibility}>
              <FaTimes />
            </IconButton>
          </Box>
          <Box className={classes.text}>
            {message}
          </Box>
        </Box>
      ) : (
      <Box>
        <IconButton className={classes.exclamationIcon} onClick={toggleVisibility}>
          <FaExclamationCircle size={24} />
        </IconButton>
      </Box>
      )}
    </>
    )
  };
  
  export default MessageContainer ;