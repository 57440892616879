import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useStyles } from './ForgottenPassword.styles';
import Input from 'components/Shared/Input/Input';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, Icon, IconButton } from '@mui/material';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import * as yup from 'yup';
import { isEmailValid, passwordRegex } from 'utils/Helpers';
import { useTranslation } from 'react-i18next';
import ForgotPassword from 'components/FotgotPassword/ForgotPassword';

interface Props {
  onConfirmForgotPassword: (verificationCode: string, email: string, password: string) => void;
  isMobile: boolean;
  isLoading: boolean;
  error: boolean;
}

const loginSchema = yup.object({
  email: yup.string().email('Enter a valid email address').required('Enter a valid email address'),
  password: yup.string().matches(passwordRegex).required('Enter a valid password')
});


const passwordSchema = yup.object({
  password: yup.string().matches(passwordRegex).required('Enter a valid password')
});


const ForgottenPassword: React.FC<Props> = ({ onConfirmForgotPassword, isMobile, isLoading, error }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [forgottenPasswordData, setForgottenPasswordData] = useState({
    verificationCode: '',
    email: '',
    password: '',
    repeatPassword: '',
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [repeatPasswordVisibility, setRepeatPasswordVisibility] = useState(false);
  const [errorInput, setErrorInput] = useState(false);

  const [errors, setErrors] = useState({
    password: true,
    repeatPassword: false,
    verificationCode: true,
    email: true,
  });


  const hasError = () =>
    errors?.verificationCode || errors?.email || errors.password || errors.repeatPassword || !forgottenPasswordData.repeatPassword.length



  const validatePassword = (passwordValue, repeatPasswordValue) => {

    passwordSchema.isValid({ password: passwordValue }).then((valid) => {
      const repeatPasswordValid = repeatPasswordValue == '' || passwordValue === repeatPasswordValue;
      const passwordValid = valid;
      setErrors({ ...errors, password: !passwordValid, repeatPassword: !repeatPasswordValid });
    });
  }

  const handleInputChange = ({ target: { name, value } }) => {
    setForgottenPasswordData({ ...forgottenPasswordData, [name]: value });

    if (name == 'password' || name == 'repeatPassword') {
      const passwordValue = name === 'password' ? value : forgottenPasswordData.password;
      const repeatPasswordValue = name === 'repeatPassword' ? value : forgottenPasswordData.repeatPassword;
      validatePassword(passwordValue, repeatPasswordValue);
    }
    else if (name == 'email') {
      setErrors({...errors, email: !isEmailValid(value)});
    }
    else {
      const newErrors = {...errors};
      newErrors[name] = value.trim() == "";
      setErrors(newErrors);
    }
  };

  const handleConfirmForgottenPassword = async () => {
    const result = await loginSchema.validate(forgottenPasswordData, { abortEarly: false }).catch((err) => err);
    const data = JSON.parse(JSON.stringify(result));
    if (data?.errors) {
      setErrorInput(true);
    } else {
      setErrorInput(false);
      onConfirmForgotPassword(data?.verificationCode, data?.email, data?.password);
    }
  };


  const handlePressEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleConfirmForgottenPassword();
    }
  };

  const renderEyeIcon = (pass: string) => (
    <IconButton className={classes.inputIcon} onClick={() => setPasswordVisibility(!passwordVisibility)} size='medium'>
      {passwordVisibility ? <FaEye /> : <FaEyeSlash />}
    </IconButton>
  );

  const renderRepeatEyeIcon = (pass: string) => (
    <IconButton className={classes.inputIcon} onClick={() => setRepeatPasswordVisibility(!repeatPasswordVisibility)} size='medium'>
      {repeatPasswordVisibility ? <FaEye /> : <FaEyeSlash />}
    </IconButton>
  );

  return (
    <div className={classes.root}>
      {errorInput && <div className={classes.errorMessage}>Invalid username and/or password</div>}
      <Grid className={classes.createAccountWrapper}>
        <Grid className={classes.createAccountText}>
          Reset password
        </Grid>
      </Grid>
      <Grid className={classes.inputsContainer}>
        <Grid className={classes.textInputContainer}>
          <Input
            className={classes.input}
            name='verificationCode'
            value={forgottenPasswordData.verificationCode}
            onChange={handleInputChange}
            hasError={false}
            disabled={isLoading}
            isRequired={true}
            onKeyUp={handlePressEnter}
            placeholder='Verification code'
          />
        </Grid>
        <Grid className={classes.textInputContainer}>
          <Input
            className={classes.input}
            name='email'
            value={forgottenPasswordData.email}
            onChange={handleInputChange}
            hasError={false}
            disabled={isLoading}
            helperText='Invalid email'
            isRequired={true}
            onKeyUp={handlePressEnter}
            placeholder='Email'
          />
        </Grid>
        <Grid className={classes.textInputContainer}>
          <Input
            className={classes.input}
            endIcon={renderEyeIcon('password')}
            name='password'
            value={forgottenPasswordData.password}
            onChange={handleInputChange}
            hasError={false}
            disabled={isLoading}
            helperText='Invalid password'
            isRequired={true}
            type={passwordVisibility ? 'text' : 'password'}
            onKeyUp={handlePressEnter}
            placeholder='Password'
          />
          <Grid className={classes.inputSubtext}>
            At least 8 characters
          </Grid>
        </Grid>
        <Grid className={classes.textInputContainer}>
          <Input
            className={classes.input}
            endIcon={renderRepeatEyeIcon('repeatPassword')}
            name='repeatPassword'
            value={forgottenPasswordData.repeatPassword}
            onChange={handleInputChange}
            hasError={false}
            disabled={isLoading}
            helperText='Passwords do not match'
            isRequired={true}
            type={repeatPasswordVisibility ? 'text' : 'password'}
            onKeyUp={handlePressEnter}
            placeholder='Repeat password'
          />
          {errors.repeatPassword &&
          <Grid className={classes.inputSubtext}>
            Passwords do not match
          </Grid>
          }
        </Grid>
        <Grid className={classes.button}>
          <LoadingButton
            color='secondary'
            onClick={handleConfirmForgottenPassword}
            loading={isLoading}
            variant='contained'
            style={{borderRadius: 20, height: 45}}
            disabled={hasError()}>
            Confirm
          </LoadingButton>
        </Grid>

      </Grid>

    </div>
  );
};

export default ForgottenPassword;
