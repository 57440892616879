import { GET, POST, DELETE } from 'utils/Constants';
import { action, createRequestTypes } from 'utils/ReduxHelpers';

import { ISignupForm, IUser } from 'core/models/Models';

interface FetchUserSuccessPayload {
  user: IUser;
}

interface UpdateCognitoUserDataRequestPayload {
  email?: string;
  name?: string;
  family_name?: string;
  given_name?: string;
}

interface FetchUserFailurePayload {
  error: string;
}
export const CURRENT_AUTH_USER = createRequestTypes('CURRENT_AUTH_USER', [GET]);
export const COGNITO_USER = createRequestTypes('COGNITO_USER', [GET, POST]);
export const COGNITO_USER_EMAIL_VERIFICATION = createRequestTypes('COGNITO_USER_EMAIL_VERIFICATION', [POST]);
export const COGNITO_USER_EMAIL_CHANGE_VERIFICATION = createRequestTypes('COGNITO_USER_EMAIL_CHANGE_VERIFICATION', [POST]);
export const SIGN_IN = createRequestTypes('SIGN_IN', [POST]);
export const SIGN_UP = createRequestTypes('SIGN_UP', [GET, POST]);
export const SIGN_OUT = createRequestTypes('SIGN_OUT', [POST]);
export const CONFIRM_SIGN_UP = createRequestTypes('CONFIRM_SIGN_UP', [POST]);
export const RESEND_CONFIRMATION_CODE = createRequestTypes('RESEND_CONFIRMATION_CODE', [POST]);
export const CHANGE_COGNITO_PASSWORD = createRequestTypes('CHANGE_COGNITO_PASSWORD', [POST]);
export const FORGOT_PASSWORD = createRequestTypes('FORGOT_PASSWORD', [POST]);
export const FORGOT_PASSWORD_CONFIRMATION = createRequestTypes('FORGOT_PASSWORD_CONFIRMATION', [POST]);
export const DEACTIVATE_USERS_PROFILE = createRequestTypes('DEACTIVATE_USERS_PROFILE', [POST]);
export const DELETE_USER_PROFILE = createRequestTypes('DELETE_USER_PROFILE', [DELETE]);
export const UPDATE_SIGNUP_DATA = 'UPDATE_SIGNUP_DATA';

export const updateSignupData = (data: ISignupForm) => action(UPDATE_SIGNUP_DATA, data);

export const getCurrentAuthUser = {
  request: () => action(CURRENT_AUTH_USER.GET.REQUEST),
  success: (payload: FetchUserSuccessPayload) => action(CURRENT_AUTH_USER.GET.SUCCESS, payload),
  failure: (error: any) => action(CURRENT_AUTH_USER.GET.FAILURE, error)
};

export const getCognitoUser = {
  request: () => action(COGNITO_USER.GET.REQUEST),
  success: (payload: FetchUserSuccessPayload) => action(COGNITO_USER.GET.SUCCESS, payload),
  failure: (error: FetchUserFailurePayload) => action(COGNITO_USER.GET.FAILURE, error)
};

export const updateCognitoUser = {
  request: (data: UpdateCognitoUserDataRequestPayload) => action(COGNITO_USER.POST.REQUEST, data),
  success: (payload: any) => action(COGNITO_USER.POST.SUCCESS, payload),
  failure: (error: any) => action(COGNITO_USER.POST.FAILURE, error)
};

export const verifyUserEmail = {
  request: (email: string, code: string) =>
    action(COGNITO_USER_EMAIL_VERIFICATION.POST.REQUEST, { email, code }),
  success: (payload: any) => action(COGNITO_USER_EMAIL_VERIFICATION.POST.SUCCESS, payload),
  failure: (error: any) => action(COGNITO_USER_EMAIL_VERIFICATION.POST.FAILURE, error)
};

export const verifyEmailChange = {
  request: (email: string, code: string) =>
    action(COGNITO_USER_EMAIL_CHANGE_VERIFICATION.POST.REQUEST, { email, code }),
  success: (payload: any) => action(COGNITO_USER_EMAIL_CHANGE_VERIFICATION.POST.SUCCESS, payload),
  failure: (error: any) => action(COGNITO_USER_EMAIL_CHANGE_VERIFICATION.POST.FAILURE, error)
};

export const signInUser = {
  request: (email: string, password: string) =>
    action(SIGN_IN.POST.REQUEST, { email, password}),
  success: (payload: any) => action(SIGN_IN.POST.SUCCESS, payload),
  failure: (error: any) => action(SIGN_IN.POST.FAILURE, error)
};

export const signUpUser = {
  request: (email: string, password: string) => action(SIGN_UP.POST.REQUEST, {email, password}),
  success: (payload: any) => action(SIGN_UP.POST.SUCCESS, payload),
  failure: (error: any) => action(SIGN_UP.POST.FAILURE, error)
};

export const signOutUser = {
  request: () => action(SIGN_OUT.POST.REQUEST),
  success: (payload: any) => action(SIGN_OUT.POST.SUCCESS, payload),
  failure: (error: any) => action(SIGN_OUT.POST.FAILURE, error)
};

export const confirmSignup = {
  request: (signupForm: ISignupForm) =>
    action(CONFIRM_SIGN_UP.POST.REQUEST, {signupForm}),
  success: (payload: any) => action(CONFIRM_SIGN_UP.POST.SUCCESS, payload),
  failure: (error: any) => action(CONFIRM_SIGN_UP.POST.FAILURE, error)
};

export const resendConfirmationCode = {
  request: (email: string) => action(RESEND_CONFIRMATION_CODE.POST.REQUEST, { email }),
  success: (payload: any) => action(RESEND_CONFIRMATION_CODE.POST.SUCCESS, payload),
  failure: (error: any) => action(RESEND_CONFIRMATION_CODE.POST.FAILURE, error)
};

export const forgotPassword = {
  request: (email: string) => action(FORGOT_PASSWORD.POST.REQUEST, { email }),
  success: (payload: any) => action(FORGOT_PASSWORD.POST.SUCCESS, payload),
  failure: (error: any) => action(FORGOT_PASSWORD.POST.FAILURE, error)
};

export const forgotPasswordConfirmation = {
  request: (verificationCode: string, email: string, password: string) =>
    action(FORGOT_PASSWORD_CONFIRMATION.POST.REQUEST, { verificationCode, email, password }),
  success: (payload: any) => action(FORGOT_PASSWORD_CONFIRMATION.POST.SUCCESS, payload),
  failure: (error: any) => action(FORGOT_PASSWORD_CONFIRMATION.POST.FAILURE, error)
};

export const changeCognitoPassword = {
  request: (oldPassword: string, newPassword: string) =>
    action(CHANGE_COGNITO_PASSWORD.POST.REQUEST, { oldPassword, newPassword }),
  success: (payload: any) => action(CHANGE_COGNITO_PASSWORD.POST.SUCCESS, payload),
  failure: (error: any) => action(CHANGE_COGNITO_PASSWORD.POST.FAILURE, error)
};

export const deactivateUsersProfile = {
  request: (discourseId: string) => action(DEACTIVATE_USERS_PROFILE.POST.REQUEST, { discourseId }),
  success: (payload: any) => action(DEACTIVATE_USERS_PROFILE.POST.SUCCESS, payload),
  failure: (error: any) => action(DEACTIVATE_USERS_PROFILE.POST.FAILURE, error)
};

export const deleteUserProfile = {
  request: (discourseId: string) => action(DELETE_USER_PROFILE.DELETE.REQUEST, { discourseId }),
  success: () => action(DELETE_USER_PROFILE.DELETE.SUCCESS),
  failure: (error: any) => action(DELETE_USER_PROFILE.DELETE.FAILURE, error)
};
