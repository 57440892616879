import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid } from '@mui/material';
import * as yup from 'yup';
import { isEmailValid, passwordRegex } from 'utils/Helpers';
import { useTranslation } from 'react-i18next';
import theme_fpl from 'utils/Theme';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import { LoadingButton } from '@mui/lab';
import { FaAngleDown } from 'react-icons/fa';
import Input from 'components/Shared/Input/Input';
import { useStyles } from './AccountSettings.styles';
import PasswordSettings from './PasswordSettings';
import EmailSettings from './EmailSettings';
import { CHANGE_COGNITO_PASSWORD, changeCognitoPassword, updateCognitoUser, verifyEmailChange, verifyUserEmail } from 'core/actions/AuthActions';
import { getLoadingStatusSelector } from 'core/selectors/LoadingSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { getCognitoAttibutesVerificationSelector, getCognitoUserSelector } from 'core/selectors/AuthSelectors';

interface Props {
  isMobile: boolean;
}


const AccountSettings: React.FC<Props> = ({ isMobile }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const cognitoUser = useSelector(getCognitoUserSelector);
  const cognitoAttributesVerification = useSelector(getCognitoAttibutesVerificationSelector);
  const isLoadingPassword = useSelector(getLoadingStatusSelector(CHANGE_COGNITO_PASSWORD.POST.REQUEST));
  const isLoadingEmail = useSelector(getLoadingStatusSelector(CHANGE_COGNITO_PASSWORD.POST.REQUEST));
  const [previousEmail, setPreviousEmail] = useState('');


  const onUpdatePassword = (oldPassword, newPassword) => {
    dispatch(changeCognitoPassword.request(oldPassword, newPassword));
  }

  const handleUpdateCognitoData = (data) => {
    setPreviousEmail(cognitoUser?.attributes?.email);
    dispatch(updateCognitoUser.request(data));
  };

  const handleCodeSubmit = (email, code) => {
    dispatch(verifyEmailChange.request(email, code));
  };


  return (
    <BoxedComponent>
      <PasswordSettings
        isMobile={false}
        isLoadingPassword={isLoadingPassword}
        onUpdatePassword={onUpdatePassword}
        isInitiallyExpanded={!isMobile}
      />
      <EmailSettings
        isMobile={false}
        isLoadingEmail={false}
        isCodeSent={cognitoAttributesVerification.confirmationCodeSent}
        onUpdateEmail={handleUpdateCognitoData}
        onCodeSubmit={handleCodeSubmit}
        previousEmail={cognitoUser?.attributes?.email}
        isInitiallyExpanded={!isMobile}/>
    </BoxedComponent>
  );
}

export default AccountSettings;
