import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useStyles } from './Login.styles';
import Input from 'components/Shared/Input/Input';
import LoadingButton from '@mui/lab/LoadingButton';
import { Divider, Grid, Icon, IconButton } from '@mui/material';
import { FaAt, FaChevronRight, FaEye, FaEyeSlash, FaLock, FaUser } from 'react-icons/fa';
import * as yup from 'yup';
import { isEmailValid, passwordRegex } from 'utils/Helpers';
import { useTranslation } from 'react-i18next';
import ForgotPassword from 'components/FotgotPassword/ForgotPassword';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';

interface Props {
  onSignIn: (email: string, password: string) => void;
  onForgotPassword: (email: string) => void;
  navigateToSignup: () => void;
  redirectFromConfirmSignup: boolean;
  isLoading: boolean;
  isMobile: boolean;
  error: boolean;
}


const Login: React.FC<Props> = ({ onSignIn, onForgotPassword, navigateToSignup, redirectFromConfirmSignup, isLoading, isMobile, error }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [errorInput, setErrorInput] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isOpenForgotPasswordModal, setForgotPasswordModal] = useState(false);

  useEffect(() => {
    if (loginData.email && isEmailValid(loginData?.email) && loginData.password) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [loginData.email, loginData.password]);

  const handleInputChange = ({ target: { name, value } }) => {
    setLoginData({ ...loginData, [name]: value });

    if (errorInput) {
      setErrorInput(false);
    }
  };

  const handleSignIn = async () => {
    onSignIn(loginData?.email, loginData?.password);
  };

  const handleForgotPassword = () => setForgotPasswordModal(true);

  const handleSubmitRequestForgotPassword = (email: string) => {
    onForgotPassword(email);
    setForgotPasswordModal(false);
  }

  const handlePressEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSignIn();
    }
  };

  const renderEyeIcon = (pass: string) => (
    <IconButton className={classes.inputIcon} onClick={() => setPasswordVisibility(!passwordVisibility)} size='medium'>
      {passwordVisibility[pass] ? <FaEye /> : <FaEyeSlash />}
    </IconButton>
  );

  return (
    <BoxedComponent>
      {redirectFromConfirmSignup &&
        <p>Your email address has been successfully verified, and you're now ready to log in!</p>
      }
      <Grid className={classes.textInputContainer}>
        <Input
          className={classes.input}
          name='email'
          value={loginData.email}
          startIcon={<Icon fontSize={'small'} className={classes.inputIcon}><FaAt/></Icon>}
          onChange={handleInputChange}
          hasError={false}
          disabled={isLoading}
          helperText='Invalid email'
          isRequired={true}
          onKeyUp={handlePressEnter}
          placeholder='Email'
        />
      </Grid>
      <Grid className={classes.textInputContainer}>
        <Input
          className={classes.input}
          endIcon={renderEyeIcon('password')}
          startIcon={<Icon fontSize={'small'} className={classes.inputIcon}><FaLock/></Icon>}
          name='password'
          value={loginData.password}
          onChange={handleInputChange}
          hasError={false}
          disabled={isLoading}
          helperText='Invalid password'
          isRequired={true}
          type={passwordVisibility ? 'text' : 'password'}
          onKeyUp={handlePressEnter}
          placeholder='Password'
        />
      </Grid>
      <Grid className={classes.button}>
        <LoadingButton
          onClick={handleSignIn}
          loading={isLoading}
          variant='contained'
          className={classes.submitButton}
          disabled={isButtonDisabled}>
          Log in
        </LoadingButton>
      </Grid>
      <Grid className={classes.forgotPasswordWrapper} onClick={handleForgotPassword}>
        <Grid className={classes.forgotPasswordLink} style={{fontSize: isMobile ? '14px' : '' }}>
          Forgot password?
        </Grid>
      </Grid>

      <Grid className={classes.signupUpContainer}>
        <Grid className={classes.noAccount} style={{fontSize: isMobile ? '12px' : '' }}>
          No Account Yet?
        </Grid>
        <Grid className={classes.signUpLink}>
          <Grid className={classes.signUpText} style={{fontSize: isMobile ? '14px' : '' }}>
            Sign up
          </Grid>
            <FaChevronRight className={classes.signUpIcon} onClick={navigateToSignup}/>
        </Grid>
      </Grid>


      {isOpenForgotPasswordModal && (
        <ForgotPassword
          open={isOpenForgotPasswordModal}
          closeRequestDialog={() => setForgotPasswordModal(false)}
          onSubmitRequestDialog={handleSubmitRequestForgotPassword}
        />
      )}
    </BoxedComponent>
  );
};

export default Login;
