import React, { useState } from 'react';
import { useStyles } from './UserAndWallet.styles';
import { Button, Grid } from '@mui/material';
import { FaCopy, FaQuestion, FaSync, FaTshirt, FaUser } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import WalletAddress from 'components/WalletAddress/WalletAddress';
import theme_fpl from 'utils/Theme';
import { IUser } from 'core/models/Models';
import { refreshWalletBalance, withdraw } from 'core/actions/UserActions';
import { useDispatch } from 'react-redux';
import CryptoHelp from './CryptoHelp';
import { animated, useTransition } from 'react-spring';
import WithdrawDialog from './WithdrawDialog';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';

interface Props {
  error: boolean;
  user: IUser;
  isMobile: boolean;
}

const UserAndWallet: React.FC<Props> = ({user, error, isMobile }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isHelpShown, setIsHelpShown] = useState(false);
  const [isWithdrawDialogOpen, setIsWithdrawDialogOpen] = useState(false);

  const handleWithdraw = () => {
    setIsWithdrawDialogOpen(true);
  }

  const toggleCryptoHelp = () => {
    setIsHelpShown((prevIsShown) => !prevIsShown);
  };


  const handleRefreshWalletBalance = () => {
    dispatch(refreshWalletBalance.request())
  }

  const onConfirmWithdraw = (address, amount) => {
    dispatch(withdraw.request(amount, address));
    setIsWithdrawDialogOpen(false);
  }

  const transitions = useTransition(isHelpShown, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 'auto' },
    leave: { opacity: 0, height: 0 },
    config: { duration: 200}
  });

  return (
    <BoxedComponent>
      <Grid className={classes.innerContainer}>

        <Grid className={classes.userNameContainer}>
          <FaUser color={theme_fpl.palette.primary.main} size={25}/>
          <Grid className={classes.usernameText}>
            {user?.playerName}
          </Grid>
        </Grid>

        <Grid className={classes.walletAmountContainer}>
          <WalletAddress isMobile={isMobile} walletAddress={user?.walletAddress}/>

          <Grid className={classes.walletAmount}>
            <span>
              {user?.walletBalance}
            </span>
            <FaSync className={classes.questionIcon} onClick={handleRefreshWalletBalance}/>
          </Grid>
        </Grid>
        <Grid className={classes.withdrawContainer}>
          <Button
            onClick={handleWithdraw}
            className={classes.withdrawButton}
            variant='contained'
            >
            withdraw
          </Button>
          <Grid className={classes.helpContainer}>
            <Grid onClick={toggleCryptoHelp}>
              Help
            </Grid>
            <FaQuestion className={classes.questionIcon} onClick={toggleCryptoHelp} />
          </Grid>

        </Grid>
        <Grid>
          {transitions((styles, item) =>
            item && (
              <animated.div style={styles}>
              <CryptoHelp/>
              </animated.div>
            )
            )}
          </Grid>
        {!isHelpShown && (
          <Grid className={classes.tapAndPlay}>
            <div>
              Top up and play now!
            </div>
            <div>
              minimum 10usdt
            </div>
          </Grid>
        )}
        <Grid className={classes.teamNameContainer}>
          <FaTshirt color={theme_fpl.palette.primary.main} size={40}/>
          <Grid className={classes.usernameText}>
            {user?.teamName}
          </Grid>
        </Grid>
      </Grid>
      <WithdrawDialog isDialogOpen={isWithdrawDialogOpen} setIsDialogOpen={setIsWithdrawDialogOpen} onConfirm={onConfirmWithdraw}/>
    </BoxedComponent>


  );
};

export default UserAndWallet;
