import { GET } from 'utils/Constants';
import { action, createRequestTypes } from 'utils/ReduxHelpers';


export const GAME_WEEKS = createRequestTypes('GAME_WEEKS', [GET]);


export const getGameWeeks = {
  request: () => action(GAME_WEEKS.GET.REQUEST),
  success: (payload: any) => action(GAME_WEEKS.GET.SUCCESS, payload),
  failure: (error: any) => action(GAME_WEEKS.GET.FAILURE, error)
}

