import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme_fpl.palette.common.black,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    paddingLeft: '3rem',
    paddingTop: '1em',
    paddingRight: '3rem',
    // minWidth: '400px',
    borderRadius: '25px',
    boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.5)',
    // marginTop: '-20px'
  },
  inputsContainer: {
    marginTop: '2.5em',
  },
  textInputContainer: {
    marginTop: '1em',
  },
  button: {
    marginTop: '4em',
    marginBottom: '3em',
    width: '100%',
    '& > button': {
      width: '100%',
      padding: 15
    }
  },
  input: {
    '& > div > div': {
      backgroundColor: theme_fpl.palette.grey[600],
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme_fpl.palette.primary.main,
    }
  },
   divider: {
      // Theme Color, or use css color in quote
      background: theme_fpl.palette.primary.main,
  },
  createAccountWrapper: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    width: '100%',
    paddingBottom: '2em',
    display: 'flex',
    borderBottomColor: theme_fpl.palette.primary.main,
    marginTop: '2em',
  },
  createAccountText: {
    fontSize: '16px',
    fontWeight: 500,
    color: theme_fpl.palette.common.white,
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontFamily: theme_fpl.palette.text.primary,
  },
  snackbar: {
    width: '100%',
    '& > * + *': {
      marginTop: 20
    }
  },
  signInLink: {
    textTransform: 'uppercase',
    color: theme_fpl.palette.secondary.main,
    display: 'flex',
    flexDiretion: 'row',
    justifyItems: 'center',
    alignItems: 'center',
  },
  progress: {
    width: '100%',
    textAlign: 'center'
  },
  errorMessage: {
    width: '100%',
    color: theme_fpl.palette.primary.main,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '16px'
  },
  inputIcon: {
    color: theme_fpl.palette.common.white,
  },
  inputSubtext: {
    color: theme_fpl.palette.grey[500],
    fontFamily: theme_fpl.palette.text.primary,
    fontSize: '12px',
    marginTop: '1.5em',
    marginBottom: '1.5em',
    paddingLeft: '1em',
    // textAlign: 'center',
  },
  settingsContainer: {
    width: '100%',
    margin: '0 auto',
    padding: '16px',
  },
  accordionSummary: {
    backgroundColor:theme_fpl.palette.common.black,
    marginBottom: '8px',
    fontSize: '16px',
  },
  accordion: {
    backgroundColor: theme_fpl.palette.common.black,
    color: theme_fpl.palette.common.white,
    border: 'none',
    boxShadow: 'none',
  },
  cancelButton: {
    borderRadius: 20,
    height: 40,
    fontSize: '14px',
    backgroundColor: 'transparent',
    border: `1px solid ${theme_fpl.palette.secondary.main}`,
    color: theme_fpl.palette.common.white,
    flex: 1,
    marginLeft: 20,
  },
  buttons: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));
