import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme_fpl.palette.common.black,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    paddingLeft: '3rem',
    paddingTop: '1em',
    paddingRight: '3rem',
    // minWidth: '400px',
    borderRadius: '25px',
    boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.5)',
    // marginTop: '-20px'
  },
  button: {
    marginTop: '4em',
    marginBottom: '3em',
    width: '100%',
    '& > button': {
      width: '100%',
      padding: 15
    }
  },
  innerContainer: {
    marginBottom: 40,
  },
  errorMessage: {
    width: '100%',
    color: theme_fpl.palette.primary.main,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '16px'
  },
  walletAddressContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  walletText: {
    fontSize: '18px',
    fontWeight: 500,
    color: theme_fpl.palette.common.white,
  },
  walletAmountContainer: {
    marginTop: 10,
    marginBottom: 5,
    borderBottom: `1px solid ${theme_fpl.palette.primary.main}`
  },
  walletAmount: {
    marginTop: 10,
    fontSize: '24px',
    fontWeight: 700,
    marginLeft: 30,
    color: theme_fpl.palette.secondary.main,
  },
  minimumWarning: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme_fpl.palette.common.white,
  },
  tapAndPlay: {
    marginTop: 40,
    marginBottom: 40,
    fontSize: '20px',
    textAlign: 'center',
  },
  enterTeam: {
    fontSize: '20px',
    textAlign: 'center',
    color: theme_fpl.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  inputContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  input: {

  },
  helpContainer: {
    marginTop: '1.5em',
    display: 'flex',
    flexDirection: 'row',
  },
  questionIcon: {
    // marginBottom: 2,
    fontSize: 20,
    padding: 5,
    color:  theme_fpl.palette.common.black,
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: theme_fpl.palette.secondary.main,
  },
  helpText: {
    marginLeft: 5,
    fontWeight: 400,
  },
  slideToggleContent: {
    height: 0,
    overflow: 'hidden',
    transition: 'all  0.3s',
  },
  slideToggleContentShown: {
    height: '100%'
  },
  logoutButton: {
    marginTop: '2em',
    borderTopLeftRadius: '25px',
    borderBottomRightRadius: '25px',
    height: 40,
    backgroundColor: 'transparent',
    border: `1px solid ${theme_fpl.palette.primary.main}`,
    color: theme_fpl.palette.common.white,
  },
  submitButton: {
    height: 40,
    borderTopLeftRadius: '25px',
    borderBottomRightRadius: '25px',
    backgroundColor: theme_fpl.palette.primary.main,
    '&:disabled': {
      backgroundColor: theme_fpl.palette.grey[600],
      color: theme_fpl.palette.grey[500],
      cursor: 'not-allowed',
      border: `1px solid ${theme_fpl.palette.primary.main}`,
    },
  }
}));
