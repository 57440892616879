import React, { useEffect, useState } from 'react';
import { useStyles } from './Standings.styles';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FaCopy, FaQuestion, FaSync, FaTshirt, FaUser } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import WalletAddress from 'components/WalletAddress/WalletAddress';
import theme_fpl from 'utils/Theme';
import { IUser } from 'core/models/Models';
import { useDispatch } from 'react-redux';
import { getStandings } from 'core/actions/NewsActions';
import { useSelector } from 'react-redux';
import { getStandingsSelector } from 'core/selectors/NewsSelector';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';


interface Props {
  isMobile: boolean;
}

const Standings: React.FC<Props> = ({isMobile }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const teams = useSelector(getStandingsSelector);

  useEffect(() => {
    if (teams.length == 0) {
      dispatch(getStandings.request(2022));
    }
  }, []);

  return (
    <BoxedComponent paddingLeft='1.5em' paddingRight='1.5em'>
      <h1 className={classes.title}>2022/23 Standings</h1>

      {!isMobile ? (
        <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>P</TableCell>
              <TableCell>W</TableCell>
              <TableCell>D</TableCell>
              <TableCell>L</TableCell>
              <TableCell>Pts</TableCell>
              <TableCell>+/-</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((team) => (
              <TableRow key={team.rank}>
                <TableCell>{team.rank}</TableCell>
                <TableCell>
                  <img src={team.logo} alt={team.name} className={classes.teamLogo} />
                </TableCell>
                <TableCell>{team.name}</TableCell>
                <TableCell>{team.all.played}</TableCell>
                <TableCell>{team.all.win}</TableCell>
                <TableCell>{team.all.draw}</TableCell>
                <TableCell>{team.all.lose}</TableCell>
                <TableCell>{team.points}</TableCell>
                <TableCell>{team.goalsDiff}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <table className="team-table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>P</th>
            <th>W</th>
            <th>D</th>
            <th>L</th>
            <th>Pts</th>
            <th>+/-</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team) => (
            <tr key={team.rank}>
              <td>{team.rank}</td>
              <td>
                <img src={team.logo} alt={team.name} className="team-logo" />
              </td>
              <td>{team.name}</td>
              <td>{team.all.played}</td>
              <td>{team.all.win}</td>
              <td>{team.all.draw}</td>
              <td>{team.all.lose}</td>
              <td>{team.points}</td>
              <td>{team.goalsDiff}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </BoxedComponent>
  );
};

export default Standings;
