import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_COGNITO_PASSWORD, changeCognitoPassword} from 'core/actions/AuthActions';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import { getLoadingStatusSelector } from 'core/selectors/LoadingSelectors';
import { useState, useEffect } from 'react';
import AccountSettings from 'components/Profile/AccountSettings';


export default function ProfileContainer() {

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;


  return (
    <ResponsiveContainer  showCarousel={false}>
      <AccountSettings
        isMobile={width < breakpoint}
      />
    </ResponsiveContainer>
  )
}