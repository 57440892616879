import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  detailsRoot: {
    padding: '5px',
    backgroundColor: theme_fpl.palette.common.white,
    color: theme_fpl.palette.common.black,
  },
  leagueDetailsHeader: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: theme_fpl.typography.fontFamily,
  },
  row: {
    backgroundColor: '#f5f5f5',
  },
  circularIcon: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: theme_fpl.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  detailColumn: {
    fontFamily: theme_fpl.typography.fontFamily,
    fontSize: '14px',
    fontWeight: 600,
  },
  rightDetailColumn: {
    color: theme_fpl.palette.primary.main,
    fontWeight: 700,
    fontSize: '16px',
    fontFamily: theme_fpl.typography.fontFamily,
  },
  expandIcon: {

  },
  percentTitle: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: theme_fpl.typography.fontFamily,
    fontSize: '14px',
    fontWeight: 600,
    alignItems: 'center',
    justifyContent: 'center',
  },
  percentText: {
    marginLeft: 50,
  },
  percentIndex: {
    fontFamily: theme_fpl.typography.fontFamily,
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center'
  },
  buttonContainer: {
    marginTop: '3em',
    marginBottom: '1em',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  joinButton: {
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    height: 45,
    backgroundColor: theme_fpl.palette.primary.main,
    paddingLeft: 50,
    paddingRight: 50,
    color: theme_fpl.palette.common.white,
    fontFamily: theme_fpl.typography.fontFamily,
  }
}));
