import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  innerContainer: {
    marginTop: 20,
    marginBottom: 40,
  },
  userNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme_fpl.palette.primary.main}`,
    paddingBottom: 10,
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  usernameText: {
    marginLeft: 20,
    fontSize: '22px',
    fontWeight: 700,
    color: theme_fpl.palette.common.white,
    textTransform: 'uppercase',
  },
  errorMessage: {
    width: '100%',
    color: theme_fpl.palette.primary.main,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '16px'
  },
  walletAddressContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  questionIcon: {
    // marginBottom: 2,
    fontSize: 20,
    padding: 5,
    color:  theme_fpl.palette.common.black,
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: theme_fpl.palette.secondary.main,
    marginLeft: '10px',
  },
  helpContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '5px',
  },
  walletAmountContainer: {
    marginTop: 15,
    marginBottom: 5,
    borderBottom: `1px solid ${theme_fpl.palette.primary.main}`
  },
  walletAmount: {
    marginTop: 10,
    fontSize: '24px',
    fontWeight: 700,
    marginLeft: 30,
    color: theme_fpl.palette.secondary.main,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  minimumWarning: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme_fpl.palette.common.white,
  },
  tapAndPlay: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: '16px',
    fontWeight: 300,
    textAlign: 'center',
  },
  enterTeam: {
    fontSize: '20px',
    textAlign: 'center',
    color: theme_fpl.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  inputContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  input: {

  },
  withdrawContainer: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  teamNameContainer: {
    alignContent: 'center',
    marginTop: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  withdrawButton: {
    borderRadius: 20,
    height: 30,
    fontSize: '12px',
    backgroundColor: theme_fpl.palette.secondary.main,
    color:  theme_fpl.palette.common.black,
    paddingRight: 20,
    paddingLeft: 20,
  }
}));
