import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import Input from 'components/Shared/Input/Input';
import { useStyles } from './WithdrawDialog.styles';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { isValidPolygonAddress, isValidRealNumber } from 'utils/Helpers';


interface IProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (boolean) => void;
  onConfirm: (address:string, amount:string) => void;
}

const WithdrawDialog = ({isDialogOpen, setIsDialogOpen, onConfirm}: IProps) => {
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');

  const classes = useStyles();


  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleConfirm = () => {
    onConfirm(address, amount);
  };

  const isInputValid = () =>
    address.trim().length > 0 && isValidPolygonAddress(address) && amount.trim().length > 0 && isValidRealNumber(amount)


  return (
    <div>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} classes={{ paper: classes.dialogContainer }}>
        <DialogTitle>Withdraw USDT</DialogTitle>
        <DialogContent>
          <p className={classes.paragraph}>
            Enter your personal wallet address. Please double-check its correctness.
            Please contact support as soon as possible if you realize that the address was incorrect.
          </p>
          <Grid className={classes.inputContainer}>
            <Input
              placeholder="Your wallet address"
              value={address}
              onChange={handleAddressChange}
            />
          </Grid>
          <Grid className={classes.inputContainer}>
            <Input
              placeholder="Amount"
              value={amount}
              onChange={handleAmountChange}
            />
          </Grid>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <LoadingButton
            color='secondary'
            onClick={handleConfirm}
            loading={false}
            variant='contained'
            style={{borderRadius: 20, height: 40, fontSize: '14px', flex: 1 }}
            disabled={!isInputValid()}>
            Confirm
          </LoadingButton>
          <Button
             className={classes.cancelButton}
             onClick={handleCloseDialog}
            >
              Cancel
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WithdrawDialog;







