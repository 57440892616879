import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  inputFieldProps: {
    width: '100%',
    height: '2.5rem',
    padding: 1,
    "&:-webkit-autofill": {
       WebkitBoxShadow: "0 0 0 1000px #484848 inset",
    },
    WebkitTextFillColor: 'white',
  },
  inputRequired: {
  },
  readOnlyInput: {
    background: 'transparent',
    width: '86%',
    fontSize: '16px'
  },
}));

