import { Grid, InputAdornment, TextField } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './Input.styles';
import theme_fpl from 'utils/Theme';

interface Props {
  label?: string;
  value?: any;
  name?: string;
  id?: string;
  type?: string;
  isRequired?: boolean;
  defaultValue?: any;
  helperText?: string;
  placeholder?: string;
  hasError?: boolean;
  disabled?: boolean;
  startIcon?: any;
  endIcon?: any;
  className?: any;
  readOnly?: boolean;
  minDate?: string;
  maxDate?: string;
  color?: string;
  multiline?: boolean;
  rows?: number;
  backgroundColor?: string;
  onClick?: (e: any) => void;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onKeyUp?: (e: any) => void;
}

const Input: React.FC<Props> = ({
  label,
  value,
  name,
  id,
  type,
  isRequired,
  defaultValue,
  helperText,
  placeholder,
  startIcon,
  endIcon,
  hasError,
  disabled,
  className,
  readOnly,
  color,
  multiline,
  rows,
  backgroundColor,
  onChange,
  onBlur,
  onFocus,
  onKeyUp,
  onClick,
  maxDate,
  minDate
}: Props) => {
  const classes = useStyles();
  const markAsRequired =
    isRequired && !value ? clsx(classes.inputFieldProps, classes.inputRequired) : classes.inputFieldProps;


  const fontColor = color || theme_fpl.palette.common.white;
  return (
    <TextField
      InputProps={{
        sx: {
          borderRadius: '0px 20px 0px 20px', paddingLeft:2, paddingRight: 2,
          backgroundColor: backgroundColor || theme_fpl.palette.grey[600],
          "&.MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill": {
          borderColor: theme_fpl.palette.grey[600],
          border: "1px solid #484848",
          color: fontColor,
        },
      },
        readOnly: readOnly || false,
        startAdornment: startIcon && <InputAdornment position='start'>{startIcon}</InputAdornment>,
        endAdornment: endIcon && <InputAdornment position='end'>{endIcon}</InputAdornment>,
        classes: { input: markAsRequired },
        style: { fontSize: '0.9rem', color: fontColor },
        inputProps: { min: minDate, max: maxDate }
      }}
      fullWidth
      error={hasError}
      id={id}
      name={name}
      type={type ?? 'text'}
      variant='outlined'
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      onClick={onClick}
      required={isRequired}
      helperText={hasError ? helperText : null}
      disabled={disabled}
      {...(multiline && { multiline: true, rows: rows })}
    />
  );
};

export default Input;
