import { produce } from 'immer';
import { IActivity } from 'core/models/Models';
import { RESET_IS_ISSUE_SENT, SEND_SUPPORT_ISSUE } from 'core/actions/EmailActions';

export interface IEmailState {
  isIssueSent: boolean;
}

const initialState: IEmailState = {
  isIssueSent: false,
};


const emailReducer = (state = initialState, action: IActivity): IEmailState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SEND_SUPPORT_ISSUE.POST.SUCCESS:
        draft.isIssueSent = true;
        break;
      case RESET_IS_ISSUE_SENT:
        draft.isIssueSent = false;
        break;
      default:
        return draft;
    }
  });

export default emailReducer;
