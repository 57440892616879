import { all, spawn } from 'redux-saga/effects';
import authSaga from './AuthSaga';
import userSaga from './UserSaga';
import emailSaga from './EmailSaga';
import newsSaga from './NewsStaga';
import leagueSaga from './LeagueSaga';
import commonsSaga from './CommonsSaga';

// import authSaga from './AuthSaga';
// import notificationsSaga from './NotificationsSaga';


export function* startWatchers() {
  yield all([
    spawn(authSaga),
    spawn(userSaga),
    spawn(emailSaga),
    spawn(newsSaga),
    spawn(leagueSaga),
    spawn(commonsSaga),
  ]);
}

export default function* rootSaga() {
  yield all([startWatchers()]);
}
