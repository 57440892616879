import Group_2637 from './images/footer.svg';
import cover from './images/naslovna.jpg';
import coverMobile from './images/lopta2mobile@2x.png';
import tableHeader from './images/tableHeader.png'
import componentLogo from './images/cryptologo1.png'
import teamID from './images/teamID.png'
import teamID2 from './images/teamID2.png'
import juve_2x from './images/juve_2x.png'
import juve_right_2x from './images/juve_right_2x.png'
import juve_right from './images/juve_right.png'
import carousel1 from './images/carousel1.jpg'
import carousel2 from './images/carousel2.jpg'
import carousel3 from './images/carousel3.jpg'
import carousel4 from './images/carousel4.jpg'
import {ReactComponent as MatchesIcon} from './images/MatchesIcon.svg'
import {ReactComponent as LeaguesIcon} from './images/LeaguesIcon.svg'
import {ReactComponent as FootballIcon} from './images/FootballIcon.svg'
import {ReactComponent as HomeIcon} from './images/HomeIcon.svg'


const images = {
  fplLogo: {
    src: Group_2637,
    alt: 'Cryptofantasy'
  },
  coverImage: {
    src: cover,
    alt: 'Play Enjoy Earn',
  },
  coverImageMobile: {
    src: coverMobile,
    alt: 'Play Enjoy Earn',
  },
  carousel1: {
    src: carousel1,
    alt: 'Play Enjoy Earn',
  },
  carousel2: {
    src: carousel2,
    alt: 'Play Enjoy Earn',
  },
  carousel3: {
    src: carousel3,
    alt: 'Play Enjoy Earn',
  },
  carousel4: {
    src: carousel4,
    alt: 'Play Enjoy Earn',
  },
  matchesIcon: {
    src: MatchesIcon
  },
  leaguesIcon: {
    src: LeaguesIcon
  },
  footballIcon: {
    src: FootballIcon
  },
  homeIcon: {
    src: HomeIcon
  },
  tableHeader: {
    src: tableHeader,
    alt: '',
  },
  componentLogo: {
    src: componentLogo,
    alt: '',
  },
  teamID: {
    src: teamID,
    alt: '',
  },
  teamID2: {
    src: teamID2,
    alt: '',
  },
  desktopInnerImage: {
    src: juve_2x,
    alt: '',
  },
  desktopInnerImageRight: {
    src: juve_right_2x,
    alt: '',
  },
  desktopRight: {
    src: juve_right,
    alt: '',
  },
};

export default images;
