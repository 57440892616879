import { ILeague, IGameWeek, IUserLeague } from 'core/models/Models';
import { AppState } from 'core/reducers/RootReducer';
import { createSelector } from 'reselect';

const getPublicLeagues = (state: AppState): ILeague[] => state.league.publicLeagues;
const getPublicH2H = (state: AppState): ILeague[] => state.league.publicH2H;
const getUsersCurrentLeagues = (state: AppState): IUserLeague[] => state.league.usersCurrentLeagues;
const getUsersCurrentH2H = (state: AppState): IUserLeague[] => state.league.usersCurrentH2H;
const getUsersFinishedLeagues= (state: AppState): IUserLeague[] => state.league.usersFinishedLeagues;
const getUsersFinishedH2H = (state: AppState): IUserLeague[] => state.league.usersFinishedH2H;


const getNextGameWeek = (state: AppState): IGameWeek => state.league.nextGameWeek;


export const getPublicLeaguesSelector = createSelector(getPublicLeagues, (league) => league);
export const getPublicH2HSelector = createSelector(getPublicH2H, (league) => league);
export const getNextGameWeekSelector = createSelector(getNextGameWeek, (league) => league);
export const getUsersCurrentLeaguesSelector = createSelector(getUsersCurrentLeagues, (league) => league);
export const getUsersCurrentH2HSelector = createSelector(getUsersCurrentH2H, (league) => league);
export const getUsersFinishedLeaguesSelector = createSelector(getUsersFinishedLeagues, (league) => league);
export const getUsersFinishedH2HSelector = createSelector(getUsersFinishedH2H, (league) => league);
