// LeagueDialog.js
import React, { useState } from 'react';
import { Table, TableBody, TableRow, TableCell, Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import { CompetitionType, ILeague, LeagueType } from 'core/models/Models';
import { faStar, faCalendarAlt, fa1, faPercent, faUser, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStyles } from './DetailPanel.styles';
import { FaAngleDown } from 'react-icons/fa';
import LoadingButton from '@mui/lab/LoadingButton';


interface IProps {
  rowData: any;
  isButtonDisabled: boolean;
  disabledReason?: string;
  onJoinLeague?: (leagueId: string) => void;
  onLeaveLeague?: (leagueId: string, leagueType: LeagueType) => void;
  isMobile: boolean;
}

const DetailPanel = ({ rowData, isButtonDisabled, disabledReason, onJoinLeague, onLeaveLeague, isMobile}: IProps) => {
  // You can populate the row data in the future based on your requirements

  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleJoin = () => {
    if (onJoinLeague) {
      onJoinLeague(rowData.leagueId);
    }
  }

  const handleLeave = () => {
    if (onLeaveLeague) {
      onLeaveLeague(rowData.leagueId, rowData.leagueType);
    }
  }

  const rows = [
    {
      icon: faKey,
      label: 'Entry code',
      value: rowData.entryCode,
    },
    {
      icon: faStar,
      label: 'Prize',
      value: rowData.prize,
    },
    {
      icon: faUser,
      label: 'Opponent',
      value: rowData.opponentName,
    },
    {
      icon: faCalendarAlt,
      label: 'Start (Gameweek)',
      value: rowData.startGameWeek,
    },
    {
      icon: faCalendarAlt,
      label: 'End (Gameweek)',
      value: rowData.endGameWeek,
    },
    {
      icon: fa1,
      label: 'Rewarded players',
      value: rowData.winnersNumber,
    }
    // Add more rows as needed
  ];

  const handleAccordionChange = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={classes.detailsRoot}>
      <div>
        {/* Heading with league name */}
        <h3 className={classes.leagueDetailsHeader}>{rowData.name}</h3>

        <Table>
          <TableBody>
            {rows.filter((row) => row.value).map((row, index) => (
              <>
              <TableRow key={index} className={index % 2 === 0 ? classes.row : ''}>
                <TableCell>
                  <span className={classes.circularIcon}>
                    <FontAwesomeIcon icon={row.icon} />
                  </span>
                </TableCell>
                <TableCell className={classes.detailColumn}>{row.label}</TableCell>
                <TableCell align="right" className={classes.rightDetailColumn}>{row.value}</TableCell>
              </TableRow>
              </>
            ))}
          </TableBody>
        </Table>

        {rowData?.prizeDistribution && (
          <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
            <AccordionSummary expandIcon={<FaAngleDown className={classes.expandIcon}/>}>
              <div className={classes.percentTitle}>
                <span className={classes.circularIcon}>
                  <FontAwesomeIcon icon={faPercent} />
                </span>
                <span className={classes.percentText}>
                  Prize distribution
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableBody>
                  {rowData.prizeDistribution.map((value, index) => (
                    <TableRow key={index} className={index % 2 === 0 ? classes.row : ''}>
                      <TableCell className={classes.percentIndex}>
                        {index + 1}. place
                      </TableCell>
                      <TableCell align="right" className={classes.rightDetailColumn}>{value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        )}


        <div className={classes.buttonContainer}>
          {onJoinLeague &&
            <LoadingButton
              onClick={handleJoin}
              loading={false}
              variant='contained'
              className={classes.joinButton}
              disabled={isButtonDisabled}>
              Join
            </LoadingButton>
          }
          {onLeaveLeague &&
            <LoadingButton
              onClick={handleLeave}
              loading={false}
              variant='contained'
              className={classes.joinButton}
              disabled={isButtonDisabled}>
              Leave
            </LoadingButton>
          }
        </div>
      </div>
    </div>
  );
};

export default DetailPanel;
