
export const formatDateToMonthAndYear = (dbDateString) => {
  if (dbDateString == null || dbDateString === '') {
    return '';
  }
  const dateObj = dbDateString ? new Date(dbDateString) : new Date();

  const year = dateObj.toLocaleDateString('en', { year: 'numeric' });
  const month = dateObj.toLocaleDateString('en', { month: 'short' });
  return `${month} ${year}`;
};

export const localDatetime = (date) => {
  return new Date(date.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19);
};

export const convertStringToDateObject = (dateStr) => {
  return dateStr ? new Date(dateStr) : null;
};

export const formatDateObject = (dateObj) => {
  return dateObj ? new Date(dateObj).toLocaleDateString('sr-RS') : '';
};


export const formatDateToDatabaseFormat = (dateObj) => {
  return dateObj ? dateObj.toJSON().slice(0, 10) : null;
};

export function isValidEmailURL(url) {
  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(url);
}

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!#%&/,><’:;|_~`])\S{8,99}$/;

// export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

export const getRandomArbitrary = (min = 3, max = 20000) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export function decodeBase64(base64String) {
  return Buffer.from(base64String, 'base64').toString();
}

export function encodeBase64(stringToEncode) {
  return Buffer.from(stringToEncode, 'utf8').toString('base64');
}

export function navigateToExternalPage(url) {
  window.location.assign(url);
}

export function splitUrlOnLast(url: string) {
  if (url) {
    const urlSplited = url.split('?')[0].split('/');
    return urlSplited[urlSplited?.length - 1];
  }
  return '';
}

export function splitS3Url(url) {
  if (url != (null || undefined || '')) {
    const decodedUrl = decodeURIComponent(url);
    const identityRegex = new RegExp('eu-west-1:[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}');
    const identityMatch = decodedUrl.match(identityRegex);
    let key = '';
    let identity = '';
    if (identityMatch != null) {
      key = decodedUrl.split(identityMatch + '/')[1];
      identity = identityMatch[0] + '';
    } else {
      key = decodedUrl.split('public/')[1];
    }
    return [identity, key];
  }
  return ['', ''];
}


export function isEmailValid(text) {
  if (text?.trim() == '') {
    return false;
  }
  let reg = /^\w+([\.-]?\w+)([\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  return reg.test(text) === true;
}


export function isValidPolygonAddress(address) {
  const polygonAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
  return polygonAddressRegex.test(address);
}


export function isValidRealNumber(value) {
  const realNumberRegex = /^-?\d+(\.\d+)?$/;
  return realNumberRegex.test(value);
}


type ArrayType = Array<number | string>;
export const arrayDifference = (arr1: ArrayType, arr2: ArrayType): ArrayType => arr1.filter((x) => !arr2.includes(x));
export const arrayIntersection = (arr1: ArrayType, arr2: ArrayType): ArrayType => arr1.filter((x) => arr2.includes(x));
