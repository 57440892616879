import React, { useEffect, useState } from 'react';
import { useStyles } from './styles/VerificationCode.styles';
import { Divider, Grid, Icon, IconButton, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { CircleLoader } from 'react-spinners';
import { decodeBase64, isEmailValid } from 'utils/Helpers';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import theme_fpl from 'utils/Theme';


interface Props {
  onConfirmCode: (email: string, code: string) => void;
  onResendConfitmationCode: (email: string) => void;
  isMobile: boolean;
  isLoading: boolean;
  email: string;
  verificationError: boolean;
}

const VerificationCode: React.FC<Props> = ({ email, onConfirmCode, onResendConfitmationCode, verificationError, isMobile, isLoading }: Props) => {
  const classes = useStyles();
  const [paramsError, setParamsError] = useState(true);
  const [empty, setEmpty] = useState(true);
  const [resendEmail, setResendEmail] = useState<string>('');


  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const emailParam = searchParams.get('email') || '';
    const codeParam = searchParams.get('code') || '';
    if (emailParam?.length || 0 > 0) {
      setResendEmail(emailParam);
    }

    if (emailParam?.length || 0 > 0 && codeParam?.length || 0 > 0) {
      setEmpty(false);

      try {
        const emailDecoded = decodeBase64(emailParam);
        const codeDecoded = decodeBase64(codeParam);
        onConfirmCode(emailDecoded, codeDecoded);
        setParamsError(false);
      }
      catch(Exception) {
        setParamsError(true);
      }
    }

  }, []);


  const handleResendConfirmationCode = () => {
    if (resendEmail) {
      onResendConfitmationCode(resendEmail);
    }
  }


  return (
    <BoxedComponent>
      {empty && email ? (
        <Grid className={classes.verificationTextContainer}>
          <h3>Thank you for signing up!</h3>
            <p>We have sent a verification email to {email}.</p>
            <p>
              Please check your inbox and follow the instructions to verify your email.
              If you don't see the email in your inbox, please check your spam folder.
            </p>
        </Grid>
        ) : (
          <>
            {paramsError ? (
              <>
                <h3>Invalid email and verification code</h3>
                <p>
                  If you don't have an account, <RouterLink to="/signup">sign up here</RouterLink>.
                </p>
              </>
            ) : (
              <>
                {verificationError ? (
                  <Grid className={classes.resendCodeTextContainer}>
                    <Link className={classes.resendCodeText} onClick={handleResendConfirmationCode}>Invalid code. Resend verification code</Link>
                  </Grid>
                ) : (
                  <>
                    <Grid>
                      Verifying account...
                    </Grid>
                    <CircleLoader size={150} color={theme_fpl.palette.primary.main} loading={isLoading} />
                   </>
                )}
            </>
            )}
          </>
        )}
    </BoxedComponent>
  );
};

export default VerificationCode;
