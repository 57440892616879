import { useDispatch, useSelector } from 'react-redux';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import { getLoadingStatusSelector } from 'core/selectors/LoadingSelectors';
import { useState, useEffect } from 'react';
import { USER, createUser } from 'core/actions/UserActions';
import WalletAndTeam from 'components/SignUp/WalletAndTeam';
import { Grid } from '@mui/material';
import images from 'assets/images';


export default function TeamContainer() {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;
  const isLoading = useSelector(getLoadingStatusSelector(USER.POST.REQUEST));

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const handleSaveTeamId = (teamId) => {
    dispatch(createUser.request(teamId));
  }

  const isDesktop = width >= breakpoint;
  const isMobile = width < breakpoint;

  return (
    <ResponsiveContainer splitOn={1}>
      <WalletAndTeam isMobile={isMobile} isLoading={isLoading} error={false} handleSaveTeamId={handleSaveTeamId} />
      {isDesktop && (
        <Grid style={{marginTop: '-15em'}}>
          <img src={images.desktopRight.src} alt={images.desktopRight.alt}/>
        </Grid>
      )}
    </ResponsiveContainer>
  )
}