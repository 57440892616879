import { makeStyles } from '@material-ui/core/styles';
import theme_fpl from 'utils/Theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme_fpl.palette.common.black,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    boxSizing: 'border-box',
    borderRadius: '25px',
    boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.5)',
    marginTop: '1em',
  }
}));

export default useStyles;