import { IGameWeek } from 'core/models/Models';
import { AppState } from 'core/reducers/RootReducer';
import { createSelector } from 'reselect';

const getCurrentGameWeek = (state: AppState): IGameWeek => state.commons.currentGameWeek;
const getNextGameWeek = (state: AppState): IGameWeek => state.commons.nextGameWeek;
const getFirstEligibleGameWeek = (state: AppState): IGameWeek => state.commons.firstEligibleGameWeek;

export const getCurrentGameWeekSelector = createSelector(getCurrentGameWeek, (currentGameWeek) => currentGameWeek);
export const getNextGameWeekSelector = createSelector(getNextGameWeek, (nextGameWeek) => nextGameWeek);
export const getFirstEligibleGameWeekSelector = createSelector(getFirstEligibleGameWeek, (firstEligibleGameWeek) => firstEligibleGameWeek);
