import React from 'react';
import { useStyles } from "./GameWeekPicker.style";
import { faChevronLeft, faChevronRight  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface IProps {
  gameWeek: number;
  onDecrement: () => void;
  onIncrement: () => void;
}

const currentDate = new Date().toLocaleDateString('en-US', {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
});


const GameWeekPicker = ({gameWeek, onDecrement, onIncrement}: IProps) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={onDecrement}
          className={classes.arrowIcon}
        />
        <span className={classes.gameWeekText}>Gameweek {gameWeek}</span>
        <FontAwesomeIcon
          icon={faChevronRight}
          onClick={onIncrement}
          className={classes.arrowIcon}
        />
      </div>
      <div className={classes.currentDate}>{currentDate}</div>
    </div>
  );
  };
  
export default GameWeekPicker;