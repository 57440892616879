import { useStyles } from './styles/MatchesContainer.styles';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import TopComponentMobile from 'components/TopComponentMobile/TopComponentMobile';
import ImageComponent from 'components/ImageComponent/ImageComponent';
import images from 'assets/images';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import {  USERS_LEAGUES, getUsersLeagues, leaveLeague } from 'core/actions/LeagueActions';
import { USER } from 'core/actions/UserActions';
import { getUsersCurrentH2HSelector, getUsersCurrentLeaguesSelector } from 'core/selectors/LeagueSelectors';
import { getLoadingStatusSelector } from 'core/selectors/LoadingSelectors';
import { getUserSelector } from 'core/selectors/UserSelectors';
import FPLTable from 'components/Table/FPLTable';
import CreateMobileHeader from 'components/Create/CreateMobileHeader';
import { CompetitionType, LeagueType } from 'core/models/Models';
import { getCognitoUserSelector } from 'core/selectors/AuthSelectors';
import theme_fpl from 'utils/Theme';
import { CircleLoader } from 'react-spinners';
import { getCurrentGameWeekSelector, getFirstEligibleGameWeekSelector } from 'core/selectors/CommonsSelector';
import { getGameWeeks } from 'core/actions/CommonsActions';
import GameWeekPicker from 'components/Matches/GameWeekPicker';



export default function MatchesContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;

  const user = useSelector(getUserSelector);
  const isLoading = useSelector(getLoadingStatusSelector(USERS_LEAGUES.GET.REQUEST));
  const cognitoUser = useSelector(getCognitoUserSelector);


  const currentLeagues = useSelector(getUsersCurrentLeaguesSelector);
  const currentH2H = useSelector(getUsersCurrentH2HSelector);

  const currentGameWeek = useSelector(getCurrentGameWeekSelector);
  const firstEligibleGameWeek = useSelector(getFirstEligibleGameWeekSelector);

  const [selectedGameWeek, setSelectedGameWeek] = useState(currentGameWeek.id > 0 ? currentGameWeek.id : 1);


  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);



  // TODO loaded indicator
  useEffect(() => {
    if (!currentLeagues || currentLeagues.length == 0) {
      dispatch(getUsersLeagues.request());
    }
  }, []);

  useEffect(() => {
    if (!currentGameWeek?.id) {
      dispatch(getGameWeeks.request());
    }
  }, []);


  const onIncrementGameWeek = () => {
    if (selectedGameWeek < currentGameWeek.id) {
      setSelectedGameWeek(selectedGameWeek + 1);
    }
  }

  const onDecrementGameWeek = () => {
    if (selectedGameWeek > 1) {
      setSelectedGameWeek(selectedGameWeek - 1);
    }
  }

  const onLeaveLeague = (leagueId, leagueType) => {
    dispatch(leaveLeague.request(leagueId, leagueType, CompetitionType.LEAGUE));
  }

  const onLeaveH2H = (leagueId, leagueType) => {
    dispatch(leaveLeague.request(leagueId,  leagueType, CompetitionType.H2H));
  }


  const disabledLeagues = currentLeagues
  ?.filter((league) => league?.startGameWeek < firstEligibleGameWeek?.id || league.createdBy == cognitoUser.attributes.sub)
  .map((league) => league.leagueId);

  const disabledH2H = currentH2H
    ?.filter((league) => league?.startGameWeek < firstEligibleGameWeek?.id || league.createdBy == cognitoUser.attributes.sub)
    .map((league) => league.leagueId);


  const isMobile = width < breakpoint;
  const isDesktop = width >= breakpoint;

  return (
    <>
    {isLoading ? (
      <Grid className={classes.loadingContainer}>
        <CircleLoader size={150} color={theme_fpl.palette.primary.main} loading={isLoading} />
      </Grid>
    ) : (
      <ResponsiveContainer showCarousel={false} splitOn={2} isMobile={isMobile}>
        {isDesktop ? (
        <BoxedComponent height='100%'>
          <Grid className={classes.topImageContainer}>
            <ImageComponent heading={'Matches'}/>
          </Grid>
        </BoxedComponent>
        ) : (
        <CreateMobileHeader heading={"Matches"}>
          <GameWeekPicker gameWeek={selectedGameWeek} onIncrement={onIncrementGameWeek} onDecrement={onDecrementGameWeek}/>
        </CreateMobileHeader>
        )}
        {isDesktop && (
          <Grid className={classes.imageContainer}>
            <img src={images.desktopInnerImage.src} alt={images.desktopInnerImage.alt}/>
          </Grid>
        )}
        {isDesktop && (
          <BoxedComponent>
            <GameWeekPicker gameWeek={selectedGameWeek} onIncrement={onIncrementGameWeek} onDecrement={onDecrementGameWeek}/>
          </BoxedComponent>
        )}
        <>
          <Grid className={classes.tablesContainer}>
          {currentLeagues.length == 0 && currentH2H.length == 0 ? (
            <BoxedComponent>Not participating in any competitions yet</BoxedComponent>
          ) : (
            <>
            <div className={classes.tableContainer}>
              <FPLTable isMobile={isMobile} title={'Leagues'} leagues={currentLeagues} disabledLeagueIds={disabledLeagues} user={user} cognitoUser={cognitoUser} onLeaveLeague={onLeaveLeague} competitionType={CompetitionType.LEAGUE}/>
            </div>
            <div className={classes.tableContainer}>
              <FPLTable isMobile={isMobile} title={'H2H'} leagues={currentH2H} disabledLeagueIds={disabledH2H} user={user} cognitoUser={cognitoUser} onLeaveLeague={onLeaveH2H} competitionType={CompetitionType.H2H}/>
            </div>
            </>
          )}
          </Grid>
        </>
    </ResponsiveContainer>
    )}
  </>
  )

}

