import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  teamLogo: {
    width: '30px',
    height: '30px',
    objectFit: 'contain',
  },
  tableContainer: {
    background: theme_fpl.palette.common.black,
    marginRight: '10em'
  },
  title: {
    textAlign: 'center', // Center align the content
  },
}));
