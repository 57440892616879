// preferred way to import (from `v4`). Uses `animate__` prefix.
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import 'animate.css/animate.min.css';
import React from 'react';
import { render } from "react-dom";
// import { ReactNotifications } from 'react-notifications-component';
// import 'react-notifications-component/dist/theme.css';
import { Provider } from 'react-redux';
import App from './App';
// import { history } from 'core/reducers/RootReducer';
// import reportWebVitals from './reportWebVitals';
import store from './core/store';
import './index.css';
import * as serviceWorker from './serviceWorker';
import './utils/i18n';
import { BrowserRouter } from 'react-router-dom';
import theme_fpl from './utils/Theme';
import Authorizer from './config/Authorizer';
import 'react-notifications-component/dist/theme.css';
import { ReactNotifications } from 'react-notifications-component';
import { GlobalHistory } from './utils/global-history';
import { StylesProvider, createGenerateClassName } from '@mui/styles';

import * as buffer from 'buffer';
window.Buffer = buffer.Buffer;


const generateClassName = createGenerateClassName({
  productionPrefix: 'fpl', // Use your desired prefix for production class names
});



Authorizer();

const AppWrapper = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme_fpl}>
      <StylesProvider generateClassName={generateClassName}>
        <StyledEngineProvider>
          <div className='app-container'>
            <ReactNotifications />
              <BrowserRouter>
                <GlobalHistory />
                <App />
              </BrowserRouter>
          </div>
        </StyledEngineProvider>
      </StylesProvider>
    </ThemeProvider>
  </Provider>
);

const container = document.getElementById('root');


render(<AppWrapper />, container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// if (process.env.NODE_ENV === 'development') {
//   reportWebVitals(console.log);
// }
