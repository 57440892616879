import { useStyles } from './styles/NewsContainer.styles';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import ImageComponent from 'components/ImageComponent/ImageComponent';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import images from 'assets/images';
import Standings from 'components/News/Standings';


export default function NewsContainer() {
  const classes = useStyles();

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;

  const isMobile = width < breakpoint;
  const isDesktop = width >= breakpoint;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <ResponsiveContainer showCarousel={false} splitOn={2} isMobile={isMobile}>
      {isDesktop ? (
      <BoxedComponent height='100%'>
        <Grid className={classes.topImageContainer}>
          <ImageComponent heading={'News'}/>
        </Grid>
      </BoxedComponent>
      ) : (
      <BoxedComponent>
        <ImageComponent heading={'News'}/>
      </BoxedComponent>
      )}
      {isDesktop && (
        <Grid className={classes.imageContainer}>
          <img src={images.desktopInnerImage.src} alt={images.desktopInnerImage.alt}/>
        </Grid>
      )}
      <Standings isMobile={width < breakpoint}/>
    </ResponsiveContainer>
  )
}
