import React, { useEffect, useState } from 'react';
import { Grid, Button, Chip  } from '@mui/material';
import useStyles from './styles/CreateLeague.styles';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import Input from 'components/Shared/Input/Input';
import theme_fpl from 'utils/Theme';
import clsx from 'clsx';
import LoadingButton from '@mui/lab/LoadingButton';
import PrizesModal from './PrizesModal';
import { CompetitionType, IGameWeek, ILeague, LeagueType } from 'core/models/Models';
import { getNextGameWeek, createLeague } from 'core/actions/LeagueActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getNextGameWeekSelector } from 'core/selectors/LeagueSelectors';
import { globalNavigate } from 'utils/global-history';
import { AppRoutes } from 'config/AppRoutes';
import MessageContainer from 'components/Shared/Container/MessageContainer';
import { getUserSelector } from 'core/selectors/UserSelectors';
import PopupDialog from 'components/Shared/Dialog/PopupDialog';
import ValueInput from './ValueInput';


const initialData: ILeague = {
  leagueName: '',
  leagueType: LeagueType.PUBLIC,
  entryPrice: 2,
  winnersNumber: 1,
  prizeDistribution: Array<number>(),
  endGameWeek: 0,
  startGameWeek: 0,
  maxPlayers: 0,
  competitionType: CompetitionType.LEAGUE
}

const CreateLeague = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [leagueData, setLeagueData] = useState(initialData);
  const [selectedChip, setSelectedChip] = useState('Public');
  const nextGameWeek = useSelector(getNextGameWeekSelector);
  const user = useSelector(getUserSelector);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handlePressEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSubmitCreateLeague();
    }
  }

  const handleInputChange = ({ target: { name, value } }) => {
    if (name == 'entryPrice') {
      setIsErrorVisible((user.walletBalance || 0)< value);
    }
    setLeagueData({ ...leagueData, [name]: value });
  };

  const setEntryPrice = (value) => {
    setLeagueData({ ...leagueData, entryPrice: value });
  }

  useEffect(() => {
    dispatch(getNextGameWeek.request());
  }, []);


  useEffect(() => {
    if (nextGameWeek) {
      setLeagueData({
        ...leagueData, startGameWeek: nextGameWeek.id, endGameWeek: nextGameWeek.id + 1
      })
    }
  }, [nextGameWeek]);

  const onCreateLeague = (league: ILeague) => {
    dispatch(createLeague.request(league));
  }


  const handleChipClick = (chip) => {
    console.log(chip)
    if (chip == 'Public') {
      setLeagueData({...leagueData, leagueType: LeagueType.PUBLIC});
    } else {
      setLeagueData({...leagueData, leagueType: LeagueType.PRIVATE});
    }
    setSelectedChip(chip);
  };


  const handleCreateLeague = () => {
    setIsDialogOpen(false);
    onCreateLeague(leagueData)
  }


  const handleSubmitCreateLeague = () => {
    if (leagueData.leagueType == LeagueType.PUBLIC) {
      handleCreateLeague();
    }
    else {
      setIsDialogOpen(true);
    }
  }

  const onCancel = () => {
    globalNavigate(AppRoutes.home.link);
  }

  const setPrizeDistribution = (index: number, value: string) => {
    const updatedValues: number[] = [...leagueData.prizeDistribution];
    updatedValues[index] = parseInt(value);
    setLeagueData({...leagueData, prizeDistribution: updatedValues})
  }

  const setWinnersNo = (winnersNumber) => {
    setLeagueData({...leagueData, winnersNumber: winnersNumber})
  }

  const validatePrizeDistribution = () => {
    const sum = leagueData.prizeDistribution.slice(0, leagueData.winnersNumber).reduce((total, num) => total + num, 0);
    // Check if the sum is equal to 100
    return sum === 100;
  }

  const isEntriesValid = () => {
    return leagueData.leagueName.trim().length > 0 && leagueData.entryPrice > 0 &&
      leagueData?.startGameWeek && leagueData?.endGameWeek &&
      leagueData.startGameWeek > 0 && leagueData?.endGameWeek > 0
      && leagueData.winnersNumber > 0 && validatePrizeDistribution()
      && !isErrorVisible
  }


  const hasError = () => !isEntriesValid()

  return (
    <BoxedComponent>
      <Grid className={classes.messageContainer}>
        <MessageContainer message={'You will automatically join the league after creating it, and the entry fee will be subtracted from your balance.'}/>
      </Grid>
      {isErrorVisible &&
        <Grid className={classes.messageContainer}>
          <MessageContainer message={'Insufficient funds to pay the entry fee'}/>
        </Grid>
      }
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container className={classes.inputContainer}>
            <Grid className={classes.label}>
              league name
            </Grid>
            <Grid>
            <Input
              name='leagueName'
              className={classes.input}
              value={leagueData.leagueName}
              onChange={handleInputChange}
              hasError={false}
              isRequired={true}
              onKeyUp={handlePressEnter}
            />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.inputContainer}>
            <Grid>
              <Grid className={classes.label}>
                league type
              </Grid>
            </Grid>
            <Grid item className={classes.buttonContainer}>
              <Chip
                clickable={false}
                label='public' className={clsx(classes.chip, classes.leftChip, selectedChip === 'Public' ? classes.selectedChip : classes.noteSlectedChip)}
                onClick={() => handleChipClick('Public')}
              />
              <Chip
                clickable={false}
                label='private' className={clsx(classes.chip, classes.rightChip, selectedChip === 'Private' ? classes.selectedChip : classes.noteSlectedChip)}
                 onClick={() => handleChipClick('Private')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.inputContainer}>
            <Grid className={classes.label}>
              Entry price
            </Grid>
            <Grid>
            <ValueInput
              value={leagueData.entryPrice}
              setValue={setEntryPrice}
            />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.inputContainer}>
            <Grid className={classes.label}>
              Max players (optional)
            </Grid>
            <Grid>
            <Input
              name='maxPlayers'
              className={classes.input}
              value={leagueData.maxPlayers > 0 ? leagueData.maxPlayers : ''}
              onChange={handleInputChange}
              hasError={false}
              isRequired={true}
              onKeyUp={handlePressEnter}
              type='number'
            />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.inputContainer}>
            <Grid className={classes.label}>
              number of winners
            </Grid>
            <Grid>
              <PrizesModal
                percentages={leagueData.prizeDistribution}
                setPercentages={setPrizeDistribution}
                numberOfWinners={leagueData.winnersNumber}
                setNumberOfWinners={setWinnersNo}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.inputContainer}>
          <Grid className={classes.label}>
            start (gameweek)
          </Grid>
          <Grid>
          <Input
            name='startGameWeek'
            className={classes.input}
            value={leagueData.startGameWeek}
            onChange={handleInputChange}
            hasError={false}
            isRequired={true}
            onKeyUp={handlePressEnter}
            type='number'
            color={theme_fpl.palette.secondary.main}
          />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.inputContainer}>
          <Grid className={classes.label}>
            end (gameweek)
          </Grid>
          <Grid>
          <Input
            name='endGameWeek'
            className={classes.input}
            value={leagueData.endGameWeek}
            onChange={handleInputChange}
            hasError={false}
            isRequired={true}
            onKeyUp={handlePressEnter}
            type='number'
            color={theme_fpl.palette.secondary.main}
          />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.confirmButtonContainer}>
          <LoadingButton
              color='secondary'
              onClick={handleSubmitCreateLeague}
              loading={false}
              variant='contained'
              style={{borderRadius: 20, height: 45}}
              disabled={hasError()}>
              Create
            </LoadingButton>

            <Button
             className={classes.cancelButton}
             onClick={onCancel}
            >
              Cancel
            </Button>
        </Grid>
      </Grid>
      <PopupDialog isOpen={isDialogOpen} onClose={handleCreateLeague} message={'An entry code will be generated in order to allow you to give access to this private league to other players. After successful creation of the league, expand the corresponding table row to find the code.'}/>
    </BoxedComponent>
  );
};

export default CreateLeague;
