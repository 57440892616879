import images from 'assets/images';
import React from 'react';

interface CustomIconProps {
  icon: string;
  color?: string;
}

const SvgIcon: React.FC<CustomIconProps> = ({ icon, color} : CustomIconProps) => {
  const IconImage = images[icon].src;

  if (!IconImage) {
    return null;
  }

  return <IconImage/>
};

export default SvgIcon;
