import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';


const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '1em',
    fontWeight: 600,
    color: theme_fpl.palette.grey[500],
    marginBottom: 10,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  chip: {
    flex: 1,
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 14,
    paddingRight: '1em',
    paddingLeft: '1em',
    color: theme_fpl.palette.common.white,
  },
  rightChip: {
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    // borderBottomRightRadius: '0px',
  },
  leftChip: {
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    marginRight: -10,
    zIndex: 1,
  },
  selectedChip: {
    backgroundColor: theme_fpl.palette.primary.main,
  },
  noteSlectedChip: {
    backgroundColor: theme_fpl.palette.grey[600],
    // backgroundColor: 'transparent',
    // border: `1px solid ${theme_fpl.palette.primary.main}`
  },
  input: {
    color: theme_fpl.palette.secondary.main,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  confirmButtonContainer: {
    marginTop: '5em',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  cancelButton: {
    marginTop: '2em',
    borderRadius: 20,
    height: 45,
    backgroundColor: 'transparent',
    border: `1px solid ${theme_fpl.palette.secondary.main}`,
    color: theme_fpl.palette.common.white,
  },
  infoTextContainer: {
    display:"flex",
    alignItems:"center",
    backgroundColor: "pink",
    color: "red",
    padding: "10px",
    borderRadius: "5px",
    marginBottom: '20px',
  },
  messageContainer: {
    marginBottom: '20px',
  }
}));

export default useStyles;
