import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  walletText: {
    marginLeft: 10,
    fontSize: '14px',
    fontWeight: 500,
    color: theme_fpl.palette.common.white,
    width: '100%',
    maxWidth: '100%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
    marginRight: '20px',
  },
  issueField: {
    fontSize: '1em',
    marginTop: '20px',
    marginBottom: '20px',
  },
  newIssueButton: {
    borderRadius: 20,
    height: 40,
    fontSize: '14px',
    backgroundColor: theme_fpl.palette.secondary.main,
    color: theme_fpl.palette.common.black,
    marginTop: '20px',
    flex: 1
  },
}));
