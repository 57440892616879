import {
    SIGN_IN,
    SIGN_OUT,
    COGNITO_USER,
    UPDATE_SIGNUP_DATA,
    SIGN_UP,
    CONFIRM_SIGN_UP,
    CURRENT_AUTH_USER,
    COGNITO_USER_EMAIL_VERIFICATION,
    FORGOT_PASSWORD_CONFIRMATION,
    FORGOT_PASSWORD,
    COGNITO_USER_EMAIL_CHANGE_VERIFICATION
  } from 'core/actions/AuthActions';
  import { USER } from 'core/actions/UserActions';

  import { IActivity, ICognitoUser, ISigninForm, ISignupForm, ISignupState } from 'core/models/Models';
  import produce from 'immer';

  export interface IAuthState {
    isAuthenticated: boolean;
    cognitoUser: ICognitoUser;
    signupForm: ISignupForm;
    signinForm: ISigninForm;
    cognitoAttributesVerification: {
      attributes?: any;
      confirmationCodeSent: boolean;
      errorMessage?: string;
      isVerified: boolean;
    };
  }
  const initialState: IAuthState = {
    isAuthenticated: false,
    cognitoUser: {} as ICognitoUser,
    signupForm: {
      email: '',
      teamName: '',
      walletAddress: '',
      teamId: 0,
      password: '',
      confirmPassword: '',
      state: ISignupState.NewAccount,
      verificationError: false,
    },
    signinForm: {
      email: '',
      password: '',
      verificationCode: '',
      redirectFromConfirmSignup: false
    },
    cognitoAttributesVerification: {
      attributes: {},
      confirmationCodeSent: false,
      errorMessage: '',
      isVerified: false
    }
  };

  const authReducer = (state = initialState, action: IActivity): IAuthState =>
    produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
        case COGNITO_USER.GET.SUCCESS:
          draft.cognitoUser = payload;
          break;
        case COGNITO_USER.POST.SUCCESS:
          draft.cognitoAttributesVerification.attributes = payload.attributes;
          if (payload?.attributes?.email) {
            draft.cognitoAttributesVerification.confirmationCodeSent = true;
          }
          break;
        case COGNITO_USER.POST.FAILURE:
          draft.cognitoAttributesVerification.attributes = payload.attribute;
          draft.cognitoAttributesVerification.confirmationCodeSent = false;
          draft.cognitoAttributesVerification.errorMessage = payload.error;
          break;
        case COGNITO_USER_EMAIL_CHANGE_VERIFICATION.POST.SUCCESS:
          draft.cognitoAttributesVerification.confirmationCodeSent = false;
          break
        case COGNITO_USER_EMAIL_VERIFICATION.POST.SUCCESS: {
          draft.cognitoUser.attributes.email = payload;
          const attributes = { ...state.cognitoAttributesVerification.attributes };
          delete attributes['email'];
          draft.cognitoAttributesVerification.attributes = attributes;
          draft.cognitoAttributesVerification.confirmationCodeSent = false;
          draft.cognitoAttributesVerification.errorMessage = '';
          draft.cognitoAttributesVerification.isVerified = true;
          return draft;
        }
        case COGNITO_USER_EMAIL_VERIFICATION.POST.FAILURE:
          draft.cognitoAttributesVerification.errorMessage = 'Incorrect code';
          draft.cognitoAttributesVerification.confirmationCodeSent = false;
          break;
        case SIGN_IN.POST.SUCCESS:
          draft.signinForm = { ...state.signinForm, redirectFromConfirmSignup: false}
          draft.cognitoUser = payload;
          draft.isAuthenticated = true;
          break;
        case CURRENT_AUTH_USER.GET.SUCCESS:
          draft.cognitoUser = payload;
          draft.isAuthenticated = true;
          break;
        case SIGN_IN.POST.FAILURE: {
          if (payload?.signupForm) {
            draft.signupForm = {...state.signupForm, ...payload?.signupForm}
          }
          return draft;
        }
        case SIGN_UP.GET.SUCCESS:
          draft.signupForm = { ...state.signupForm, ...payload };
          break;
        case SIGN_UP.POST.SUCCESS:
          draft.cognitoUser = payload;
          break;
        case SIGN_OUT.POST.SUCCESS:
          return initialState;
        case CURRENT_AUTH_USER.GET.FAILURE:
          draft.cognitoUser = {} as ICognitoUser;
          draft.isAuthenticated = false;
          break;
        case UPDATE_SIGNUP_DATA:
        case CONFIRM_SIGN_UP.POST.SUCCESS:
          draft.signupForm = { ...state.signupForm, ...payload };
          draft.signinForm = { ...state.signinForm, redirectFromConfirmSignup: true}
          break;
        case CONFIRM_SIGN_UP.POST.FAILURE:
          draft.signupForm = { ...state.signupForm, verificationError: true };
          break;
        case FORGOT_PASSWORD.POST.SUCCESS:
          // nothing to do
          break;
        case FORGOT_PASSWORD_CONFIRMATION.POST.SUCCESS:
          // nothign to do
          break;
        default:
          return draft;
      }
    });

  export default authReducer;
