import { all, put, takeLatest } from 'redux-saga/effects';
import { API, Auth } from 'aws-amplify';
import * as Alert from 'utils/Alerts';
import { createLeagueMutation, joinLeagueMutation, leaveLeagueMutation} from 'api/graphql/mutations';
import { globalNavigate } from 'utils/global-history';
import { AppRoutes } from 'config/AppRoutes';
import { GAMEWEEK, H2H_DETAILS, JOIN_LEAGUE, LEAGUE, LEAVE_LEAGUE, PUBLIC_LEAGUES, USERS_LEAGUES, createLeague, getH2HDetails, getNextGameWeek, getPublicLeagues, getUsersLeagues, joinLeague, leaveLeague } from 'core/actions/LeagueActions';
import { getH2HDetailsMutation, getNextGameWeekQuery, getPublicLeaguesQuery, getUsersLeaguesQuery } from 'api/graphql/queries';
import { addUserLeague, removeUserLeague } from 'core/actions/UserActions';
import { CompetitionType, LeagueType } from 'core/models/Models';


export function* createLeagueSaga({ payload: { league } }) {
  const competitionName = league.competitionType == CompetitionType.LEAGUE ? 'League'  : 'H2H';
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: createLeagueMutation({...league, createdBy: cognitoId})
      }
    };

    const response = yield API.post('LeagueAPI', '/graphql?query', requestInfo);
    const isRequestSuccessful = response?.data?.createLeague?.isRequestSuccessful;
    if (!isRequestSuccessful) {
      throw new Error(response?.errors[0]?.message) // TODO custom error here
    }
    const newLeague = response?.data?.createLeague?.league;

    const updatedLeague = {
      ...newLeague,
      leagueType: league.leagueType,
      competitionType: league.competitionType
    }
    yield put(createLeague.success(updatedLeague));
    yield put(addUserLeague(updatedLeague))
    yield Alert.setSuccessAlert(`${competitionName} successfully created`);
    globalNavigate(AppRoutes.matches.link);
  } catch (e: any) {
    console.log(e);
    yield put(createLeague.failure(e));
    yield Alert.setErrorAlert(e.message);
  }
}


export function* getPublicLeaguesSaga() {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: getPublicLeaguesQuery()
      }
    };

    const response = yield API.post('LeagueAPI', '/graphql?query', requestInfo);
    const errors = response?.errors;
    if (errors) {
        throw Error(errors[0])
    }
    const publicLeagues = response?.data?.publicLeagues;
    yield put(getPublicLeagues.success(publicLeagues));
  } catch (e: any) {
    console.log(e);
    yield put(getPublicLeagues.failure(e));
    yield Alert.setErrorAlert('An error occurred while getting public leagues');
  }
}


export function* getUsersLeaguesSaga() {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: getUsersLeaguesQuery(cognitoId)
      }
    };

    const response = yield API.post('LeagueAPI', '/graphql?query', requestInfo);
    const errors = response?.errors;
    if (errors) {
        throw Error(errors[0])
    }
    yield put(getUsersLeagues.success(response?.data?.usersLeagues))
  } catch (e: any) {
    console.log(e);
    yield put(getUsersLeagues.failure(e));
    yield Alert.setErrorAlert("An error occurred while getting user's leagues");
  }
}


export function* getNextGameWeekSaga() {
  try {

    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: getNextGameWeekQuery()
      }
    };

    const response = yield API.post('LeagueAPI', '/graphql?query', requestInfo);

    yield put(getNextGameWeek.success(response?.data?.nextGameWeek));

  } catch (e: any) {
    console.log(e);
    yield put(getNextGameWeek.failure(e));
    yield Alert.setErrorAlert('An error occurred while getting current gameweek. Please set it manually');
  }
}


export function* joinLeagueSaga({ payload: { input } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: joinLeagueMutation(input?.leagueId, input.leagueType, input.competitionType, cognitoId, input?.entryCode)
      }
    };

    const response = yield API.post('LeagueAPI', '/graphql?query', requestInfo);
    const isRequestSuccessful = response?.data?.joinLeague?.isRequestSuccessful;
    if (!isRequestSuccessful) {
      throw new Error(response?.errors[0]?.message) // TODO custom error here
    }
    let updatedLeague = response?.data?.joinLeague?.league;
    if (updatedLeague) {
      updatedLeague = {
        ...updatedLeague,
        leagueType: input.leagueType,
        competitionType: input.competitionType
      }
      yield put(joinLeague.success(updatedLeague));
      yield put(addUserLeague(updatedLeague))
      yield Alert.setSuccessAlert("Successfully joined league");
    }
    else throw new Error("Could not join league")
  } catch (e: any) {
    console.log(e);
    yield put(joinLeague.failure(e));
    yield Alert.setErrorAlert(e.message);
  }
}


export function* leaveLeagueSaga({ payload: { leagueId, leagueType, competitionType } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: leaveLeagueMutation(leagueId, leagueType, competitionType, cognitoId)
      }
    };

    const response = yield API.post('LeagueAPI', '/graphql?query', requestInfo);
    const isRequestSuccessful = response?.data?.leaveLeague?.isRequestSuccessful;
    if (!isRequestSuccessful) {
      throw new Error(response?.errors[0]?.message) // TODO custom error here
    }
    let updatedLeague = response?.data?.leaveLeague?.league;
    if (updatedLeague) {
      updatedLeague = {
        ...updatedLeague,
        leagueType: leagueType,
        competitionType: competitionType
      }
      yield put(leaveLeague.success(updatedLeague));
      yield put(removeUserLeague(updatedLeague))
      yield Alert.setSuccessAlert("Successfully left league");
    }
    else throw new Error("Could not leave league")
  } catch (e: any) {
    console.log(e);
    yield put(joinLeague.failure(e));
    yield Alert.setErrorAlert('Could not leave league. Please try again later.');
  }
}


export function* getH2HDetailsSaga({ payload: { leagueId, leagueType } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: getH2HDetailsMutation(leagueId, cognitoId)
      }
    };

    const response = yield API.post('LeagueAPI', '/graphql?query', requestInfo);
    // if (response.data.h2hDetails != '') {
      yield put(getH2HDetails.success({
        leagueId: leagueId,
        leagueType: leagueType,
        opponentName: response.data.h2hDetails
      }))
    // }

  } catch (e: any) {
    console.log(e);
    yield put(getH2HDetails.failure(e));
  }
}

function* leagueSaga() {
  yield all([
    takeLatest(LEAGUE.POST.REQUEST, createLeagueSaga),
    takeLatest(GAMEWEEK.GET.REQUEST, getNextGameWeekSaga),
    takeLatest(PUBLIC_LEAGUES.GET.REQUEST, getPublicLeaguesSaga),
    takeLatest(JOIN_LEAGUE.POST.REQUEST, joinLeagueSaga),
    takeLatest(LEAVE_LEAGUE.POST.REQUEST, leaveLeagueSaga),
    takeLatest(USERS_LEAGUES.GET.REQUEST, getUsersLeaguesSaga),
    takeLatest(H2H_DETAILS.GET.REQUEST, getH2HDetailsSaga),
  ])
}

export default leagueSaga;
