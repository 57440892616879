import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  requestDialogContainer: {
    borderRadius: '0px',
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: theme_fpl.palette.common.black,
    padding: theme_fpl.spacing(4, 4, 4, 4)
  },
  requestDialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifySelf: 'center',
    margin: theme_fpl.spacing(4, 0, 4, 0)
  },
  titleWrapper: {
    marginBottom: 20
  },
  button: {
    marginTop: '4em',
    marginBottom: '3em',
    width: '100%',
    '& > button': {
      width: '100%',
      padding: 15
    }
  },
  inputIcon: {
    color: theme_fpl.palette.common.white,
  },
  sendRequestButton: {
    ...theme_fpl.typography.button,
    backgroundColor: theme_fpl.palette.primary.main,
    color: theme_fpl.palette.common.white,
    width: '100%',
    height: '3.5rem',
    borderRadius: '0.188rem',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme_fpl.palette.primary.light
    }
  },
  sendRequestButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px'
  },
  requestInputField: {
    width: '28.571rem',
    borderRadius: '0px'
  },
  requestInputFieldProps: {
    height: '3.571rem',
    backgroundColor: theme_fpl.palette.common.white,
    color: theme_fpl.palette.common.black,
    padding: '0rem',
    textAlign: 'center'
  },
  requestDialogTitle: {
    fontSize: '24px',
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme_fpl.palette.common.white,
  },
  requestDialogSubtitle: {
    color: theme_fpl.palette.common.white,
    ...theme_fpl.typography.subtitle2,
    textAlign: 'center',
    marginTop: 10
  },
  inputLabel: {
    ...theme_fpl.typography.body1,
    marginLeft: '1rem',
    '&:hover': {
      cursor: 'pointer'
    },
    alignSelf: 'flex-start'
  }
}));
