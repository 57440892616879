import React, { useEffect, useState } from 'react';
import { useStyles } from './styles/WalletAndTeam.styles';
import Input from 'components/Shared/Input/Input';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid, Icon, IconButton } from '@mui/material';
import { FaQuestion } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import TeamID from './TeamID';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import { useTransition, animated } from 'react-spring';
import { signOutUser } from 'core/actions/AuthActions';
import { useDispatch } from 'react-redux';


interface Props {
  handleSaveTeamId: (teamName: string) => void;
  isMobile: boolean;
  isLoading: boolean;
  error: boolean;
}

const WalletAndTeam: React.FC<Props> = ({handleSaveTeamId, isMobile, isLoading, error }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [teamId, setTeamId] = useState('');
  const dispatch = useDispatch();
  const [isTeamIDHelpShown, setIsTeamIDHelpShown] = useState(false);

  const toggleTemIDHelp = () => {
    setIsTeamIDHelpShown((prevIsShown) => !prevIsShown);
  };


  const handlePressEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setTeamId(value);
  };

  const hasError = () =>
    !teamId.length

  const transitions = useTransition(isTeamIDHelpShown, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 'auto' },
    leave: { opacity: 0, height: 0 },
    config: { duration: 200}
  });

  const onLogout = () => {
    dispatch(signOutUser.request());
  }


  return (
    <BoxedComponent paddingRight='1.5em' paddingLeft='1.5em'>
      <Grid className={classes.innerContainer}>
        <Grid className={classes.tapAndPlay}>
          Tap and play now!
        </Grid>
        <Grid className={classes.enterTeam}>
          Enter team
        </Grid>
        <Grid className={classes.inputContainer}>
          <Input
            className={classes.input}
            name='teamId'
            value={teamId}
            onChange={handleInputChange}
            hasError={false}
            disabled={isLoading}
            isRequired={true}
            onKeyUp={handlePressEnter}
            placeholder='Enter your team ID'
            type='number'
          />
        </Grid>
        <Grid className={classes.helpContainer}>
          <FaQuestion className={classes.questionIcon} onClick={toggleTemIDHelp}/>
          <Grid className={classes.helpText} onClick={toggleTemIDHelp}>
            how to find team id
          </Grid>
        </Grid>
        {transitions((styles, item) =>
        item && (
          <animated.div style={styles}>
           <TeamID isMobile={isMobile}/>
          </animated.div>
        )
        )}
        <Grid className={classes.button}>
          <LoadingButton
            onClick={() => handleSaveTeamId(teamId)}
            loading={isLoading}
            variant='contained'
            className={classes.submitButton}
            disabled={hasError()}>
            confirm
          </LoadingButton>

          <Button
             className={classes.logoutButton}
             onClick={onLogout}
            >
              Log out
          </Button>
        </Grid>

      </Grid>
    </BoxedComponent>
  );
};

export default WalletAndTeam;


