import React, { useEffect, useRef, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import theme_fpl from 'utils/Theme';
import { useStyles } from "./FPLTableDesktop.styles";
import images from "assets/images";
import { CompetitionType, ICognitoUser, ILeague, IUser, IUserLeague, LeagueType } from "core/models/Models";
import DetailPanel from "./DetailPanel";
import { useDispatch } from "react-redux";
import { H2H_DETAILS, getH2HDetails } from "core/actions/LeagueActions";
import { faChevronRight, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderPopup from "components/Shared/Dialog/LoaderPopup";
import { useSelector } from "react-redux";
import { getLoadingStatusSelector } from "core/selectors/LoadingSelectors";


interface IProps {
  title: string;
  pageSize?: number;
  user: IUser;
  cognitoUser: ICognitoUser;
  leagues: ILeague[] | IUserLeague[];
  competitionType: CompetitionType;
  disabledLeagueIds?: any[];
  isMobile: boolean;
  onJoinLeague?: (leagueId: string) => void;
  onLeaveLeague?: (leagueId: string, leagueType: LeagueType) => void;
}


const formatRows = (leagues, competitionType, cognitoUser) =>
  leagues.map((league) => {
    return {
      ...league,
      name: league.leagueName,
      duration: `${league.endGameWeek - league.startGameWeek + 1}GW`,
      entryFee: `${league.entryPrice} ₮`,
      players: (league?.maxPlayers || 0)> 0 ? `${league.playersNumber}/${league.maxPlayers}` : league?.playersNumber,
      prize: `${league?.fund || 0} ₮`,
      competitionType: competitionType,
      entryCode: (league.leagueType == LeagueType.PRIVATE && league.createdBy == cognitoUser.attributes.sub) ? league.leagueId : '',
  }}
  )


export default function FPLTableDesktop({title, isMobile, pageSize=5, user, leagues, cognitoUser, disabledLeagueIds, competitionType, onJoinLeague, onLeaveLeague}: IProps) {


  const classes = useStyles();

  const rows = formatRows(leagues, competitionType, cognitoUser);

  const dispatch = useDispatch();

  const tableRef = useRef<any>(null)

  const [expandedRowData, setExpandedRowData] = useState(null);

  const isLoading = useSelector(getLoadingStatusSelector(H2H_DETAILS.GET.REQUEST));

  const isButtonDisabled = (rowData) =>
    onJoinLeague ?
    ((user.walletBalance || 0) < rowData.entryFee) ||
     (rowData.leagueId != null && disabledLeagueIds != undefined && disabledLeagueIds.includes(rowData?.leagueId))
    :
    false;

    useEffect(() => {
      if (leagues && expandedRowData) {
        onToggleDetailPanel(expandedRowData);
      }
    }, [leagues]);

  const getOpponentTeam = (leagueId: string, leagueType: LeagueType) => {
    dispatch(getH2HDetails.request(leagueId, leagueType));
  }

  const onToggleDetailPanel = (rowData) => {
    if (tableRef?.current && rowData) {
      tableRef.current.onToggleDetailPanel(
          [rowData.tableData.id],
          tableRef.current.props.detailPanel[0].render
      )
    }
  }


  const handleRowClick = (event, rowData, togglePanel) => {
    // Check if the row is already expanded or collapsed
    const isExpanded = rowData.tableData.showDetailPanel;
     if (!isExpanded) {
      if (rowData.competitionType == CompetitionType.H2H && rowData?.opponentName == undefined) {
        getOpponentTeam(rowData.leagueId, rowData.leagueType);
        setExpandedRowData(rowData);
      }
      else onToggleDetailPanel(rowData);
    }
    else {
      onToggleDetailPanel(rowData);
    }
    // }
  };


  return (
    <>
     {rows.length > 0 ? (

      <div className={classes.root}>
        <MaterialTable
          tableRef={tableRef}
          title={title}
          style= {{
            borderRadius: 20,
            backgroundColor: theme_fpl.palette.common.black,
            overflow: 'hidden',
          }}
          columns={[
            {
              title: '', // Empty title for the icon column
              render: () => <FontAwesomeIcon icon={faChevronRight} />, // Replace with your expand icon
              cellStyle: {
                textAlign: 'center', // Center the icon within the cell
              },
              headerStyle: {
                textAlign: 'center', // Center the icon within the column header
              },
            },
            {
              title: 'Name', field: 'name',
              align: 'left',
              headerStyle: {
                color: theme_fpl.palette.common.black,
                fontFamily: theme_fpl.typography.fontFamily,
                fontWeight: 600,
                fontSize: '14px',
                minWidth: 200,
                textAlign: 'left',
                padding: 15,
              },
              cellStyle: {
                minWidth: 200
                },

            },

            { title: 'Duration', field: 'duration', align: 'center'} ,
            { title: 'Fee', field: 'entryFee',  align: 'center'
            },
            { title: 'Players', field: 'players', align: 'center'},
            { title: 'Prize', field: 'prize',
              cellStyle: {
                backgroundColor: theme_fpl.palette.primary.main,
                color: theme_fpl.palette.common.white,
                width: 150,
                minWidth: 150,
              },
              align: 'center',

          },
          ]}
          data={rows}
          // detailPanel={
          //   rowData => (
          //   <DetailPanel
          //     rowData={rowData} isButtonDisabled={isButtonDisabled(rowData)}
          //     onJoinLeague={onJoinLeague}
          //     onLeaveLeague={onLeaveLeague}
          //     />
          // )}
          detailPanel={[
            {
              // icon: () => null,
              render: rowData => (
                <DetailPanel
                  rowData={rowData} isButtonDisabled={isButtonDisabled(rowData)}
                  onJoinLeague={onJoinLeague}
                  onLeaveLeague={onLeaveLeague}
                  isMobile={isMobile}
                  />
              )
            }
          ]}

          onRowClick={handleRowClick}
          options={{
            detailPanelColumnStyle: {
             display: 'none'
            },
            tableLayout: 'auto',
            paging: true,
            pageSize: pageSize,
            actionsColumnIndex: -1, // Set to -1 to hide the default actions column
            emptyRowsWhenPaging: false,   // To avoid having empty rows
            headerStyle: {
              color: theme_fpl.palette.common.white,
              fontFamily: theme_fpl.typography.fontFamily,
              fontWeight: 500,
              fontSize: '14px',
              borderBottom: `6px solid ${theme_fpl.palette.common.black}`,
              borderTop: `4px solid ${theme_fpl.palette.common.black}`,
              textAlign: 'center',
              paddingLeft: 40,
            },
            rowStyle: {
              fontSize: '14px',
              // overflowWrap: 'break-word',
              color: theme_fpl.palette.common.black,
              backgroundColor: theme_fpl.palette.common.white,
              fontFamily: theme_fpl.typography.fontFamily,
              fontWeight: 700,
              borderBottom: `4px solid ${theme_fpl.palette.common.black}`,
              alignContent: 'center'
            },
            showTextRowsSelected: false,
            search: false,
            showTitle: false,
            pageSizeOptions: [],
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '',
            }
          }}
          components={{
            Toolbar: props => (
              <div className={classes.toolbarWrapper}>
                <MTableToolbar {...props} />
                <div className={classes.headerInnerContainer}>
                  <div className={classes.titleText}>
                    {title}
                  </div>
                  <div className={classes.imageContainer}>
                    <img src={images.tableHeader.src} style={{maxHeight: '100px'}}/>
                  </div>
                </div>
              </div>
            ),
          }}
          />
        </div>
    ) : (
      null
    )}
    <LoaderPopup isOpen={isLoading}/>
  </>
  )
}