
export interface IActivity {
  type: string;
  payload: any;
}

export enum ISignupState {
  NewAccount,
  ConfirmEmail,
  WalletAndTeam,
}



export interface ISignupForm {
  teamName: string,
  email: string;
  username?: string;
  userCognitoId?: string;
  password?: string;
  confirmPassword?: string;
  walletAddress?: string;
  teamId?: number;
  code?: string;
  state: ISignupState;
  verificationError : boolean;
}

export interface ISigninForm {
  email: string;
  password?: string;
  verificationCode?: string;
  redirectFromConfirmSignup: boolean;
}


export interface UserLeague {
  leagueId: string;
  finalRank: number;
  competitionType: string;
  prize: number;
}

export interface IUser {
  teamName?: string;
  playerName?: string;
  email?: string;
  teamId?: string;
  walletAddress?: string;
  walletBalance?: number;
  usersLeagues?: UserLeague[];
  usersPrivateLeagues?: UserLeague[];
}


interface IAttributes {
  email: string;
  email_verified?: boolean;
  name?: string;
  sub: string;
}

interface IClient {
  endpoint?: string;
  fetchOptions?: any;
}

interface ICognitoUserSession {
  accessToken?: {
    jwtToken?: string;
    payload?: any;
  };
  idToken?: {
    jwtToken?: string;
    payload?: any;
  };
  refreshToken?: {
    token?: string;
  };
}

interface IPool {
  advancedSecurityDataCollectionFlag?: boolean;
  client?: IClient;
  clientId?: string;
  storage?: any;
  userPoolId?: string;
  wrapRefreshSessionCallback?: () => void;
}

export interface ICognitoUser {
  Session?: string;
  attributes: IAttributes;
  authenticationFlowType?: string;
  client?: IClient;
  keyPrefix?: string;
  pool?: IPool;
  preferredMFA?: string;
  signInUserSession?: ICognitoUserSession;
  storage?: any;
  userDataKey?: string;
  username?: string;
}


export enum LeagueType {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC"
}

export enum CompetitionType {
  LEAGUE = "LEAGUE",
  H2H = "H2H"
}


export interface ILeague {
  leagueId?: string;
  leagueName: string;
  entryPrice: number;
  fund?: number;
  playersNumber?: number;
  winnersNumber: number;
  prizeDistribution: number[];
  startGameWeek: number;
  endGameWeek: number;
  leagueType: LeagueType,
  maxPlayers: number;
  entryCode?: string;
  createdBy?: string;
  competitionType: CompetitionType;
  opponentName?: string;
}


export interface IUserLeague {
  leagueId: string;
  leagueName: string;
  entryPrice?: number;
  fund?: number;
  playersNumber?: number;
  winnersNumber?: number;
  prizeDistribution?: number[];
  startGameWeek: number;
  endGameWeek: number;
  leagueType: LeagueType,
  maxPlayers?: number;
  entryCode?: string;
  createdBy?: string;
  competitionType: CompetitionType;
  opponentName?: string;
  prize?: number;
  rank?: number;
}


interface ITeamStats {
  played: number;
  win: number;
  draw: number;
  lose: number;
}

export interface ITeam {
  rank: number;
  name: string;
  logo: string;
  points: number;
  goalsDiff: number;
  all: ITeamStats;
  home?: ITeamStats;
  away?: ITeamStats;
}


export interface IGameWeek {
  id: number;
  name: string;
  deadlineTime: string;
}

