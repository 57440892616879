import { produce } from 'immer';
import { IActivity, ITeam, IUser } from 'core/models/Models';
import { RESET_USER_STATE, USER, WITHDRAW } from 'core/actions/UserActions';
import { IUsersState } from './UserReducer';
import { STANDINGS } from 'core/actions/NewsActions';

export interface INewState {
  standings: ITeam[];
}

const initialState: INewState = {
  standings: [] as ITeam[]
};


const newsReducer = (state = initialState, action: IActivity): INewState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case STANDINGS.GET.SUCCESS:
        const teamData = payload.response[0].league.standings[0];
        const teamList: ITeam[] = teamData.map((teamDataItem: any) => {

          const teamStats = {
            played: teamDataItem.all.played,
            win: teamDataItem.all.win,
            draw: teamDataItem.all.draw,
            lose: teamDataItem.all.lose,
          };

          return {
            rank: teamDataItem.rank,
            name: teamDataItem.team.name,
            logo: teamDataItem.team.logo,
            points: teamDataItem.points,
            goalsDiff: teamDataItem.goalsDiff,
            all: teamStats,
          };
        });
        draft.standings = teamList;
      break;
      default:
        return draft;
    }
  });

export default newsReducer;
