import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import TranslationSR from './locals/sr/TranslationSR.json';

const resources = {

}
// const resources = {
//   sr: {
//     translation: TranslationSR
//   }
// };

i18n.use(initReactI18next).init({
  returnNull: false,
  resources,
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
