import { makeStyles } from "@mui/styles";
import theme_fpl from "utils/Theme";

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'center',
    marginTop: '40px',
	},
  chip: {
    flex: 1,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,
    padding: '10px',
    paddingRight: '1em',
    paddingLeft: '1em',
    textAlign: 'center',
    color: theme_fpl.palette.common.white,
  },
  rightChip: {
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '20px',
  },
  leftChip: {
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '20px',
    marginRight: -10,
    zIndex: 1,
  },
  selectedChip: {
    backgroundColor: theme_fpl.palette.primary.main,
  },
  noteSlectedChip: {
    backgroundColor: theme_fpl.palette.grey[600],
    // backgroundColor: 'transparent',
    //
  }
}));

export default useStyles;
