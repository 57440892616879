import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingPage = () => (
  <Box display='flex' justifyContent='center' alignItems='center' height='100vh' width='100vw'>
    <CircularProgress size='3rem' color='info' />
  </Box>
);

export default LoadingPage;
