import { DELETE, GET, POST, PUT } from 'utils/Constants';
import { action, createRequestTypes } from 'utils/ReduxHelpers';


export const SEND_WITHDRAW_NOTIFICATION = createRequestTypes('SEND_WITHDRAW_NOTIFICATION', [POST]);
export const SEND_SUPPORT_ISSUE = createRequestTypes('SEND_SUPPORT_ISSUE', [POST]);
export const RESET_IS_ISSUE_SENT = 'RESET_IS_ISSUE_SENT';

export const resetIsIssueSent = () => action(RESET_IS_ISSUE_SENT);

export const sendWithdrawNotification = {
  request: (address: string, amount: string) => action(SEND_WITHDRAW_NOTIFICATION.POST.REQUEST, {address, amount}),
  success: (payload: any) => action(SEND_WITHDRAW_NOTIFICATION.POST.SUCCESS, payload),
  failure: (error: any) => action(SEND_WITHDRAW_NOTIFICATION.POST.FAILURE, error)
}

export const sendSupportIssue = {
  request: (issue: string) => action(SEND_SUPPORT_ISSUE.POST.REQUEST, {issue}),
  success: (payload: any) => action(SEND_SUPPORT_ISSUE.POST.SUCCESS, payload),
  failure: (error: any) => action(SEND_SUPPORT_ISSUE.POST.FAILURE, error)
}