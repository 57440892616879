import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles({
  imagePopupOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', /* Semi-transparent black */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, /* Adjust the z-index as needed */
  },
});
