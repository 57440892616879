import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import useStyles from './LeaguesFilter.styles';
import { Box, Switch } from '@mui/material';


interface IProps {
  onFilter: (value: number) => void;
}


const ButtonType = {
  Low: 'Low',
  Medium: 'Medium',
  High: 'High',
};


const lowNumbers = [2, 3, 5];
const mediumNumbers = [10, 20, 30, 50];
const highNumbers = [100, 200, 300, 400, 500];

const LeaguesFilter = ({onFilter}: IProps) => {
  const classes = useStyles();
  const [selectedButton, setSelectedButton] = useState(null);
  const [currentNumbers, setCurrentNumbers] = useState<Array<number>>([]);
  const [isPanelOpen, setShowPanel] = useState(false);

  const [selectedValue, setSelectedValue] = useState(0);

  const handleSwitchToggle = (number) => (event) => {
    let newNumber = 0;
    if (selectedValue == number) {
      setSelectedValue(0);
    } else {
      setSelectedValue(number);
      newNumber = number;
    }
    onFilter(newNumber);
  };

  const handleButtonClick = (buttonType) => {
    if (selectedButton == buttonType) {
      setShowPanel(!isPanelOpen);
    } else {
      setSelectedButton((prevButton) => (prevButton === buttonType ? null : buttonType));
      if (buttonType == ButtonType.Low) {
        setCurrentNumbers(lowNumbers);
      } else if (buttonType == ButtonType.Medium) {
        setCurrentNumbers(mediumNumbers);
      } else {
        setCurrentNumbers(highNumbers);
      }
      setShowPanel(true);
    }
  };


  return (
    <div>
      <div className={classes.container}>
        {Object.values(ButtonType).map((buttonType) => (
          <Box key={buttonType} display="flex" alignItems="center">
            <span className={classes.buttonLabel}
              onClick={() => handleButtonClick(buttonType)}
            >
              {buttonType}</span>
            <div
              className={classes.button}
              onClick={() => handleButtonClick(buttonType)}
            >
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ fontSize: '12px' }}
              />
            </div>
          </Box>
        ))}
      </div>
      {isPanelOpen && (
        <div className={`${classes.panel} ${isPanelOpen ? classes.showPanel : ''}`}>
          {currentNumbers.map((number, index) => (
            <div key={index} className={classes.numberContainer}>
              <span className={classes.number}>{number} ₮</span>
              <Switch
                checked={number == selectedValue}
                onChange={handleSwitchToggle(number)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LeaguesFilter;

