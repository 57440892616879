import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles({
  dialogContainer: {
    background: theme_fpl.palette.common.black,
    padding: '1.5em',
    display: 'flex',
  },
  cancelButton: {
    borderRadius: 20,
    height: 40,
    fontSize: '14px',
    backgroundColor: 'transparent',
    border: `1px solid ${theme_fpl.palette.secondary.main}`,
    color: theme_fpl.palette.common.white,
    flex: 1
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  paragraph: {
    marginBottom: 20,
    color: theme_fpl.palette.common.white,
  },
  inputContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
});
