import React, { useState } from 'react';
import { useStyles } from './Account.styles';
import { Button, Grid } from '@mui/material';
import { FaCopy, FaQuestion, FaSync, FaTshirt, FaUser, FaWallet } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import WalletAddress from 'components/WalletAddress/WalletAddress';
import theme_fpl from 'utils/Theme';
import { IUser } from 'core/models/Models';
import { refreshWalletBalance, withdraw } from 'core/actions/UserActions';
import { useDispatch } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';
import { AppRoutes } from 'config/AppRoutes';
import { globalNavigate } from 'utils/global-history';

interface Props {
  user: IUser;
  isMobile: boolean;
}

const Account: React.FC<Props> = ({user, isMobile }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleNavigateToHome = () => {
    globalNavigate(AppRoutes.home.link);
  }

  return (
    <BoxedComponent>
      <Grid className={classes.innerContainer}>
        <Grid className={classes.labelIconContainer}>
          <FaTshirt color={theme_fpl.palette.primary.main} size={40}/>
          <Grid className={classes.text}>
            {user?.teamName}
          </Grid>
        </Grid>
        <Grid className={classes.labelIconContainer}>
          <FaWallet color={theme_fpl.palette.primary.main} size={40}/>
          <Grid className={classes.text}>
            {user?.walletBalance} ₮
          </Grid>
        </Grid>
        <Grid className={classes.buttonContainer}>
          <Button className={classes.button} onClick={handleNavigateToHome}>
              My Account
          </Button>
        </Grid>
      </Grid>
    </BoxedComponent>
  );
};

export default Account;
