import * as Alert from 'utils/Alerts';
import { Auth, API } from "aws-amplify";
import { put, all, takeLatest, call } from "redux-saga/effects";
import { SEND_SUPPORT_ISSUE, SEND_WITHDRAW_NOTIFICATION, sendSupportIssue, sendWithdrawNotification } from "core/actions/EmailActions";

export function* sendWithdrawNotificationSaga({ payload: { address, amount } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const email = user?.attributes?.email;
    const response = yield API.post('EmailAPI', '/send-withdraw-email', {
      body: {
        address,
        amount,
        email,
      },
    })

    yield put(sendWithdrawNotification.success({}));
    yield Alert.setSuccessAlert('A withdraw request successfully sent');
  } catch (e: any) {
    console.log(e);
    yield put(sendWithdrawNotification.failure(e));
    yield Alert.setErrorAlert('An error occurred while sending withdraw request. Please try again');
  }
}


export function* sendSupportIssueSaga({ payload: { issue } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const email = user?.attributes?.email;
    const response = yield API.post('EmailAPI', '/send-issue-email', {
      body: {
        issue,
        email,
      },
    })

    yield put(sendSupportIssue.success({}));
  } catch (e: any) {
    console.log(e);
    yield put(sendSupportIssue.failure(e));
    yield Alert.setErrorAlert('An error occurred while sending the issue. Please try again');
  }
}



function* emailSaga() {
  yield all([
    takeLatest(SEND_WITHDRAW_NOTIFICATION.POST.REQUEST, sendWithdrawNotificationSaga),
    takeLatest(SEND_SUPPORT_ISSUE.POST.REQUEST, sendSupportIssueSaga),
  ])
}

export default emailSaga;