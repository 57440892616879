import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme_fpl.palette.common.black,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    paddingLeft: '3rem',
    paddingTop: '1em',
    paddingRight: '3rem',
    // minWidth: '350px',
    borderRadius: '25px',
    boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.5)',
    // marginTop: '-20px'
  },
  textInputContainer: {
    marginTop: '1em',
  },
  button: {
    marginTop: '1em',
    marginBottom: '1em',
    width: '100%',
    '& > button': {
      width: '100%',
      padding: 15
    }
  },
  input: {
    '& > div > div': {
      backgroundColor: theme_fpl.palette.grey[600],
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme_fpl.palette.primary.main,
    }
  },
   divider: {
      // Theme Color, or use css color in quote
      background: theme_fpl.palette.primary.main,
  },
  forgotPasswordWrapper: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    width: '100%',
    paddingBottom: '20px',
    display: 'flex',
    borderBottomColor: theme_fpl.palette.primary.main,
    alignText: 'center',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  forgotPasswordLink: {
    fontSize: '18px',
    fontWeight: 500,
    color: theme_fpl.palette.common.white,
    textDecoration: 'none',
    cursor: 'pointer',
    marginTop: 10,
    fontFamily: theme_fpl.palette.text.primary,
  },
  noAccount: {
    marginRight: '10px',
  },
  snackbar: {
    width: '100%',
    '& > * + *': {
      marginTop: 20
    }
  },
  signUpLink: {
    textTransform: 'uppercase',
    color: theme_fpl.palette.secondary.main,
    display: 'flex',
    flexDiretion: 'row',
    justifyItems: 'center',
    alignItems: 'center',
  },
  signupUpContainer: {
    fontSize: '16px',
    marginTop: '2em',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    justifyItems: 'center',
    alignItems: 'center',
    marginBottom: '2em'
  },
  progress: {
    width: '100%',
    textAlign: 'center'
  },
  errorMessage: {
    width: '100%',
    color: theme_fpl.palette.primary.main,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '16px'
  },
  inputIcon: {
    color: theme_fpl.palette.common.white,
  },
  signUpIcon: {
    fontSize: 25,
    padding: 5,
    color:  theme_fpl.palette.common.black,
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: theme_fpl.palette.common.white,
  },
  signUpText: {
    marginRight: 20,
    fontWeight: 550,
  },
  submitButton: {
    height: 40,
    borderTopLeftRadius: '25px',
    borderBottomRightRadius: '25px',
    backgroundColor: theme_fpl.palette.primary.main,
    '&:disabled': {
      backgroundColor: theme_fpl.palette.grey[600],
      color: theme_fpl.palette.grey[500],
      cursor: 'not-allowed',
      border: `1px solid ${theme_fpl.palette.primary.main}`,
    },
  }
}));
