import { produce } from 'immer';
import { IActivity, IGameWeek, ITeam, IUser } from 'core/models/Models';
import { GAME_WEEKS } from 'core/actions/CommonsActions';

export interface IFPLState {
  currentGameWeek: IGameWeek;
  nextGameWeek: IGameWeek;
  firstEligibleGameWeek: IGameWeek;
}

const initialState: IFPLState = {
  currentGameWeek: {} as IGameWeek,
  nextGameWeek: {} as IGameWeek,
  firstEligibleGameWeek: {} as IGameWeek,
};


const commonsReducer = (state = initialState, action: IActivity): IFPLState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case GAME_WEEKS.GET.SUCCESS:
        draft.currentGameWeek = payload.currentGameWeek;
        draft.nextGameWeek = payload.nextGameWeek;
        draft.firstEligibleGameWeek = payload.fistEligibleGameWeek;
        break;
      default:
        return draft;
    }
  });

export default commonsReducer;
