import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid } from '@mui/material';
import * as yup from 'yup';
import { isEmailValid, passwordRegex } from 'utils/Helpers';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { FaAngleDown } from 'react-icons/fa';
import Input from 'components/Shared/Input/Input';
import { useStyles } from './AccountSettings.styles';

interface Props {
  isMobile: boolean;
  isLoadingEmail: boolean;
  isCodeSent: boolean;
  previousEmail: string;
  isInitiallyExpanded: boolean;
  onUpdateEmail: (data: any) => void;
  onCodeSubmit: (email: string, code: string) => void;
}


const passwordSchema = yup.object({
  password: yup.string().matches(passwordRegex).required('Enter a valid password')
});


const EmailSettings: React.FC<Props> = ({ isMobile, isLoadingEmail, isCodeSent, previousEmail, isInitiallyExpanded, onUpdateEmail, onCodeSubmit }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [email, setEmail] = useState(previousEmail);
  const [code, setCode] = useState('');

  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

  const handleAccordionToggle = () => {
    setIsExpanded(!isExpanded);
  };


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handlePressEnterEmail = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    if (!isCodeSent) {
      onUpdateEmail({ email: email });
    } else {
      onCodeSubmit(email, code);
      setCode('');
    }
  }


  const hasError = () =>
    (isCodeSent && code.length == 0) || (!isCodeSent && (!email || email == previousEmail || !isEmailValid(email)))


  const hanleCancelEmailUpdate = () => {
    setCode('');
    setEmail('');
    setIsExpanded(false);
  }


  const renderEmailForm = () =>
    <>
      <Grid className={classes.textInputContainer}>
        {!isCodeSent ? (
          <>
            <Input
              className={classes.input}
              name='email'
              value={email}
              onChange={handleEmailChange}
              hasError={false}
              disabled={isLoadingEmail}
              helperText='Invalid email'
              isRequired={true}
              onKeyUp={handlePressEnterEmail}
              placeholder='Email'
            />
            <Grid className={classes.inputSubtext}>
              We will send you a confirmation email to this address
            </Grid>
          </>
          ) : (
            <Input
              className={classes.input}
              name='email'
              value={code}
              onChange={handleCodeChange}
              hasError={false}
              disabled={isLoadingEmail}
              helperText='Invalid email'
              isRequired={true}
              onKeyUp={handlePressEnterEmail}
              placeholder='Code'
          />
        )}
      </Grid>
      <Grid className={classes.button}>
        <Grid className={classes.buttons}>
        <LoadingButton
          color='secondary'
          onClick={handleSubmit}
          loading={isLoadingEmail}
          variant='contained'
          style={{borderRadius: 20, height: 40, fontSize: '14px',  flex: 1 }}
          disabled={hasError()}>
          Confirm
        </LoadingButton>
        <Button
          className={classes.cancelButton}
          onClick={hanleCancelEmailUpdate}
        >
          Cancel
        </Button>
      </Grid>
      </Grid>
    </>


  return (
    <Accordion className={classes.accordion} expanded={isExpanded} onChange={handleAccordionToggle}>
      <AccordionSummary expandIcon={<FaAngleDown className={classes.inputIcon} />} className={classes.accordionSummary}>
        <span>Update Email</span>
      </AccordionSummary>
      <AccordionDetails>
        {renderEmailForm()}
      </AccordionDetails>
    </Accordion>
  );
}

export default EmailSettings;
