import React, { useEffect, useState } from 'react';
import { useStyles } from './WalletAddress.styles';
import { Grid } from '@mui/material';
import { FaCheck, FaCopy, FaQuestion, FaWallet } from 'react-icons/fa';
import * as yup from 'yup';
import { passwordRegex } from 'utils/Helpers';
import { useTranslation } from 'react-i18next';
import theme_fpl from 'utils/Theme';

interface Props {
  walletAddress?: string;
  isMobile: boolean;
}


const WalletAddress: React.FC<Props> = ({ isMobile, walletAddress }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isShowNotification, setIsShowNotification] = useState(false);


  const onCopyToClipboardClicked = () => {
    navigator.clipboard.writeText(walletAddress || '')
      .then(() => {
        console.log('Text copied to clipboard:', walletAddress);
        setIsShowNotification(true);
        setTimeout(() => setIsShowNotification(false), 2000); // Hide the notification after 2 seconds
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
        setIsShowNotification(false);
      });
  };


  return (
    <Grid>
      <Grid className={classes.walletAddressContainer}>
        <FaWallet color={theme_fpl.palette.primary.main} size={30}/>
        <Grid className={classes.walletText} style={{fontSize: isMobile ? '14px' : '' }}>
          {walletAddress}
        </Grid>
        <Grid className={classes.walletSidebar}>
          <Grid className={classes.sidebarContainer}>
            {isShowNotification===false ? (
              <FaCopy className={classes.questionIcon} onClick={onCopyToClipboardClicked}/>
            ) : (
            <FaCheck className={classes.questionIcon}/>
            )}
          </Grid>
        </Grid>
      </Grid>
   </Grid>
  )
}

export default WalletAddress;
