import React, { useState, useRef, useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import useStyles from './styles/CounterComponent.styles';
import clsx from 'clsx';


interface IProps {
  currentNumber: number;
  setCurrentNumber: (number) => void
}

const CounterComponent = ({currentNumber, setCurrentNumber}: IProps) => {
  const [numbers, setNumbers] = useState([1, 2, 3, 4]);
  const [isLoading, setIsLoading] = useState(false);

  const listRef = useRef<HTMLDivElement | null>(null);

  const classes = useStyles();

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = listRef.current!;
      const threshold = 1; // Adjust this threshold if needed
      if (scrollHeight - scrollTop <= clientHeight + threshold && !isLoading) {
        setIsLoading(true);
        setTimeout(() => {
          const newNumbers = generateNumbers(numbers.length+1, numbers.length + 2); // Generate one new number
          setNumbers((prevNumbers) => [...prevNumbers, ...newNumbers]);
          setIsLoading(false);
        }, 1000);
      }
    };

    listRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      listRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [numbers.length, isLoading]);

  const generateNumbers = (start: number, end: number): Array<number> => {
    const newNumbers: Array<number> = [];
    for (let i = start; i < end; i++) {
      newNumbers.push(i);
    }
    return newNumbers;
  };

  return (
    <div
      ref={listRef}
      style={{ height: '100px', overflow: 'auto', paddingBottom: '20px' }}
    >
      {numbers.map((number, index) => (
        <Grid
          key={index}
          container
          direction="row"
          onClick={() => setCurrentNumber(number)}
          className={
            clsx(classes.counterElement, number===currentNumber ? classes.counterElementSelected : classes.counterElementNotSelected)
          }
        >
          <Grid item>{number}</Grid>
        </Grid>
      ))}
      {isLoading &&
       <div className={classes.spinnerContainer}>
          <CircularProgress size={18} />
        </div>
      }
    </div>
  );
};

export default CounterComponent;
