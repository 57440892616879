import { all, put, takeLatest } from 'redux-saga/effects';
import { API, Auth } from 'aws-amplify';
import { getGameWeeksQuery } from 'api/graphql/queries';
import { GAME_WEEKS, getGameWeeks } from 'core/actions/CommonsActions';



export function* getGameWeeksSaga() {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: getGameWeeksQuery()
      }
    };

    const response = yield API.post('CommonsAPI', '/graphql?query', requestInfo);
    const gameWeeks = response?.data?.getGameWeeks;
    yield put(getGameWeeks.success(gameWeeks));
    console.log(gameWeeks);
  } catch (e: any) {
    console.log(e);
    yield put(getGameWeeks.failure(e));
    // yield Alert.setErrorAlert('Could not fetch user');
  }
}

function* commonsSaga() {
  yield all([
    takeLatest(GAME_WEEKS.GET.REQUEST, getGameWeeksSaga),
  ])
}

export default commonsSaga;
