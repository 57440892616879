import React, {useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton} from '@mui/material';
import * as yup from 'yup';
import { passwordRegex } from 'utils/Helpers';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { FaAngleDown, FaEye, FaEyeSlash } from 'react-icons/fa';
import Input from 'components/Shared/Input/Input';
import { useStyles } from './AccountSettings.styles';

interface Props {
  isMobile: boolean;
  isLoadingPassword: boolean;
  isInitiallyExpanded: boolean;
  onUpdatePassword: (oldPassword: string, newPassword: string) => void;
}


const passwordSchema = yup.object({
  password: yup.string().matches(passwordRegex).required('Enter a valid password')
});


const PasswordSettings: React.FC<Props> = ({ isMobile, isLoadingPassword, isInitiallyExpanded, onUpdatePassword }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [passwordData, setSettingsData] = useState({
    password: '',
    oldPassword: '',
    repeatPassword: '',
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [repeatPasswordVisibility, setRepeatPasswordVisibility] = useState(false);
  const [oldPasswordVisibility, setOldPasswordVisibility] = useState(false);

  const [isPasswordExpanded, setIsPasswordExpanded] = useState(isInitiallyExpanded);

  const handlePasswordAccordionToggle = () => {
    setIsPasswordExpanded(!isPasswordExpanded);
  };

  const [errors, setErrors] = useState({
    password: true,
    repeatPassword: false,
  });


  const hasErrorPassword = () =>
    errors.password || errors.repeatPassword || !passwordData.repeatPassword.length || !passwordData.oldPassword.length


  const validatePassword = (passwordValue, repeatPasswordValue) => {

    passwordSchema.isValid({ password: passwordValue }).then((valid) => {
      const repeatPasswordValid = repeatPasswordValue == '' || passwordValue === repeatPasswordValue;
      const passwordValid = valid;
      setErrors({ ...errors, password: !passwordValid, repeatPassword: !repeatPasswordValid });
    });
  }

  const handleInputChange = ({ target: { name, value } }) => {
    setSettingsData({ ...passwordData, [name]: value });

    if (name == 'password' || name == 'repeatPassword') {
      const passwordValue = name === 'password' ? value : passwordData.password;
      const repeatPasswordValue = name === 'repeatPassword' ? value : passwordData.repeatPassword;
      validatePassword(passwordValue, repeatPasswordValue);
    }
    else {
      const newErrors = {...errors};
      newErrors[name] = value.trim() == "";
      setErrors(newErrors);
    }
  };


  const renderOldEyeIcon = (pass: string) => (
    <IconButton className={classes.inputIcon} onClick={() => setOldPasswordVisibility(!oldPasswordVisibility)} size='medium'>
      {oldPasswordVisibility ? <FaEye /> : <FaEyeSlash />}
    </IconButton>
  );

  const renderEyeIcon = (pass: string) => (
    <IconButton className={classes.inputIcon} onClick={() => setPasswordVisibility(!passwordVisibility)} size='medium'>
      {passwordVisibility ? <FaEye /> : <FaEyeSlash />}
    </IconButton>
  );

  const renderRepeatEyeIcon = (pass: string) => (
    <IconButton className={classes.inputIcon} onClick={() => setRepeatPasswordVisibility(!repeatPasswordVisibility)} size='medium'>
      {repeatPasswordVisibility ? <FaEye /> : <FaEyeSlash />}
    </IconButton>
  );


  const handlePressEnterPassword = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleUpdatePassword();
    }
  }

  const handleUpdatePassword = () => {
    onUpdatePassword(passwordData.oldPassword, passwordData.password);
    hanleCancelPasswordUpdate();
  }


  const hanleCancelPasswordUpdate = () => {
    setSettingsData({...passwordData, oldPassword: '', password: '', repeatPassword: ''})
    handlePasswordAccordionToggle();
    setErrors({
      password: true,
      repeatPassword: false,
    });
  }



  const renderPasswordForm = () =>
    <>
      <Grid className={classes.textInputContainer}>
        <Input
          className={classes.input}
          endIcon={renderOldEyeIcon('oldPassword')}
          name='oldPassword'
          value={passwordData.oldPassword}
          onChange={handleInputChange}
          hasError={false}
          disabled={isLoadingPassword}
          helperText='Invalid password'
          isRequired={true}
          type={oldPasswordVisibility ? 'text' : 'password'}
          onKeyUp={handlePressEnterPassword}
          placeholder='Old password'
        />
      </Grid>
      <Grid className={classes.textInputContainer}>
        <Input
          className={classes.input}
          endIcon={renderEyeIcon('password')}
          name='password'
          value={passwordData.password}
          onChange={handleInputChange}
          hasError={false}
          disabled={isLoadingPassword}
          helperText='Invalid password'
          isRequired={true}
          type={passwordVisibility ? 'text' : 'password'}
          onKeyUp={handlePressEnterPassword}
          placeholder='New password'
        />
        <Grid className={classes.inputSubtext}>
          At least 8 characters, one uppercase letter, one number, and one special character (apart from @)
        </Grid>
      </Grid>
      <Grid className={classes.textInputContainer}>
        <Input
          className={classes.input}
          endIcon={renderRepeatEyeIcon('repeatPassword')}
          name='repeatPassword'
          value={passwordData.repeatPassword}
          onChange={handleInputChange}
          hasError={false}
          disabled={isLoadingPassword}
          helperText='Passwords do not match'
          isRequired={true}
          type={repeatPasswordVisibility ? 'text' : 'password'}
          onKeyUp={handlePressEnterPassword}
          placeholder='Repeat password'
        />
        {errors.repeatPassword &&
        <Grid className={classes.inputSubtext}>
          Passwords do not match
        </Grid>
        }
      </Grid>
      <Grid className={classes.buttons}>
        <LoadingButton
          color='secondary'
          onClick={handleUpdatePassword}
          loading={isLoadingPassword}
          variant='contained'
          style={{borderRadius: 20, height: 40, fontSize: '14px', flex: 1 }}
          disabled={hasErrorPassword()}>
          Confirm
        </LoadingButton>
        <Button
          className={classes.cancelButton}
          onClick={hanleCancelPasswordUpdate}
        >
          Cancel
        </Button>
      </Grid>
    </>


  return (
    <Accordion className={classes.accordion} expanded={isPasswordExpanded} onChange={handlePasswordAccordionToggle}>
      <AccordionSummary expandIcon={<FaAngleDown className={classes.inputIcon}/>} className={classes.accordionSummary}>
        <span>Update Password</span>
      </AccordionSummary>
      <AccordionDetails>
        {renderPasswordForm()}
      </AccordionDetails>
    </Accordion>
  );
}

export default PasswordSettings;
