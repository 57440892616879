import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles({
  root: {

  },
  paragraph: {
    textAlign: 'justify',
  },
  helpLink: {
    fontWeight: 600,
    color: theme_fpl.palette.secondary.main
  }
});
