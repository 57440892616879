import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  arrowIcon: {
    cursor: 'pointer',
    color: theme_fpl.palette.grey[500],
    fontSize: 20,
  },
  gameWeekText: {
    margin: '0 20px',
    fontSize: '20px',
    fontWeight: 600,
    color: theme_fpl.palette.secondary.main,
  },
  currentDate: {
    marginTop: 10,
    fontSize: '12px',
    color: theme_fpl.palette.common.white
  },
}));
