import { useStyles } from './Nav.styles';

import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import clsx from 'clsx';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import { Grid } from '@mui/material';
import { signOutUser } from 'core/actions/AuthActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getIsAuthenticatedSelector } from 'core/selectors/AuthSelectors';
import { text } from '@fortawesome/fontawesome-svg-core';
import { faHome, faPlus, faTrophy, faGamepad, faInfoCircle, faQuestionCircle, faSignInAlt, faEllipsisV, faFutbol, faRunning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserSelector } from 'core/selectors/UserSelectors';
import { globalNavigate } from 'utils/global-history';
import { AppRoutes } from 'config/AppRoutes';
import SvgIcon from 'components/Shared/SvgIcon';
import theme_fpl from 'utils/Theme';


const moreData = {
  name: 'More',
  path: '/more'
}


interface IProps {
  isMobile: boolean;
}

export default function Nav({isMobile}: IProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [clickedItem, setClickedItem] = useState('');
  const handleSetItemType = (type) => setClickedItem(type);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const [links, setLinks] = useState<[string, string, any, any][]> ([]);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const user = useSelector(getUserSelector);
  const pathname = window.location.pathname



  const isActiveCreate = (isActive) => {
    // Define an array of URLs that should be considered active
    const activeUrls = ['/create-league', '/create-h2h'];

    return activeUrls.includes(pathname);
  };

  const isActiveDefault = (isActive) => {
    return isActive
  };


  const authenticatedLinks: [string, string, any, any][] = [
    ['Home', '/', 'homeIcon', isActiveDefault],
    ['Create', '/create-league', 'footballIcon', isActiveCreate],
    ['Leagues', '/tournaments', 'leaguesIcon', isActiveDefault],
    ['Matches', '/matches', 'matchesIcon', isActiveDefault],
    ['More', '/more', faEllipsisV, isActiveDefault],
  ];

  const unauthenticatedLinks: [string, string, any][] = [
    ['Login', '/login', faSignInAlt],
    ['Help', '/help', faQuestionCircle],
    ['About', '/about', faInfoCircle],
  ];

  useEffect(() => {
    if (isAuthenticated) {
      setLinks(authenticatedLinks);
    }
    // does not work anyway
    // else {
    //   setLinks(unauthenticatedLinks);
    // }
  }, [isAuthenticated]);



  const getLinkClass = (isActive, isMoreLink) => {
    let selectClass;
    if (!isMoreLink) {
      if (!isMobile) {
        const classLinkDesktop =  isActive ? classes.activeLink : classes.link;
        const borderClass = isActive ? classes.activeLinkWithBorder : classes.linkWithBorder;
        selectClass = clsx(classLinkDesktop, borderClass);
      }
      else {
        selectClass = isActive ? classes.activeLinkMobile : classes.linkMobile;
      }
    }
    else {
      if (!isMobile) {
        const classLinkDesktop =  isActive ? classes.activeMoreLink : classes.moreLink;
        const borderClass = isActive ? classes.activeLinkWithBorder : classes.linkWithBorder;
        selectClass = clsx(classLinkDesktop, borderClass);
      }
      else {
        selectClass =  isActive ? classes.activeMoreLinkMobile : classes.moreLinkMobile;
      }
    }
    return selectClass;
  }


  const toggleMore = () => {
    setIsMoreOpen(!isMoreOpen);
  }

  const handleSignOut = () => {
    dispatch(signOutUser.request());
  };


  const handleOpenNews = () => {
    globalNavigate(AppRoutes.news.link);
  };

  const handleOpenProfile = () => {
    globalNavigate(AppRoutes.profile.link);
  };

  const handleOpenHelp = () => {
    globalNavigate(AppRoutes.help.link);
  };

  const handleOpenSupport = () => {
    globalNavigate(AppRoutes.support.link);
  };



  const dropdownMenuItems = [
    {
      text: 'News',
      enabled: true,
      onclick: handleOpenNews
    },
    {
      text: 'Profile',
      enabled: true,
      onclick: handleOpenProfile
    },
    {
      text: 'Support',
      enabled: true,
      onclick: handleOpenSupport
    },
    {
      text: 'Help',
      enabled: true,
      onclick: handleOpenHelp
    },
    {
      text: 'Logout',
      enabled: true,
      onclick: handleSignOut
    }
  ];

  const renderLinks = () => {

    return (
      <>
      {user?.teamId && (
      <Grid className={classes.wrapper}>
        <Grid className={classes.root}>
          {links.map(([name, path, icon, isActiveFunc]) => (
            <>
            {name!='More' ? (
              <NavLink
                key={path}
                to={path}
                onClick={() => navigate(path)}
                className={({ isActive }) => getLinkClass(isActiveFunc(isActive), false)}>
                  <>
                  {isMobile ? (
                    <>
                      <div className={classes.navIconContainer}>
                      <SvgIcon
                        icon={icon}
                      />
                      </div>
                      <span className={classes.navText}>{name}</span>
                    </>
                    ) : (
                      <span>{name}</span>
                    )}
                  </>
              </NavLink>
            ): (
              <>
                {(isAuthenticated && isMobile) && (
                  <Grid className={classes.linkMobile} onClick={toggleMore}>
                    <FontAwesomeIcon icon={icon} className={classes.moreLinkMobile} />
                    <span className={isMoreOpen ? classes.moreTextActive : classes.moreText}>{name}</span>
                  </Grid>
                )}
                {(isAuthenticated && !isMobile) && (
                  <DropdownMenu
                    button={<Grid className={getLinkClass(false, true)}>More <MoreVertIcon /></Grid>}
                    isMobile={isMobile}
                    items={dropdownMenuItems}
                    onTypedItem={handleSetItemType}
                  />
                )}
              </>
            )}
            </>
          ))}

        </Grid>

        <>
        {isMoreOpen && (
          <Grid className={classes.moreContainer}>
            {dropdownMenuItems.map(({ text, enabled, onclick }, index) => (
              <>
                {enabled && (
                  <span className={classes.moreOption} onClick={onclick}>{text}</span>
                )
                }
              </>
            ))}
          </Grid>
          )}
        </>
        </Grid>
        )}
      </>
    )
  }

  return <div className={classes.root}>{renderLinks()}</div>;
}
