import { useDispatch, useSelector } from 'react-redux';
import { CONFIRM_SIGN_UP, SIGN_UP, confirmSignup, resendConfirmationCode} from 'core/actions/AuthActions';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import { getLoadingStatusSelector } from 'core/selectors/LoadingSelectors';
import { useState, useEffect } from 'react';
import VerificationCode from 'components/SignUp/VerificationCode';
import { getSignupFormSelector } from 'core/selectors/AuthSelectors';


export default function ConfirmSignupContainer() {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;
  const isLoading = useSelector(getLoadingStatusSelector(CONFIRM_SIGN_UP.POST.REQUEST));
  const signupForm = useSelector(getSignupFormSelector);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const onConfirmCode = (email, code) => {
    dispatch(confirmSignup.request({...signupForm, email: email, code: code}));
  }

  const onResendConfitmationCode = (email) => {
    dispatch(resendConfirmationCode.request(email))
  }

  return (
    <ResponsiveContainer>
      <VerificationCode
        isMobile={width < breakpoint}
        onResendConfitmationCode={onResendConfitmationCode}
        onConfirmCode={onConfirmCode}
        isLoading={isLoading}
        email={signupForm?.email}
        verificationError={signupForm.verificationError}
        />
    </ResponsiveContainer>
  )
}