import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid } from '@mui/material';
import { useStyles } from './PopupDialog.styles';

const PopupDialog = ({ isOpen, onClose, message }) => {

  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose} classes={{ paper: classes.requestDialogContainer }}>
      <DialogContent>
        <DialogContentText className={classes.dialogText}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid className={classes.button}>
          <Button
            color='secondary'
            onClick={onClose}
            variant='contained'
            style={{borderRadius: 20, height: 45}}
          >
            Close
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PopupDialog;
