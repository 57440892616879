import { DELETE, GET, POST, PUT } from 'utils/Constants';
import { action, createRequestTypes } from 'utils/ReduxHelpers';


export const STANDINGS = createRequestTypes('STANDINGS', [GET]);


export const getStandings = {
  request: (season: number) => action(STANDINGS.GET.REQUEST, {season}),
  success: (payload: any) => action(STANDINGS.GET.SUCCESS, payload),
  failure: (error: any) => action(STANDINGS.GET.FAILURE, error)
}

