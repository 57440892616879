import { useDispatch, useSelector } from 'react-redux';
import { SIGN_UP, forgotPassword, signInUser, signUpUser } from 'core/actions/AuthActions';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import { getLoadingStatusSelector } from 'core/selectors/LoadingSelectors';
import SignUp from 'components/SignUp/SignUp';
import { AppRoutes } from 'config/AppRoutes';
import { globalNavigate } from 'utils/global-history';
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import images from 'assets/images';


export default function SignUpContainer() {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;
  const isLoading = useSelector(getLoadingStatusSelector(SIGN_UP.POST.REQUEST));

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const navigateToSignin = () => {
    globalNavigate(AppRoutes.login.link);
  }

  const onForgotPassword = (email) => {
    dispatch(forgotPassword.request(email))
  }

  const onSignUp = (email, password) => {
    dispatch(signUpUser.request(email, password))
  }


  const isDesktop = width >= breakpoint;
  const isMobile = width < breakpoint;
  return (
    <ResponsiveContainer splitOn={1}>
      <SignUp
        isMobile={isMobile}
        navigateToSignin={navigateToSignin}
        onSignUp={onSignUp}
        onForgotPassword={onForgotPassword}
        isLoading={isLoading}
        error={false}/>
    </ResponsiveContainer>
  )
}