export const AppRoutes = {
    // init: { link: '/', name: 'Init' },
    home: { link: '/', name: 'Home' },
    login: {link: '/login', name: 'Login'},
    forgottenPassword: {link: '/reset-password', name: 'Reset Password'},
    confirmSignup: {link: '/confirm-signup', name: 'Confirm Signup'},
    signup: {link: '/signup', name: 'Sign up'},
    teamID: { link: '/team-id', name: 'Team ID' },
    create: { link: '/create', name: 'Create' },
    createLeague: { link: '/create-league', name: 'Create League' },
    createH2H: { link: '/create-h2h', name: 'Create H2H'},
    tournaments: { link: '/tournaments', name: 'Tournaments' },
    matches: { link: '/matches', name: 'Matches' },
    help: { link: '/help', name: 'Help' },
    support: { link: '/support', name: 'Support' },
    profile: { link: '/profile', name: 'Profile' },
    news: { link: '/news', name: 'News' },
};
  