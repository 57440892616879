import React from "react";
import { useStyles } from "./FPLTable.styles";
import { CompetitionType, ICognitoUser, ILeague, IUser, IUserLeague, LeagueType } from "core/models/Models";
import FPLTableMobile from "./FPLTableMobile";
import FPLTableDesktop from "./FPLTableDesktop";



interface IProps {
  title: string;
  pageSize?: number;
  user: IUser;
  cognitoUser: ICognitoUser;
  leagues: ILeague[] | IUserLeague[];
  competitionType: CompetitionType;
  disabledLeagueIds?: any[];
  isMobile: boolean;
  onJoinLeague?: (leagueId: string) => void;
  onLeaveLeague?: (leagueId: string, leagueType: LeagueType) => void;
}


export default function FPLTable({title, isMobile, pageSize=5, user, leagues, cognitoUser, disabledLeagueIds, competitionType, onJoinLeague, onLeaveLeague}: IProps) {

  const classes = useStyles();

  return (
    <div className={isMobile ? classes.containerMobile : classes.containerDesktop}>
      {isMobile ?
        <FPLTableMobile isMobile={isMobile} title={title} leagues={leagues} disabledLeagueIds={disabledLeagueIds} user={user} cognitoUser={cognitoUser} onJoinLeague={onJoinLeague} competitionType={competitionType} onLeaveLeague={onLeaveLeague} pageSize={pageSize}/>
      :
        <FPLTableDesktop isMobile={isMobile} title={title} leagues={leagues} disabledLeagueIds={disabledLeagueIds} user={user} cognitoUser={cognitoUser} onJoinLeague={onJoinLeague} competitionType={competitionType} onLeaveLeague={onLeaveLeague} pageSize={pageSize}/>
      }
    </div>
  )
}
