import * as Alert from 'utils/Alerts';
import { Auth, API } from "aws-amplify";
import { put, all, takeLatest, call } from "redux-saga/effects";
import axios from 'axios';
import { STANDINGS, getStandings } from 'core/actions/NewsActions';


export function* getStandingsSaga({ payload: { season } }) {
  try {
    const options = {
        method: 'GET',
        url: 'https://api-football-v1.p.rapidapi.com/v3/standings',
        params: {
          season: season,
          league: '39'
        },
        headers: {
          'X-RapidAPI-Key': '73e84c1a94msh233ae1f8b69956ap132eb3jsn80ed140ab75b',
          'X-RapidAPI-Host': 'api-football-v1.p.rapidapi.com'
        }
      };

    const response = yield axios.request(options);
    console.log(response.data);

    yield put(getStandings.success(response.data));
  } catch (e: any) {
    console.log(e);
    yield put(getStandings.failure(e));
    yield Alert.setErrorAlert('An error occurred while getting standings');
  }
}



function* newsSaga() {
  yield all([
    takeLatest(STANDINGS.GET.REQUEST, getStandingsSaga),
  ])
}

export default newsSaga;