import React from 'react';
import useStyles from './styles/LeagueOrH2HHeader.styles';
import { clsx } from 'clsx';
import { globalNavigate } from 'utils/global-history';
import { AppRoutes } from 'config/AppRoutes';

const LeagueOrH2HHeader = ({ selected }) => {
  const classes = useStyles();

  const onLeagueClicked = () => {
    if (selected === 'h2h') {
      globalNavigate(AppRoutes.createLeague.link);
    }
  }

  const onH2hClicked = () => {
    if (selected === 'league') {
      globalNavigate(AppRoutes.createH2H.link);
    }
  }

  return (
    <div className={classes.container}>
      <div
        onClick={onLeagueClicked}
        className={clsx(classes.chip, classes.leftChip, selected === 'league' ? classes.selectedChip : classes.noteSlectedChip)}
      >
        League
      </div>
      <div
        onClick={onH2hClicked}
        className={clsx(classes.chip, classes.rightChip, selected === 'h2h' ? classes.selectedChip : classes.noteSlectedChip)}
        >
        H2H
      </div>
    </div>
  );
  };
  



  export default LeagueOrH2HHeader;