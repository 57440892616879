import { useStyles } from './ResponsiveContainer.styles';
import { Grid } from '@mui/material';
import FPLCarousel from 'components/Carousel/FPLCarousel';
import TopComponentMobile from 'components/TopComponentMobile/TopComponentMobile';
import { ReactNode, useEffect, useState } from 'react';
import BoxedComponent from './BoxedComponent';
import React from 'react';



interface IProps {
  showCarousel?: boolean;
  children: ReactNode;
  splitOn?: number;
  isMobile?: boolean;
}


export default function ResponsiveContainer({showCarousel, children, splitOn, isMobile}: IProps) {
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;

  const childrenArray = React.Children.toArray(children);

  const responsiveSplitOn = isMobile ? childrenArray.length : splitOn || childrenArray.length;
  const leftChildren = childrenArray.slice(0, responsiveSplitOn);
  const rightChildren = childrenArray.slice(responsiveSplitOn);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);


  const showMobilePage = (showCarousel) =>
    <Grid item container className={classes.rootMobile}>
      <TopComponentMobile>
        {showCarousel!== false && (
          <FPLCarousel/>
        )}
      </TopComponentMobile>
      <Grid item className={classes.contentContainer}>
        {leftChildren}
      </Grid>
    </Grid>


  const showDesktropPage = (showCarousel) =>
    <Grid item container className={classes.root}>
      <Grid item className={classes.topHorizontalContainer}>
        <Grid item className={classes.leftContainerDesktop}>
          {leftChildren[0]}
        </Grid>
        <Grid className={classes.rightContainerDesktop}>
          <BoxedComponent paddingLeft='0' paddingRight='0'>
            <FPLCarousel />
          </BoxedComponent>
        </Grid>
      </Grid>

      <Grid item className={classes.horizontalContainer}>
        <Grid item className={classes.leftContainerDesktop}>
          {leftChildren.slice(1, leftChildren.length)}
        </Grid>
        <Grid className={classes.rightContainerDesktop}>
          {rightChildren}
        </Grid>
      </Grid>
    </Grid>

  return width < breakpoint ? showMobilePage(showCarousel) : showDesktropPage(showCarousel);
}