import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // width: '100%',
    backgroundColor: theme_fpl.palette.common.black,
    // minHeight: '100vh',
    marginTop: '10px',
    marginRight: '30px',
    marginLeft: '30px',
    color: theme_fpl.palette.common.white,
  },
  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // width: '100%',
    backgroundColor: theme_fpl.palette.common.black,
    // minHeight: '100vh',
    marginTop: '10px',
    // marginRight: '10px',
    // marginLeft: '10px',
    color: theme_fpl.palette.common.white,
  },
  contentContainer: {
    marginTop: '1em',
    width: "100%",
  },
  leftContainerDesktop: {
    flex: '1 0 30%',
    height: '100%',
    marginTop: '-1.5em',
  },
  rightContainerDesktop: {
    marginLeft: '2em',
    width: 'auto',
    marginTop: '-1.5em',
  },
  horizontalContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '2em'
  },
  topHorizontalContainer: {
    marginTop: '-1.5em',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'stretch',
  },
}));


export default useStyles;