import { useStyles } from './styles/HomePageContainer.styles';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { confirmSignup, forgotPassword, forgotPasswordConfirmation, resendConfirmationCode, signInUser, signUpUser } from 'core/actions/AuthActions';
import { getCognitoUserSelector, getIsAuthenticatedSelector, getSigninFormSelector, getSignupFormSelector } from 'core/selectors/AuthSelectors';
import {  USER, getUser } from 'core/actions/UserActions';
import { getUserSelector } from 'core/selectors/UserSelectors';
import { getLoadingStatus, getLoadingStatusSelector } from 'core/selectors/LoadingSelectors';
import { CircleLoader } from 'react-spinners';
import theme_fpl from 'utils/Theme';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import JoinPrivate from 'components/Home/JoinPrivate';
import UserAndWallet from 'components/Home/UserAndWallet';
import FPLTable from 'components/Table/FPLTable';
import images from 'assets/images';
import { getPublicH2HSelector, getPublicLeaguesSelector } from 'core/selectors/LeagueSelectors';
import { getPublicLeagues, joinLeague } from 'core/actions/LeagueActions';
import { CompetitionType, LeagueType } from 'core/models/Models';


export default function HomePageContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  const isLoading = useSelector(getLoadingStatusSelector(USER.GET.REQUEST));
  const publicLeagues = useSelector(getPublicLeaguesSelector);
  const publicH2H = useSelector(getPublicH2HSelector);
  const cognitoUser = useSelector(getCognitoUserSelector);

  const disabledLeagues = publicLeagues
    ?.filter((league) => league?.maxPlayers == league.playersNumber || user?.usersLeagues?.some((userLeague) => userLeague.leagueId === league.leagueId))
    .map((league) => league.leagueId);

  const disabledH2H = publicH2H
    ?.filter((league) => league?.maxPlayers == league.playersNumber || user?.usersLeagues?.some((userLeague) => userLeague.leagueId === league.leagueId))
    .map((league) => league.leagueId);


  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;

  const isMobile = width < breakpoint;
  const isDesktop = width >= breakpoint;


  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);


  useEffect(() => {
    if (!publicLeagues || publicLeagues.length == 0) {
      dispatch(getPublicLeagues.request());
    }
  }, []);


  const onJoinLeague = (leagueId) => {
    dispatch(joinLeague.request({
      leagueType: LeagueType.PUBLIC,
      competitionType: CompetitionType.LEAGUE,
      leagueId: leagueId,
    }))
  }

  const onJoinH2H = (leagueId) => {
    dispatch(joinLeague.request({
      leagueType: LeagueType.PUBLIC,
      competitionType: CompetitionType.H2H,
      leagueId: leagueId
    }));
  }


  return (
    <>
      {isLoading ? (
        <Grid className={classes.loadingContainer}>
          <CircleLoader size={150} color={theme_fpl.palette.primary.main} loading={isLoading} />
        </Grid>
      ) : (
        <ResponsiveContainer splitOn={2} isMobile={isMobile}>
          <UserAndWallet isMobile={isMobile} user={user} error={false}/>
          {isDesktop && (
            <Grid className={classes.imageContainer}>
              <img src={images.desktopInnerImage.src} alt={images.desktopInnerImage.alt}/>
            </Grid>
          )}
          {isDesktop && (
            <>
            <div className={classes.leaguesContainer}>
              <div className={classes.leagueContainer}>
                <JoinPrivate isMobile={isMobile}/>
              </div>
            </div>
              <div className={classes.tablesContainer}>
                <div className={classes.tableContainer}>
                  <FPLTable isMobile={isMobile} title={'Public Leagues'} leagues={publicLeagues} disabledLeagueIds={disabledLeagues} user={user} cognitoUser={cognitoUser} onJoinLeague={onJoinLeague} competitionType={CompetitionType.LEAGUE}/>
                </div>
                <div className={classes.tableContainer}>
                  <FPLTable isMobile={isMobile} title={'Public H2H'} leagues={publicH2H} disabledLeagueIds={disabledH2H} user={user} cognitoUser={cognitoUser} onJoinLeague={onJoinH2H} competitionType={CompetitionType.H2H}/>
                </div>
              </div>
            </>
          )}
          {isMobile && (
            <>
              <div className={classes.tableContainer}>
                <FPLTable isMobile={isMobile} title={'Public Leagues'} leagues={publicLeagues} disabledLeagueIds={disabledLeagues} user={user} cognitoUser={cognitoUser} onJoinLeague={onJoinLeague} competitionType={CompetitionType.LEAGUE}/>
              </div>
              <div className={classes.tableContainer}>
                <FPLTable isMobile={isMobile} title={'Public H2H' } leagues={publicH2H} disabledLeagueIds={disabledH2H} user={user} cognitoUser={cognitoUser} onJoinLeague={onJoinH2H} competitionType={CompetitionType.H2H}/>
              </div>
              <div className={classes.leagueContainer}>
                <JoinPrivate isMobile={isMobile}/>
              </div>
            </>
          )}
        </ResponsiveContainer>
      )
    }
    </>
  )
}
