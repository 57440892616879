import Input from 'components/Shared/Input/Input';
import React, { useState } from 'react';
import { useStyles } from './JoinPrivateLeagueDialog.styles';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Grid, Icon } from '@mui/material';
import { FaAt } from 'react-icons/fa';
import { CompetitionType } from 'core/models/Models';

interface Props {
  isOpen: boolean;
  competitionType: string;
  onClose: () => void;
  onSubmit: (code: string) => void;
}


export default function JoinPrivateLeagueDialog({ isOpen, competitionType,  onClose, onSubmit }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [code, setCode] = useState('');

  const handleClose = (e) => {
    onClose();
  };

  const handleInputChange = ({ target: { value } }) => {
    setCode(value);
  }

  const handlePressEnter = (event) => {
    if (code.length == 6 &&  event.keyCode === 13) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    onSubmit(code);
    onClose();
  }


  return (
    <Dialog
      classes={{ paper: classes.requestDialogContainer }}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'>
      <React.Fragment>
        <div className={classes.titleWrapper}>
          <div className={classes.requestDialogTitle}>Join {competitionType}</div>
          <div className={classes.requestDialogSubtitle}>To join a private {competitionType}, please enter its entry code</div>
        </div>
        <Input
          label='Entry code'
          name='code'
          onChange={handleInputChange}
          value={code}
          isRequired
        />
        <Grid className={classes.button}>
          <Button
            color='secondary'
            onClick={handleSubmit}
            variant='contained'
            style={{borderRadius: 20, height: 45}}
            onKeyUp={handlePressEnter}
            disabled={code.length != 6}>
            CONFIRM
          </Button>
        </Grid>
      </React.Fragment>
    </Dialog>
  );
}
