import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  tablesContainer: {
    display: 'flex',
    marginTop: '20px',
    flexDirection: 'column',
    gap: '20px'
  },
  tableContainer: {
    marginTop: 30,
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  leaguesContainer: {
    display: 'flex',
    marginTop: '40px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '20px'
  },
  leagueContainer: {
    display: 'flex',
    flex: 1,
  },
  imageContainer: {
    marginTop: '2em',
  }
}))
