import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme_fpl.palette.common.black,
    // maxWidth: '450px',
    // width: '90%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    paddingLeft: '3rem',
    paddingTop: '1em',
    paddingRight: '3rem',
    height: '360px',
    borderRadius: '25px',
    boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.5)',
    marginTop: '-20px'
  },
}));
