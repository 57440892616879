import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useStyles } from './DropdownMenu.styles';

const StyledMenuMobile = withStyles({
  paper: {
    borderRightColor: '#25eba8',
    borderLeftColor: '#25eba8',
    borderRightWidth: '1px',
    borderLeftWidth: '1px',
    borderRightStyle: 'solid',
    borderLeftStyle: 'solid',
    minWidth: '5rem',
    overflow: 'visible',
    backgroundColor: '#2A2A2A',
    color: '#ffffff',
    marginTop: '12px',
    borderRadius: '0px',
    boxShadow: '-1px 4px 4px #00000025'
  }
})((props: MenuProps) => (
  <Menu
    elevation={0}
    // getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));


const StyledMenu = withStyles({
  paper: {
    borderRightColor: '#25eba8',
    borderLeftColor: '#25eba8',
    borderRightWidth: '1px',
    borderLeftWidth: '1px',
    borderRightStyle: 'solid',
    borderLeftStyle: 'solid',
    minWidth: '8rem',
    overflow: 'visible',
    backgroundColor: '#2A2A2A',
    color: '#ffffff',
    marginTop: '12px',
    marginLeft: '40px',
    borderRadius: '0px',
    boxShadow: '-1px 4px 4px #00000025'
  }
})((props: MenuProps) => (
  <Menu
    elevation={0}
    // getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

interface Item {
  text: string;
  icon?: React.ReactElement;
  enabled?: boolean;
  type?: string;
  onclick?: () => void;
}

interface Props {
  button: React.ReactElement;
  isMobile: boolean;
  items: Array<Item>;
  onTypedItem?: (type: string) => void;
}

export default function DropdownMenu({ button, items, isMobile, onTypedItem }: Props) {
  const classes  = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLink = (item) => {
    item.onclick();
    handleClose();
    if (onTypedItem) {
      onTypedItem(item?.type);
    }
  };

  const showMenuItem = (item) =>
    <MenuItem
      key={item.text}
      className={classes.menuItem}
      onClick={() => handleClickLink(item)}>
      <ListItemText
        className={isMobile ? classes.itemTextMobile : classes.itemText}
        primary={item.text}
      />
    </MenuItem>



  return (
    <div aria-controls='customized-menu' aria-haspopup='true'>
      <div aria-controls='customized-menu' aria-haspopup='true' onClick={handleClick}>
        {button}
      </div>
      {isMobile ? (
        <StyledMenuMobile id='customized-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {items.map((item) => {
            return (
              showMenuItem(item)
            );
          })}
        </StyledMenuMobile>
      ) : (
        <StyledMenu id='customized-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {items.map((item) => {
          return (
            showMenuItem(item)
          );
        })}
      </StyledMenu>
      )}
    </div>
  );
}
