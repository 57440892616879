import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  rootHomePage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // width: '100%',
    backgroundColor: theme_fpl.palette.common.black,
    // minHeight: '100vh',
    marginTop: '10px',
    marginRight: '30px',
    marginLeft: '30px',
    color: theme_fpl.palette.common.white,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '10px 0px 20px'
  },
  contentContainer: {
    marginTop: '1em',
    width: "100%",
  },
  leftContainerDesktop: {
    flex: '0 0 30%',

  },
  rightContainerDesktop: {
    marginLeft: '2em',
    width: 'auto'
  },
  horizontalContainer: {
    marginTop: '-1.5em',
    display: 'flex',
    flexDirectio: 'row',
    width: '100%',
  },
  roundedContainer: {
    backgroundColor: theme_fpl.palette.common.black,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    paddingTop: '3em',
    paddingBottom: '3em',
    // paddingLeft: '3rem',
    // paddingTop: '1em',
    // paddingRight: '3rem',
  //  minWidth: '400px',
    borderRadius: '25px',
    boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.5)',
    // marginTop: '-20px'
    },
    loadingContainer: {
      display: 'flex',
      flex: 1,
      height: 500,
      justifyContent: 'center',
      alignItems: 'center',
    },
    tablesContainer: {
      display: 'flex',
      marginTop: '20px',
      flexDirection: 'column',
      gap: '20px'
    },
    tableContainer: {
      marginTop: 30,
      display: 'flex',
      flex: 1,
      overflow: 'auto',
    },
    leaguesContainer: {
      display: 'flex',
      marginTop: '40px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: '20px'
    },
    leagueContainer: {
      display: 'flex',
      flex: 1,
    },
    imageContainer: {
      marginTop: '2em',
    }
}));
