import React, { ReactNode } from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles/CreateMobileHeader.styles';

import images from 'assets/images';
import BoxedComponent from 'components/Shared/Container/BoxedComponent';


interface IProps {
  heading: string;
  children: ReactNode;
}

const CreateMobileHeader = ({heading, children}: IProps) => {
  const classes = useStyles();

  return (
    <BoxedComponent>
      <div className={classes.root}>
        <h2 className={classes.text}>{heading}</h2>
      </div>
      {children}
    </BoxedComponent>
  );
};

export default CreateMobileHeader;
