import { useDispatch, useSelector } from 'react-redux';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import { getLoadingStatusSelector } from 'core/selectors/LoadingSelectors';
import { useState, useEffect } from 'react';
import Support from 'components/Support/Support';
import { getIsIssueSentSelector } from 'core/selectors/EmailSelector';
import { SEND_SUPPORT_ISSUE, resetIsIssueSent, sendSupportIssue } from 'core/actions/EmailActions';


export default function SupportContainer() {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;
  const isLoading = useSelector(getLoadingStatusSelector(SEND_SUPPORT_ISSUE.POST.REQUEST));
  const isIssueSent = useSelector(getIsIssueSentSelector);
  const [issue, setIssue] = useState('');


  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);


  useEffect(() => {
    if (isIssueSent) {
      setIssue('');
      dispatch(resetIsIssueSent());
    }
  }, []);

  const onCreateNewIssue = () => {
    setIssue('');
    dispatch(resetIsIssueSent());
  }

  const onSendIssue = (issue) => {
    dispatch(sendSupportIssue.request(issue));
  }

  return (
    <ResponsiveContainer showCarousel={false}>
      <Support isMobile={width < breakpoint} isLoading={isLoading}
       onSendIssue={onSendIssue} isIssueSent={isIssueSent}
       onCreateNewIssue={onCreateNewIssue}
       issue={issue}
       setIssue={setIssue}
       />
    </ResponsiveContainer>
  )
}