import React, { Suspense, useEffect, useState, lazy } from 'react';
import './App.css';
import { AppRoutes } from './config/AppRoutes';
import HomePageContainer from './containers/HomePageContainer';
import LoadingPage from './containers/LoadingPage';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { getUserFetchedFailedSelector, getUserSelector } from 'core/selectors/UserSelectors';
import ProtectedRoute from './utils/ProtectedRoute';
import { useDispatch, useSelector } from 'react-redux';
import TournamentsContainer from './containers/TournamentsContainer';
import MatchesContainer from './containers/MatchesContainer';
import { getCognitoUserSelector, getIsAuthenticatedSelector } from 'core/selectors/AuthSelectors';
import { getUser } from './core/actions/UserActions';
import { getCurrentAuthUser } from './core/actions/AuthActions';
import LoginContainer from '././containers/LoginContainer';
import ForgottenPasswordContainer from './containers/ForgottenPasswordContainer';
import SignUpContainer from './containers/SignUpContainer';
import ConfirmSignupContainer from './containers/ConfirmSignupContainer';
import TeamContainer from './containers/TeamContainer';
import HelpContainer from './containers/HelpContainer';
import SupportContainer from './containers/SupportContainer';
import ProfileContainer from './containers/ProfileContainer';
import NewsContainer from './containers/NewsContainer';
import CreateLeagueContainer from 'containers/CreateLeagueContainer';
import CreateH2HContainer from 'containers/CreateH2HContainer';

const Layout = lazy(() => import('./containers/Layout'));
const NotFound404 = lazy(() => import('components/ErrorPages/NotFound404'));


const routes = [
  { path: AppRoutes.help.link, Component: HelpContainer},
]
const protectedRoutes = [
  { path: AppRoutes.home.link, Component: HomePageContainer},
  { path: AppRoutes.teamID.link, Component: TeamContainer},
  { path: AppRoutes.createLeague.link, Component: CreateLeagueContainer},
  { path: AppRoutes.createH2H.link, Component: CreateH2HContainer},
  { path: AppRoutes.tournaments.link, Component: TournamentsContainer},
  { path: AppRoutes.matches.link, Component: MatchesContainer},
  { path: AppRoutes.support.link, Component: SupportContainer},
  { path: AppRoutes.profile.link, Component: ProfileContainer},
  { path: AppRoutes.news.link, Component: NewsContainer}
]

const nonAuthenticatedRoutes = [
  { path: AppRoutes.signup.link, Component: SignUpContainer},
  { path: AppRoutes.confirmSignup.link, Component: ConfirmSignupContainer},
  { path: AppRoutes.forgottenPassword.link, Component: ForgottenPasswordContainer},
  { path: AppRoutes.login.link, Component: LoginContainer},

]


function App() {

  const user = useSelector(getUserSelector);
  const { state, pathname } = useLocation();
  const cognitoUser = useSelector(getCognitoUserSelector);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { from }: any = state || { from: { pathname: AppRoutes.home.link } };
  const fetchUserFailed = useSelector(getUserFetchedFailedSelector);

  useEffect(() => {
    if (!pathname.startsWith('/signup') && !pathname.startsWith('/confirm-signup')) {
     dispatch(getCurrentAuthUser.request());
    }
  }, []);


  useEffect(() => {
    if (isAuthenticated) {
      setRedirectToReferrer(true);
    }
  }, [isAuthenticated]);


  useEffect(() => {
    if (!user?.teamId && cognitoUser?.attributes?.sub) {
      dispatch(getUser.request());
    }
  }, [cognitoUser]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        {/* <Route index element={<Navigate to={AppRoutes.login.link} replace />} /> */}
        {nonAuthenticatedRoutes.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={
              isAuthenticated ? (
                <Navigate to={AppRoutes.home.link} replace />
              ) : (
                <Layout>
                  <Component />
                </Layout>
              )
            }
          />
        ))}
        {routes.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={
              <Layout>
                <Component />
              </Layout>
            }
          />
        ))}
        {protectedRoutes.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute isAuthorized={isAuthenticated} userExists={!fetchUserFailed}>
                <Layout>
                  <Component />
                </Layout>
              </ProtectedRoute>
            }
          />
        ))}
        <Route path='*' element={<NotFound404 />} />
      </Routes>
    </Suspense>
  );
}

export default App;
