import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  innerContainer: {
    marginTop: 20,
    marginBottom: 40,
  },
  headerText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    borderBottom: `1px solid ${theme_fpl.palette.primary.main}`,
    paddingBottom: 10,
    fontWeight: 600,
  },
  createJoinContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    width: '100%',
    marginTop: '30px',
  },
  creteJoinItem: {
    width: '50%',
  },
  createJoinHeader: {
    textAlign: 'center',
    paddingTop: 10,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    paddingTop: 20,
  },
  button: {
    width: '50%',
    borderTopLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    color: theme_fpl.palette.common.white,
    backgroundColor: theme_fpl.palette.primary.main,
  },
  inputContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
