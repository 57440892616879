import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';


const useStyles = makeStyles((theme) => ({
  counterElement: {
    borderBottom: `1px solid ${theme_fpl.palette.grey[500]}`,
    justifyContent: "center",
    alignItems: "center",
    height: 30,
  },
  counterElementSelected: {
    fontSize: 18,
    color: theme_fpl.palette.secondary.main,
    fontWeight: 600,
  },
  counterElementNotSelected: {
    fontSize: 16,
    color: theme_fpl.palette.grey[500],
    fontWeight: 500,
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '22px',
  }
}));

export default useStyles;
