import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  navigationContainer: {
    marginTop: -40,
    // display: 'flex',
    // flex: 1,
    marginBottom: 30,
  },
  root: {
    // display: 'flex',
    // flex: 1,
    width: '100%'
  },
  navigationBar: {
    margin: 10,
    marginBottom: 0,
  },
  centralComponent: {
    marginBottom: 40,
  },
  mobileNavRoot: {
    backgroundColor: theme_fpl.palette.common.black,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    boxSizing: 'border-box',
    borderTopRightRadius: '25px',
    borderTopLeftRadius: '25px',
    boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.5)',
    marginTop: '1em',
  }
}));
