import { makeStyles } from '@mui/styles';
import theme_fpl from 'utils/Theme';

export const useStyles = makeStyles(() => ({
  requestDialogContainer: {
    borderRadius: '0px',
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: theme_fpl.palette.common.black,
    padding: theme_fpl.spacing(4, 4, 4, 4)
  },
  requestDialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifySelf: 'center',
    margin: theme_fpl.spacing(4, 0, 4, 0)
  },
  dialogText: {
    color: theme_fpl.palette.common.white,
    ...theme_fpl.typography.subtitle2,
    textAlign: 'center',
    marginTop: 10
  },
  button: {
    marginTop: '4em',
    marginBottom: '3em',
    width: '100%',
    '& > button': {
      width: '100%',
      padding: 15
    }
  },
}));
