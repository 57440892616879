import { useStyles } from './styles/CreateContainer.styles';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import ImageComponent from '../components/ImageComponent/ImageComponent';
import images from 'assets/images';
import BoxedComponent from '../components/Shared/Container/BoxedComponent';
import ResponsiveContainer from 'components/Shared/Container/ResponsiveContainer';
import CreateMobileHeader from 'components/Create/CreateMobileHeader';
import LeagueOrH2HHeader from 'components/Create/LeagueOrH2HHeader';
import CreateH2H from 'components/Create/CreateH2H';


export default function CreateH2HContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();


  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1080;

  const isMobile = width < breakpoint;
  const isDesktop = width >= breakpoint;


  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const onCancel = () => {
    console.log("cancel")
  }


  return (
    <ResponsiveContainer showCarousel={false} splitOn={2} isMobile={isMobile}>
      {isDesktop ? (
      <BoxedComponent height='100%'>
        <Grid className={classes.topImageContainer}>
          <ImageComponent heading={'H2H'}/>
        </Grid>
      </BoxedComponent>
      ) : (
      <CreateMobileHeader heading={'Create H2H'}>
        <LeagueOrH2HHeader selected={'h2h'}/>
      </CreateMobileHeader>
      )}
      {isDesktop && (
        <Grid className={classes.imageContainer}>
          <img src={images.desktopInnerImage.src} alt={images.desktopInnerImage.alt}/>
        </Grid>
      )}
      {isDesktop && (
        <BoxedComponent>
          <LeagueOrH2HHeader selected={'h2h'}/>
        </BoxedComponent>
      )}
      <CreateH2H/>
  </ResponsiveContainer>
  )
}